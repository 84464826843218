<template>
   <v-card class="py-4 border-config" tile flat>
      <div class="d-flex align-center ma-n1">
         <p class="body-2 ma-1">
            {{ translatedResponsible }}
         </p>

         <p class="caption text--secondary ma-1">
            {{ formatDate(message.created_at, "DD/MM/YYYY [•] HH:mm") }}
         </p>

         <v-chip v-if="!message.is_read" class="ma-1" color="warning" x-small
            >Recente</v-chip
         >
      </div>

      <p class="body-2 font-weight-medium mt-2">{{ message.text }}</p>
   </v-card>
</template>

<script>
import formatUtil from "utils/formatUtil";

export default {
   name: "MessageItem",

   props: {
      message: {
         type: Object,
      },
   },

   computed: {
      translatedResponsible() {
         let result = "Regulador";

         if (this.message.message_from === "system") result = "Sistema";

         return result;
      },
   },

   methods: {
      ...formatUtil,
   },
};
</script>

<style scoped>
.border-config {
   border-top: 1px solid rgba(147, 147, 147, 0.3);
   border-bottom: 1px solid rgba(147, 147, 147, 0.3);
   margin-top: -1px;
}

.wobble-hor-top {
   -webkit-animation: wobble-hor-top 1.5s ease-in-out 2 both;
   animation: wobble-hor-top 1.5s ease-in-out 2 both;
}

@-webkit-keyframes wobble-hor-top {
   0%,
   100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
   }
   15% {
      -webkit-transform: translateX(-30px) rotate(6deg);
      transform: translateX(-30px) rotate(6deg);
   }
   30% {
      -webkit-transform: translateX(15px) rotate(-6deg);
      transform: translateX(15px) rotate(-6deg);
   }
   45% {
      -webkit-transform: translateX(-15px) rotate(3.6deg);
      transform: translateX(-15px) rotate(3.6deg);
   }
   60% {
      -webkit-transform: translateX(9px) rotate(-2.4deg);
      transform: translateX(9px) rotate(-2.4deg);
   }
   75% {
      -webkit-transform: translateX(-6px) rotate(1.2deg);
      transform: translateX(-6px) rotate(1.2deg);
   }
}
@keyframes wobble-hor-top {
   0%,
   100% {
      -webkit-transform: translateX(0%);
      transform: translateX(0%);
      -webkit-transform-origin: 50% 50%;
      transform-origin: 50% 50%;
   }
   15% {
      -webkit-transform: translateX(-30px) rotate(6deg);
      transform: translateX(-30px) rotate(6deg);
   }
   30% {
      -webkit-transform: translateX(15px) rotate(-6deg);
      transform: translateX(15px) rotate(-6deg);
   }
   45% {
      -webkit-transform: translateX(-15px) rotate(3.6deg);
      transform: translateX(-15px) rotate(3.6deg);
   }
   60% {
      -webkit-transform: translateX(9px) rotate(-2.4deg);
      transform: translateX(9px) rotate(-2.4deg);
   }
   75% {
      -webkit-transform: translateX(-6px) rotate(1.2deg);
      transform: translateX(-6px) rotate(1.2deg);
   }
}
</style>