import { render, staticRenderFns } from "./Message.vue?vue&type=template&id=0ea60a73&scoped=true"
import script from "./Message.vue?vue&type=script&lang=js"
export * from "./Message.vue?vue&type=script&lang=js"
import style0 from "./Message.vue?vue&type=style&index=0&id=0ea60a73&prod&scoped=true&lang=css"
import style1 from "./Message.vue?vue&type=style&index=1&id=0ea60a73&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ea60a73",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VImg,VProgressLinear,VTab,VTabs})
