<template>
   <div class="pb-16" id="messages-content">
      <div class="mb-6">
         <p class="font-weight-bold font-sora main-title">Notificações</p>

         <p class="text--secondary body-2 mt-1">
            Fique <strong>atento</strong> as notificações que o sistema e os
            reguladores enviam para você!
         </p>
      </div>

      <v-tabs v-model="activeTab" class="margin-bottom-tab" grow show-arrows>
         <v-tab> REGULADOR </v-tab>
         <v-tab> SISTEMA </v-tab>
      </v-tabs>

      <div
         v-if="loadingMessages"
         class="
            d-flex
            flex-column
            justify-center
            align-center
            standard-padding
            cursor-default
            margin-top-mobile
            py-7
         "
      >
         <p
            class="
               text-center
               font-sora
               loading-messages-label
               primary--text
               mb-5
            "
         >
            Carregando as <br />
            mensagens...
         </p>

         <v-progress-linear
            style="width: 50%"
            color="primary"
            indeterminate
            rounded
            height="4"
         ></v-progress-linear>
      </div>

      <div v-else-if="messages.length">
         <MessageItem
            v-for="(message, index) in messages"
            :key="index"
            :message="message"
         />
      </div>

      <div
         v-else
         class="
            d-flex
            flex-column
            justify-center
            align-center
            standard-padding
            cursor-default
            margin-top-mobile
         "
      >
         <p
            class="
               text-center
               font-sora
               loading-messages-label
               primary--text
               mb-3
            "
         >
            Eba, tudo em dia! Você<br />
            não tem notificações.
         </p>

         <v-img
            :src="require('@/assets/images/smiley.png')"
            height="85px"
            contain
         >
         </v-img>
      </div>
   </div>
</template>

<script>
import MessageItem from "./MessageItem";
import messageService from "services/messageService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "Message",

   components: { MessageItem },

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         messages: [],
         loadingMessages: false,

         activeTab: 0,

         page: 1,
         limit: 100,
         sort: "created_at",
      };
   },

   watch: {
      request: {
         immediate: true,
         handler(request) {
            if (request && request.id) {
               this.activeTab = 0;
               this.getAllRequestMessages(request.id, this.query);
            }
         },
      },

      activeTab() {
         if (this.request && this.request.id) {
            this.messages = [];
            this.getAllRequestMessages(this.request.id, this.query);
         }
      },
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
            filter: [
               {
                  attribute: "message_from",
                  query: this.activeTab === 0 ? "regulation" : "system",
               },
               {
                  attribute: "message_to",
                  query: "patient",
               },
            ],
         };

         return query;
      },
   },

   methods: {
      async getAllRequestMessages(requestId, query) {
         this.loadingMessages = true;

         try {
            const response = await messageService.getAllRequestMessages(
               requestId,
               query
            );

            this.messages = Object.assign([], response.data);

            /* Seta as mensagens não lidas, o padrão do backend não está funcionando. */
            if (this.request.unreadMessages > 0)
               this.messages.forEach((item, index, array) => {
                  if (array.length - index - 1 < this.request.unreadMessages)
                     item.is_read = false;
               });
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMessages = false;
         }
      },
   },
};
</script>

<style scoped>
.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

.loading-messages-label {
   font-weight: 400 !important;
   font-size: 0.875rem !important;
   letter-spacing: 0.0178571429em !important;
}

@media (max-width: 959px) {
   .margin-top-mobile {
      margin-top: 64px;
   }
}

.margin-bottom-tab {
   margin-bottom: 1px;
}

::v-deep .v-slide-group__content {
   height: 45px;
}

::v-deep .v-tabs {
   height: 45px;
}
</style>

<style lang="sass">
#messages-content
   .v-tabs-slider
      max-width: 50%
      margin: 0 auto
</style>