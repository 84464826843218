<template>
   <div>
      <v-dialog
         v-model="dialogViewer"
         transition="dialog-bottom-transition"
         fullscreen
         hide-overlay
         persistent
      >
         <v-card class="card-overlay" tile flat>
            <AttachmentViewer
               @onCloseOverlay="closeDialogViewer"
               :attachment="attachmentSelected"
            />
         </v-card>
      </v-dialog>

      <div
         v-if="loadingRequestAttachments"
         class="d-flex align-center flex-wrap ma-n2"
      >
         <AttachmentItemSkeleton v-for="item in 2" :key="item" class="ma-2" />
      </div>

      <Attachments
         v-else
         @onRemoveAttachment="onRemoveAttachment"
         @onViewAttachment="onViewAttachment"
         @onSelectInvalidFormat="onSelectInvalidFormat"
         @onSelectInvalidSize="onSelectInvalidSize"
         :key="attachmentsKey"
         :attachments="requestAttachmentsFormatted"
         :hideAddButton="!editMode"
         :allowedTypes="allowedTypes"
         :maximumSize="maximumSize"
         :hideRemoveButton="hideRemoveButton"
      />
   </div>
</template>

<script>
import Attachments from "components/attachment/Attachments";
import AttachmentViewer from "components/attachment/AttachmentViewer";
import AttachmentItemSkeleton from "components/attachment/AttachmentItemSkeleton";
import attachmentService from "services/attachmentService";
import snackBarUtil from "utils/snackBarUtil";
import util from "utils/util";

export default {
   name: "RequestAttachments",

   components: { Attachments, AttachmentViewer, AttachmentItemSkeleton },

   props: {
      request: {
         type: Object,
      },

      hideRemoveButton: {
         type: Boolean,
         default: false,
      },

      hideAddButton: {
         type: Boolean,
         default: false,
      },

      editMode: {
         type: Boolean,
         default: false,
      },

      maximumSize: {
         typeof: Number,
         default: 1048576,
      },

      allowedTypes: {
         typeof: Array,
         default: () => [],
      },
   },

   data: function () {
      return {
         dialogViewer: false,

         attachmentSelected: null,

         loadingRequestAttachments: false,

         requestAttachments: [],
         requestAttachmentsFormatted: [],

         attachmentsKey: 0,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
         };

         if (
            this.filter &&
            this.filterBy &&
            this.filterBy.replace(/ /g, "") !== ""
         )
            query.filter = {
               attribute: this.filter,
               query: this.filterBy,
            };

         return query;
      },
   },

   watch: {
      request: {
         immediate: true,
         async handler(request) {
            this.getAllRequestAttachmentsFully(request);
         },
      },
   },

   methods: {
      closeDialogViewer() {
         this.dialogViewer = false;
      },

      openDialogViewer() {
         this.dialogViewer = true;
      },

      onViewAttachment(attachment) {
         this.attachmentSelected = attachment;
         this.openDialogViewer();
      },

      getAttachments() {
         return this.requestAttachmentsFormatted;
      },

      onSelectInvalidFormat() {
         const allowedTypesNames = this.allowedTypes.map((item) => {
            if (item.split("/")[1]) return `".${item.split("/")[1]}"`;
            else return "desconhecido";
         });

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            title: "FORMATO INVÁLIDO",
            icon: "mdi-paperclip",
            message: `Anexo com formato inválido. Selecione arquivos ${allowedTypesNames.join(
               ", "
            )}.`,
         });
      },

      onSelectInvalidSize() {
         const sizeMb = Math.ceil(this.maximumSize / (1024 * 1024));

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            title: "TAMANHO INVÁLIDO",
            icon: "mdi-paperclip",
            message: `Anexo com tamanho inválido. Selecione arquivos com no máximo ${sizeMb} MB.`,
         });
      },

      forceAttachmentsRerender() {
         this.attachmentsKey += 1;
      },

      async getAllRequestAttachmentsFully(request) {
         this.requestAttachments = [];
         this.requestAttachmentsFormatted = [];

         if (request && request.id) {
            await this.getAllRequestAttachments(request.id, this.query);

            this.requestAttachments.forEach((item) => {
               this.getRequestAttachmentById(request.id, item.id);
            });
         }
      },

      onRemoveAttachment(attachment) {
         if (
            !this.request ||
            !this.request.id ||
            !attachment ||
            !attachment.id ||
            attachment.raw
         )
            return;

         const methodDeleteItem = async () => {
            await attachmentService.removeRequestAttachment(
               this.request.id,
               attachment.id
            );

            this.getAllRequestAttachmentsFully(this.request);
         };

         snackBarUtil.showCustomSnackBar({
            color: "warning",
            title: "CONFIRMAÇÃO DE EXCLUSÃO!",
            message: "Deseja realmente excluir esse anexo?",
            action: {
               text: "Confirmar",
               method: methodDeleteItem,
            },
         });
      },

      async getAllRequestAttachments(requestId, query) {
         this.loadingRequestAttachments = true;

         try {
            const response = await attachmentService.getAllRequestAttachments(
               requestId,
               query
            );

            this.requestAttachments = Object.assign([], response.data);

            this.requestAttachments.forEach((item) => {
               this.requestAttachmentsFormatted.push({
                  id: item.id,
                  loading: true,
               });
            });
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRequestAttachments = false;
         }
      },

      async getRequestAttachmentById(requestId, attachmentId) {
         try {
            const response = await attachmentService.getRequestAttachmentById(
               requestId,
               attachmentId
            );

            const attachmentOriginalTemp = this.requestAttachments.find(
               (item) => item.id === attachmentId
            );
            const content = await util.getFileContents(response.data);

            const fileIndex = this.requestAttachmentsFormatted.findIndex(
               (item) => item.id === attachmentId
            );
            if (fileIndex !== -1) {
               this.requestAttachmentsFormatted[fileIndex] = {
                  id: attachmentOriginalTemp.id,
                  raw: false,
                  content: {
                     name: attachmentOriginalTemp.filename,
                     type: attachmentOriginalTemp.content_type,
                     data: content,
                     size: attachmentOriginalTemp.size,
                  },
               };

               this.forceAttachmentsRerender();
            }
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>
.card-overlay {
   background-color: rgba(0, 0, 0, 0.98) !important;
}

@media (max-width: 959px) {
   .card-overlay {
      background-color: black !important;
   }
}
</style>