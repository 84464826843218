<template>
   <div>
      <v-app-bar
         class="overflow-hidden"
         color="primary"
         app
         prominent
         shrink-on-scroll
      >
         <template v-slot:img>
            <v-img :src="imageByPath" class="mt-3" height="80%" contain>
            </v-img>
         </template>

         <template v-slot:extension>
            <v-tabs dark centered>
               <v-tab
                  v-for="(item, index) in menuRouteList"
                  :to="item.link"
                  :key="index"
                  class="font-sora background-tab"
               >
                  <v-icon class="mr-3" size="20">{{ item.icon }}</v-icon>
                  {{ item.text }}
               </v-tab>
            </v-tabs>
         </template>
      </v-app-bar>
   </div>
</template>

<script>
import userService from "../../services/userService";

export default {
   name: "AppBar",

   data: function () {
      return {
         menuRouteList: [
            {
               icon: "mdi-file-document",
               text: "Solicitações",
               link: "/minhas-solicitacoes",
            },
            {
               icon: "mdi-calendar",
               text: "Agendamentos",
               link: "/meus-agendamentos",
            },
            {
               icon: "mdi-account",
               text: "Dados",
               link: "/minha-conta",
            },
         ],
      };
   },

   computed: {
      imageByPath() {
         let path = "warning-circle.png";

         switch (this.$route.path) {
            case "/minhas-solicitacoes":
               path = "requests-image-circle.png";
               break;
            case "/meus-agendamentos":
               path = "schedule-image-circle.png";
               break;
            case "/minha-conta":
               path = "my-data-image-circle.png";
               break;
         }

         return require(`@/assets/images/${path}`);
      },
   },

   methods: {
      logout() {
         userService.removeUserSession();

         if (this.$route.path !== "/autenticacao")
            this.$router.push({ path: "/autenticacao" });
      },
   },
};
</script>

<style scoped>
.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

.background-tab {
   background-image: linear-gradient(
      rgba(92, 107, 192, 0) -20% 0%,
      rgba(92, 107, 192, 0.4) 20% 100%
   );
}
</style>