export default {
   namespaced: true,

   state: {
      email: null,
      cpf: null,
   },

   getters: {
      getEmail(state) {
         return state.email;
      },

      getCpf(state) {
         return state.cpf;
      },
   },

   mutations: {
      setEmail(state, email) {
         state.email = email;
      },

      setCpf(state, cpf) {
         state.cpf = cpf;
      },
   },
};
