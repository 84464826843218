import axios from "axios";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getZipCode(cep) {
   return await axios.get(`https://viacep.com.br/ws/${cep.replace(/[^A-Z0-9]/gi, "")}/json/`);
}

export default { getZipCode };
