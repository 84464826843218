<template>
   <v-dialog
      v-model="dialogTerm"
      :fullscreen="isSmallerThanComputed"
      :hide-overlay="isSmallerThanComputed"
      :transition="
         isSmallerThanComputed
            ? 'dialog-bottom-transition'
            : 'slide-y-transition'
      "
      width="550px"
      id="term-dialog"
      persistent
   >
      <div>
         <v-card :tile="isSmallerThanComputed" class="standard-padding">
            <p class="font-weight-bold font-sora main-title">
               Termo de autorização
            </p>

            <div class="mb-6">
               <p class="caption text-justify mt-4">
                  <strong>Prezado(a)</strong>,
                  <br />
                  <br />
                  Nosso Sistema foi desenvolvido para ajudar você em suas
                  atividades, garantindo a qualidade e sua segurança. Deste
                  modo, concebemos diversos mecanismos de controles de segurança
                  e auditoria e sua participação é muito importante neste
                  processo. Sendo assim, é fundamental o cumprimento das
                  seguintes regras:
               </p>

               <p class="term-topics font-sora text-justify mt-6">
                  <strong>1)</strong> Não revelar fora do âmbito profissional,
                  dados, fatos ou informações de qualquer natureza que tenha
                  conhecimento por força de suas atribuições, salvo em
                  decorrência de decisão competente na esfera legal ou judicial,
                  bem como de autoridade superior e, mantendo a necessária
                  cautela quando de sua exibição em tela, impressora ou na
                  gravação em meios eletrônicos, a fim de evitar sua divulgação
                  às pessoas não autorizadas.
                  <br />
                  <strong>2)</strong> Não revelar sua Senha de acesso ao Sistema
                  da SES a ninguém e tomar todo o cuidado para que elas
                  permaneçam somente de seu conhecimento. Alterá-las, sempre que
                  obrigatório ou que tenha suposição de descoberta por
                  terceiros, não usando combinações simples que possam ser
                  facilmente descobertas.
                  <br />
                  <strong>3)</strong> Não ausentar-se da estação de trabalho sem
                  o encerramento da sessão do Sistema em uso (efetuando
                  “Logout”, “Sair” ou função similar), fechar o navegador, e,
                  bloquear ou desligar a estação de trabalho.
                  <br />
                  <strong>4)</strong> Responder, em todas as instâncias, pelas
                  consequências das ações ou omissões de sua parte, que possam
                  por em risco ou comprometer a exclusividade de conhecimento de
                  sua Senha, ou das transações a que tenha acesso.
                  <br />
                  <strong>5)</strong> Ao solicitar a inclusão de um Usuário no
                  Sistema, sua Identificação será armazenada como o responsável
                  pela solicitação/autorização do cadastramento, portanto,
                  somente o faça se imprescindível.
                  <br />
                  <strong>6)</strong> Todos os acessos ao Sistema, efetuados por
                  meio do seu Usuário e Senha, ficam registrados, sendo você o
                  único responsável, respondendo administrativa, civil e
                  criminalmente pelas operações realizadas.
                  <br />
                  <strong>7)</strong> A utilização de sua Senha, por terceiros,
                  fará com que você incorra em responsabilidade civil, infração
                  disciplinar e quebra de sigilo, constituindo, ainda, infração
                  funcional e penal, ocasionadas por operações não autorizadas e
                  pela facilitação na visualização, alteração, inserção ou
                  exclusão de dados ou informações, conforme responsabilização
                  por crime contra a Administração Pública, tipificado no art.
                  313-A e 313-B da Lei nº 9.983, de 14 de julho de 2000.
                  <br />
                  <strong>8)</strong> Em caso de dúvidas sobre a operação do
                  Sistema, faça contato com o setor de Suporte a Sistemas da
                  SES.
               </p>
            </div>

            <div v-if="isSmallerThan(959)">
               <v-btn @click="logout" color="primary" block text>
                  Recusar
               </v-btn>

               <v-btn
                  @click="authorize"
                  :loading="loading"
                  class="mt-2"
                  color="primary"
                  block
               >
                  Aceitar
                  <v-icon right>mdi-check</v-icon>
               </v-btn>
            </div>
            <div v-else class="d-flex justify-center align-center ma-n1">
               <v-btn @click="logout" class="ma-1" color="primary" text>
                  Recusar
               </v-btn>

               <v-btn
                  @click="authorize"
                  :loading="loading"
                  class="ma-1"
                  color="primary"
               >
                  Aceitar
                  <v-icon right>mdi-check</v-icon>
               </v-btn>
            </div>
         </v-card>
      </div>
   </v-dialog>
</template>

<script>
import responsiveUtil from "utils/responsiveUtil";
import userService from "services/userService";
import snackBarUtil from "utils/snackBarUtil";
import axiosService from "services/axiosService";

export default {
   name: "AuthorizationTerm",

   data: function () {
      return {
         dialogTerm: true,
         loading: false,
      };
   },

   computed: {
      getUserRoutesStore() {
         return this.$store.getters["user/getUserRoutes"];
      },

      isSmallerThanComputed() {
         return this.isSmallerThan(959);
      },

      fullUserStore: {
         get() {
            return this.$store.getters["user/getFullUser"];
         },

         set(value) {
            this.$store.commit("user/setFullUser", value);
         },
      },

      termVisibilityStore: {
         get() {
            return this.$store.getters["term/getVisibility"];
         },

         set(value) {
            this.$store.commit("term/setVisibility", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      closeTerm() {
         this.dialogTerm = false;
         /* Espera a animação de fechar terminar, para desabilitar o dialog. */
         setTimeout(() => {
            this.termVisibilityStore = false;
         }, 300);
      },

      logout() {
         userService.removeUserSession();

         if (this.$route.path !== "/autenticacao")
            this.$router.push({ path: "/autenticacao" });

         this.closeTerm();
      },

      async authorize() {
         if (
            this.fullUserStore &&
            this.fullUserStore.id &&
            axiosService.defaults.headers.common["Authorization"] &&
            axiosService.defaults.headers.common["Authorization"].length > 0
         ) {
            await this.authorizedUser(
               this.fullUserStore.id,
               axiosService.defaults.headers.common["Authorization"].split(
                  " "
               )[1],
               true
            );
            this.closeTerm();
         }
      },

      async authorizedUser(userId, token, authorization) {
         this.loading = true;

         try {
            /* 1. Realiza a requisição para aceitar o termo. */
            await userService.authorizedUser(userId, !!authorization);

            /* 2. Seta o token como default. */
            userService.setUserSession({
               token: token,
            });

            /* 3. Envie o usuário para a página inicial do sistema. */
            if (this.$route.path !== this.getUserRoutesStore[0])
               this.$router.push({ path: this.getUserRoutesStore[0] });
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loading = false;
         }
      },
   },
};
</script>

<style scoped>
.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

.term-topics {
   font-weight: 400 !important;
   font-size: 0.7rem !important;
}
</style>