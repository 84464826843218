<template>
   <div v-if="request.was_created_by_patient">
      <p class="label-small text--secondary">
         <v-icon class="margin-icon" size="18"> mdi-map-marker-outline </v-icon>
         Município
      </p>

      <p v-if="request.municipality.name" class="body-2 mt-1">
         {{ request.municipality.name }}
      </p>
   </div>
   <div v-else>
      <p class="label-small text--secondary">
         <v-icon class="margin-icon" size="16"> mdi-home-city-outline </v-icon>
         Unidade de origem
      </p>

      <p v-if="request.source_health_entity.name" class="body-2 mt-1">
         {{ request.source_health_entity.name }}
      </p>
   </div>
</template>

<script>
export default {
   name: "MyRequestsListDataTableCellOrigin",

   props: {
      request: {
         type: Object,
      },
   },
};
</script>

<style scoped>
.label-small {
   font-size: 0.685rem !important;
   letter-spacing: 0.0125em;
}

.margin-icon {
   margin-right: 3px;
}
</style>