<template>
   <v-card
      @click="view(schedule)"
      class="standard-padding-small item-shadow ma-3"
   >
      <div>
         <p class="body-2 truncate-ellipsis">
            <v-icon class="mr-1" size="20">$medical-bag-outline</v-icon>
            {{ schedule.name }}
         </p>

         <p class="body-2 truncate-ellipsis my-1">
            <v-icon class="mr-1" size="20">mdi-calendar-outline</v-icon>
            {{ formatDate(schedule.scheduling_date, "DD/MM/YYYY") }}
         </p>

         <div class="d-flex align-center">
            <v-icon class="mr-2" size="19">mdi-clock-outline</v-icon>
            <v-chip
               class="chip-status"
               :color="generateSurgeryStatus(schedule.status).color"
               x-small
            >
               {{ generateSurgeryStatus(schedule.status).text }}
            </v-chip>
         </div>

         <p class="updated-at-label mt-1">
            Última atualização em
            {{ formatDate(schedule.updated_at, "DD/MM/YYYY [às] HH:mm") }}.
         </p>
      </div>
   </v-card>
</template>

<script>
import util from "utils/util";
import formatUtil from "utils/formatUtil";

export default {
   name: "MySchedulesListItem",

   props: {
      schedule: {
         type: Object,
      },
   },

   methods: {
      ...formatUtil,
      ...util,

      view(schedule) {
         this.$emit("onClickViewSchedule", schedule);
      },
   },
};
</script>

<style scoped>
.item-shadow {
   box-shadow: rgba(14, 30, 37, 0.1) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.2) 0px 2px 16px 0px !important;
}

.updated-at-label {
   font-weight: 400 !important;
   font-size: 0.7rem !important;
   letter-spacing: 0.0178571429em !important;
   color: #939393;
}

.chip-status {
   border-radius: 20px !important;
   padding-left: 8px;
   padding-right: 8px;
   height: 18px;
}

.truncate-ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}
</style>