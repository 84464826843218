<template>
   <div class="top-image-floating">
      <v-img :src="imageByPath" height="110%" contain> </v-img>
   </div>
</template>

<script>
export default {
   name: "TopImageFloating",

   computed: {
      imageByPath() {
         let path = "warning-circle.png";

         switch (this.$route.path) {
            case "/minhas-solicitacoes":
               path = "requests-image-circle.png";
               break;
            case "/meus-agendamentos":
               path = "schedule-image-circle.png";
               break;
            case "/minha-conta":
               path = "my-data-image-circle.png";
               break;
         }

         return require(`@/assets/images/${path}`);
      },
   },
};
</script>

<style scoped>
.top-image-floating {
   /* position: absolute !important;
   top: 0px; */

   position: sticky !important;
   top: 0px !important;

   height: 18vh;
   width: 100vw;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   overflow: hidden;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );

   border-bottom-left-radius: 20px !important;
   border-bottom-right-radius: 20px !important;
}
</style>