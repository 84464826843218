import stringMask from "string-mask";
import moment from "moment";
moment.locale("pt-br");

function formatDate(date, format, sourceFormat) {
   if (!date) return "-";
   return moment(date, sourceFormat || null).format(format || "DD/MM/YYYY");
}

function formatCPF(CPF) {
   const formatter = new stringMask("000.000.000-00");
   return formatter.apply(CPF.replace(/[^0-9]/g, ""));
}

function formatZipCode(ZipCode) {
   const formatter = new stringMask("00000-000");
   return formatter.apply(ZipCode.replace(/[^0-9]/g, ""));
}

function formatPhone(phone) {
   const formatter = new stringMask("(00) 00000-0000");
   return formatter.apply(phone.replace(/[^0-9]/g, ""));
}

function formatAddress(remoteAddress) {
   const formatter = new stringMask("000.00.0.0");
   return formatter.apply(remoteAddress.replace(/[^0-9]/g, ""));
}

export default {
   formatDate,
   formatCPF,
   formatZipCode,
   formatPhone,
   formatAddress,
};
