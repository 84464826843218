<template>
   <div v-if="!isSmallerThan(959)">
      <v-data-table
         @click:row="view"
         :headers="headers"
         :items="schedules"
         :loading="loadingSchedules"
         :items-per-page="-1"
         class="box-shadow-data-table"
         hide-default-header
         hide-default-footer
      >
         <template v-slot:top>
            <p
               class="
                  font-weight-bold font-sora
                  main-title
                  standard-padding-large
                  primary--text
                  py-4
               "
            >
               <v-icon class="mb-1" color="primary" size="26" left
                  >mdi-calendar</v-icon
               >
               MEUS AGENDAMENTOS
            </p>

            <v-divider></v-divider>
         </template>

         <template v-slot:no-data>
            <div
               class="
                  d-flex
                  flex-column
                  justify-center
                  align-center
                  standard-padding
                  cursor-default
               "
            >
               <p
                  class="
                     text-center
                     font-sora
                     loading-schedules-label
                     primary--text
                  "
               >
                  Por enquanto você não tem <br />
                  agendamentos.
               </p>

               <v-img
                  :src="require('@/assets/images/calendar.png')"
                  height="95px"
                  class="my-4"
                  contain
               >
               </v-img>

               <p
                  class="
                     text-center
                     font-sora
                     loading-schedules-label
                     primary--text
                  "
               >
                  Mas não se preocupe, sua solicitação <br />
                  será atendida em breve!
               </p>
            </div>
         </template>

         <template v-slot:loading>
            <p
               class="
                  text-center
                  font-sora
                  loading-schedules-label
                  primary--text
                  standard-padding
                  cursor-default
               "
            >
               Carregando os <br />
               agendamentos...
            </p>
         </template>

         <template v-slot:[`item.name`]="{ item }">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="16">
                  $medical-bag-outline
               </v-icon>
               Cirurgia solicitada
            </p>

            <p class="body-2 mt-1">{{ item.name }}</p>
         </template>

         <template v-slot:[`item.scheduling_date`]="{ item }">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="16">
                  mdi-calendar-outline
               </v-icon>
               Data do agendamento
            </p>

            <p class="body-2 mt-1">
               {{ formatDate(item.scheduling_date, "DD/MM/YYYY") }}
            </p>
         </template>

         <template v-slot:[`item.status`]="{ item }">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="16">mdi-clock-outline</v-icon>
               Estado
            </p>
            <v-chip
               class="chip-status mt-1"
               :color="generateSurgeryStatus(item.status).color"
               x-small
            >
               {{ generateSurgeryStatus(item.status).text }}
            </v-chip>
         </template>
      </v-data-table>
   </div>

   <div v-else class="standard-padding">
      <div
         v-if="loadingSchedules"
         class="d-flex flex-column justify-center align-center mt-16"
      >
         <p
            class="
               text-center
               font-sora
               loading-schedules-label
               primary--text
               mb-5
            "
         >
            Carregando os <br />
            agendamentos...
         </p>

         <v-progress-linear
            style="width: 50%"
            color="primary"
            indeterminate
            rounded
            height="4"
         ></v-progress-linear>
      </div>

      <div v-else-if="schedules.length" class="mx-n3 mb-n3 mt-n12">
         <MySchedulesListItem
            @onClickViewSchedule="view"
            v-for="(schedule, index) in schedules"
            :key="index"
            :schedule="schedule"
         />
      </div>

      <div v-else class="d-flex flex-column justify-center align-center mt-16">
         <p class="text-center font-sora loading-schedules-label primary--text">
            Por enquanto você <br />
            não tem agendamentos.
         </p>

         <v-img
            :src="require('@/assets/images/calendar.png')"
            height="95px"
            class="my-4"
            contain
         >
         </v-img>

         <p class="text-center font-sora loading-schedules-label primary--text">
            Mas não se preocupe, <br />
            sua solicitação será <br />
            atendida em breve!
         </p>
      </div>
   </div>
</template>

<script>
import MySchedulesListItem from "./MySchedulesListItem";
import responsiveUtil from "utils/responsiveUtil";
import snackBarUtil from "utils/snackBarUtil";
import patientService from "services/patientService";
import util from "utils/util";
import formatUtil from "utils/formatUtil";

export default {
   name: "MySchedulesList",

   components: { MySchedulesListItem },

   data: function () {
      return {
         loadingSchedules: false,
         schedules: [],

         page: 1,
         limit: 100,
         sort: "-created_at",

         headers: [
            { value: "name", align: "start" },
            { value: "scheduling_date", align: "center" },
            { value: "status", align: "center" },
         ],
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
         };

         return query;
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,
      ...formatUtil,
      ...util,

      view(schedule) {
         this.$emit("onClickView", schedule);
      },

      async getAllPatientSurgeries(patientId, query) {
         this.loadingSchedules = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await patientService.getAllPatientSurgeries(
               patientId,
               query
            );

            /* Só exibe as cirurgias que já foram agendadas. */
            this.schedules = Object.assign(
               [],
               response.data.filter((item) => !!item.scheduling_date)
            );
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSchedules = false;
         }
      },
   },

   created() {
      this.getAllPatientSurgeries(this.userStore.sub, this.query);
   },
};
</script>

<style scoped>
.loading-schedules-label {
   font-weight: 400 !important;
   font-size: 0.875rem !important;
   letter-spacing: 0.0178571429em !important;
}

.label-small {
   font-size: 0.685rem !important;
   letter-spacing: 0.0125em;
}

.margin-icon {
   margin-right: 3px;
}

.chip-status {
   border-radius: 20px !important;
   padding-left: 8px;
   padding-right: 8px;
   height: 18px;
}

::v-deep td {
   padding: 15px 30px !important;
}

.registred-by-cell {
   width: 105px !important;
}

.main-title {
   font-size: 1.1rem !important;
   letter-spacing: 0.0125em;
}
</style>