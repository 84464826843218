<template>
   <v-card
      class="d-flex flex-column justify-center align-center border-empty-data"
      height="150"
      width="140"
      flat
   >
      <v-img
         :src="require('@/assets/images/empty-folder.png')"
         class="image-black-white"
         max-height="70px"
         width="70px"
         contain
      >
      </v-img>

      <p class="text-center text--secondary empty-document-label mt-2">
         Nenhum documento <br />
         adicionado
      </p>
   </v-card>
</template>

<script>
export default {
   name: "AttachmentItemEmpty",
};
</script>

<style scoped>
.border-empty-data {
   border-radius: 7px !important;
   border: 2px solid #c4c4c4 !important;
}

.empty-document-label {
   font-weight: 400 !important;
   font-size: 0.7rem !important;
   letter-spacing: 0.0178571429em !important;
}

.image-black-white {
   filter: grayscale(100%);
}
</style>