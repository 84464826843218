<template>
   <v-main class="background">
      <!-- Desktop -->
      <div v-if="!isSmallerThan(959)" class="image-background">
         <transition name="slide" mode="out-in">
            <router-view class="content"> </router-view>
         </transition>
      </div>

      <GoTop v-if="!isSmallerThan(959)" />

      <!-- Mobile -->
      <transition v-else name="slide" mode="out-in">
         <router-view
            :class="bottomNavigationBarActive ? 'content-mobile' : null"
         >
         </router-view>
      </transition>
   </v-main>
</template>

<script>
import GoTop from "components/base/GoTop";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "Content",

   components: { GoTop },

   props: {
      bottomNavigationBarActive: {
         type: Boolean,
         default: true,
      },
   },

   methods: {
      ...responsiveUtil,
   },
};
</script>

<style scoped>
.background {
   grid-area: content;
   background-color: white;
}

.image-background {
   height: 100%;
   background-image: linear-gradient(#e1e5f5cc 100%, #e1e4f5cc 100%),
      url("~@/assets/images/fundo2.png");
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
}

.content {
   height: 100%;
   margin: 0px auto;
}

@media (min-width: 1903px) {
   .content {
      width: 50%;
      padding: 40px 0px;
   }
}

@media (min-width: 1263px) and (max-width: 1903px) {
   .content {
      width: 65%;
      padding: 40px 0px;
   }
}

@media (min-width: 959px) and (max-width: 1263px) {
   .content {
      width: 75%;
      padding: 40px 0px;
   }
}

@media (min-width: 599px) and (max-width: 959px) {
   .content {
      width: 75%;
      padding: 45px 0px;
   }
}

@media (max-width: 599px) {
   .content {
      width: 75%;
      padding: 45px 0px;
   }
}

@keyframes slide-in {
   from {
      transform: translateY(-30px);
      opacity: 0;
   }
   to {
      transform: translateY(0px);
      opacity: 1;
   }
}

@keyframes slide-out {
   from {
      transform: translateY(0px);
      opacity: 1;
   }
   to {
      transform: translateY(-30px);
      opacity: 0;
   }
}

.slide-enter-active {
   animation: slide-in 0.2s ease;
}

.slide-leave-active {
   animation: slide-out 0.2s ease;
}

.content-mobile {
   margin-bottom: 90px;
}
</style>