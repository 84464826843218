<template>
   <v-footer class="footer-config image-background" color="transparent">
      <FooterBase />
   </v-footer>
</template>

<script>
import FooterBase from "./FooterBase";

export default {
   components: { FooterBase },

   name: "Footer",
};
</script>

<style scoped>
.footer-config {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.image-background {
   background-image: linear-gradient(#e1e5f5cc 100%, #e1e4f5cc 100%),
      url("~@/assets/images/fundo2.png");
   background-repeat: no-repeat;
   background-attachment: fixed;
   background-size: cover;
}
</style>