<template>
   <v-tooltip open-delay="300" top>
      <template v-slot:activator="{ on }">
         <v-fab-transition>
            <v-btn
               v-on="on"
               v-show="scrollTop"
               @click="goTop"
               :color="color"
               class="button-scroll-top mx-2"
               height="48"
               width="48"
               fixed
               fab
               dark
            >
               <v-icon dark>mdi-chevron-up</v-icon>
            </v-btn>
         </v-fab-transition>
      </template>
      <span class="tooltip-config">Voltar ao topo</span>
   </v-tooltip>
</template>

<script>
export default {
   name: "GoTop",

   props: {
      color: {
         type: String,
         required: false,
         default: "primary",
      },
   },

   data() {
      return {
         scrollTop: false,
      };
   },

   methods: {
      goTop() {
         this.$vuetify.goTo(0, {
            duration: 200,
            offset: 0,
            easing: "easeInOutCubic",
         });
      },

      handleScroll() {
         this.scrollTop = window.scrollY > 300;
      },
   },

   beforeMount() {
      window.addEventListener("scroll", this.handleScroll);
   },

   beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
   },
};
</script>

<style scoped>
.button-scroll-top {
   bottom: 30px;
   right: 30px;
}
</style>