<template>
   <div>
      <div class="mt-6 mb-12">
         <p class="font-weight-bold font-sora main-title">Trocar senha</p>
         <p class="text--secondary body-2 mt-1">
            Informe a nova <strong>senha</strong> e a
            <strong>confirmação</strong> para resetar sua senha.
         </p>
      </div>

      <v-form ref="form">
         <v-text-field
            v-model="formData.email"
            :rules="[
               (value) => !!value || 'Email obrigatório.',
               (value) => emailRules(value) || 'Email inválido.',
            ]"
            type="email"
            class="body-2 label-position"
            id="email-forgot-password-input"
            label="Email*"
            placeholder="Digite seu email"
            height="40"
            ><template v-slot:prepend-inner>
               <v-icon size="18" left>mdi-email-outline</v-icon>
            </template>
         </v-text-field>

         <v-text-field
            v-model="formData.new_password"
            :type="showNewPassword ? 'text' : 'password'"
            :rules="[
               (value) => !!value || 'Senha obrigatória.',
               (value) =>
                  passwordValidation(value) ||
                  'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
            ]"
            class="mt-10 body-2 label-position"
            id="password-change-password-input"
            label="Senha*"
            placeholder="Digite sua senha"
            height="40"
         >
            <template v-slot:prepend-inner>
               <v-icon size="18" left>mdi-lock-outline</v-icon>
            </template>

            <template v-slot:append>
               <v-icon
                  @click="showNewPassword = !showNewPassword"
                  size="18"
                  id="visualization-change-password-card-icon"
                  left
                  >{{
                     showNewPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}</v-icon
               >
            </template>
         </v-text-field>

         <v-text-field
            v-model="formData.confirm_new_password"
            @keyup.enter="onClickSubmit(formData)"
            :type="showConfirmationNewPassword ? 'text' : 'password'"
            :rules="[
               (value) => !!value || 'Confirmação de senha obrigatória.',
               (value) =>
                  formData.new_password === value || 'Confirmação inválida',
            ]"
            class="mt-10 body-2 label-position"
            id="confirm-password-change-password-input"
            label="Confirmação de senha*"
            placeholder="Digite sua confirmação de senha"
            height="40"
         >
            <template v-slot:prepend-inner>
               <v-icon size="18" left>mdi-lock-check-outline</v-icon>
            </template>

            <template v-slot:append>
               <v-icon
                  @click="
                     showConfirmationNewPassword = !showConfirmationNewPassword
                  "
                  size="18"
                  id="visualization-change-confirm-password-card-icon"
                  left
                  >{{
                     showConfirmationNewPassword
                        ? "mdi-eye-outline"
                        : "mdi-eye-off-outline"
                  }}</v-icon
               >
            </template>
         </v-text-field>
      </v-form>

      <v-btn
         @click="onClickSubmit(formData)"
         :loading="loadingSubmit"
         :large="!isSmallerThan(959)"
         class="mt-13"
         color="primary"
         id="change-button"
         block
         >ALTERAR SENHA
         <v-icon right>mdi-check</v-icon>
      </v-btn>
   </div>
</template>

<script>
import responsiveUtil from "utils/responsiveUtil";
import validationUtil from "utils/validationUtil";

export default {
   name: "ChangePasswordCardBase",

   props: {
      loadingSubmit: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         formData: {},
         showNewPassword: false,
         showConfirmationNewPassword: false,
      };
   },

   methods: {
      ...responsiveUtil,
      ...validationUtil,

      onClickSubmit() {
         /* Valida o formulário */
         const dataValidation = this.validateForm();
         const authToken = this.$route.query.token;

         if (!dataValidation || !authToken) return;

         const emitData = {
            ...this.formData,
            token: authToken,
         };

         this.$emit("onChangePassword", emitData);
      },

      validateForm() {
         return this.$refs.form.validate();
      },

      emailRules(value) {
         if (value) return validationUtil.emailValidation(value);
      },
   },
};
</script>

<style scoped>
::v-deep .label-position > div > div > div > input {
   margin-bottom: 12px !important;
}

.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}
</style>