import Vue from "vue";
import VueRouter from "vue-router";

import snackBarUtil from "../utils/snackBarUtil";
import store from "../store";

import Auth from "../views/auth/Auth.vue";
import ChangePassword from "../views/auth/ChangePassword.vue";
import RetrieveAccount from "../views/auth/RetrieveAccount.vue";
import Registration from "../views/registration/Registration.vue";
import MyRequests from "../views/myRequests/MyRequests.vue";
import MySchedules from "../views/mySchedules/MySchedules.vue";

import MyAccount from "../views/myAccount/MyAccount.vue";
import Page404 from "../views/page404/Page404.vue";
import { PUBLIC_ROUTES } from "../utils/defaultUtil";

Vue.use(VueRouter);

const routes = [
   {
      path: "/autenticacao",
      name: "Auth",
      component: Auth,
   },
   {
      path: "/password-reset",
      name: "ChangePassword",
      component: ChangePassword,
   },
   {
      path: "/recuperar-conta",
      name: "RetrieveAccount",
      component: RetrieveAccount,
   },
   {
      path: "/cadastro",
      name: "Registration",
      component: Registration,
   },
   {
      path: "/minhas-solicitacoes",
      name: "MyRequests",
      component: MyRequests,
   },
   {
      path: "/meus-agendamentos",
      name: "MySchedules",
      component: MySchedules,
   },
   {
      path: "/minha-conta",
      name: "MyAccount",
      component: MyAccount,
   },
   {
      path: "/page404",
      name: "Page404",
      component: Page404,
   },
   {
      path: "/",
      redirect: "/autenticacao",
   },
   {
      path: "*",
      redirect: "/page404",
   },
];

const router = new VueRouter({
   mode: "hash",
   base: process.env.BASE_URL,
   routes,
});

const publicRoutes = PUBLIC_ROUTES;

function showUnauthorizedSnackBar() {
   snackBarUtil.showCustomSnackBar({
      title: "NÃO AUTORIZADO",
      message:
         "Usuário não possui permissão para acessar o recurso solicitado.",
      icon: "mdi-alert-octagon-outline",
      color: "warning",
   });
}

router.beforeEach((to, from, next) => {
   if (from.name) {
      if (store.getters["user/getUser"]) {
         const userRoutes = store.getters["user/getUserRoutes"];

         if (!userRoutes.includes(to.path)) {
            if (!publicRoutes.includes(to.path)) showUnauthorizedSnackBar();

            return next({ path: userRoutes[0] });
         }
      } else if (!publicRoutes.includes(to.path))
         return next({ path: "/autenticacao" });
   }

   next();
});

export default router;
