import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import colors from "vuetify/lib/util/colors";
import MedicalBagOutline from "src/assets/icons/MedicalBagOutline.vue";

Vue.use(Vuetify);

export default new Vuetify({
   theme: {
      options: { customProperties: true },
      themes: {
         light: {
            primary: colors.indigo.lighten1,
            black: "#494949",

            primaryLight: colors.indigo.lighten5,
            successLight: colors.green.lighten5,
            errorLight: colors.red.lighten5,
            warningLight: colors.orange.lighten5,
            infoLight: colors.blue.lighten5,
            purpleLight: colors.purple.lighten5,
            blackLight: "#DBDBDB",
         },
      },
   },

   icons: {
      values: {
         "medical-bag-outline": {
            component: MedicalBagOutline,
         },
      },
   },
});
