var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('v-form',{ref:"form"},[_c('v-text-field',{staticClass:"body-2 label-position",attrs:{"rules":[
            function (value) { return !!value || 'Email obrigatório.'; },
            function (value) { return _vm.emailRules(value) || 'Email inválido.'; } ],"type":"email","id":"email-forgot-password-input","label":"Email*","placeholder":"Digite seu email","height":"40"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"18","left":""}},[_vm._v("mdi-email-outline")])]},proxy:true}]),model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", $$v)},expression:"formData.email"}}),_c('v-text-field',{staticClass:"mt-10 body-2 label-position",attrs:{"type":_vm.showNewPassword ? 'text' : 'password',"rules":[
            function (value) { return !!value || 'Senha obrigatória.'; },
            function (value) { return _vm.passwordValidation(value) ||
               'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.'; } ],"id":"password-change-password-input","label":"Senha*","placeholder":"Digite sua senha","height":"40"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"18","left":""}},[_vm._v("mdi-lock-outline")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"size":"18","id":"visualization-change-password-card-icon","left":""},on:{"click":function($event){_vm.showNewPassword = !_vm.showNewPassword}}},[_vm._v(_vm._s(_vm.showNewPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"))])]},proxy:true}]),model:{value:(_vm.formData.new_password),callback:function ($$v) {_vm.$set(_vm.formData, "new_password", $$v)},expression:"formData.new_password"}}),_c('v-text-field',{staticClass:"mt-10 body-2 label-position",attrs:{"type":_vm.showConfirmationNewPassword ? 'text' : 'password',"rules":[
            function (value) { return !!value || 'Confirmação de senha obrigatória.'; },
            function (value) { return _vm.formData.new_password === value || 'Confirmação inválida'; } ],"id":"confirm-password-change-password-input","label":"Confirmação de senha*","placeholder":"Digite sua confirmação de senha","height":"40"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onClickSubmit(_vm.formData)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"size":"18","left":""}},[_vm._v("mdi-lock-check-outline")])]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"size":"18","id":"visualization-change-confirm-password-card-icon","left":""},on:{"click":function($event){_vm.showConfirmationNewPassword = !_vm.showConfirmationNewPassword}}},[_vm._v(_vm._s(_vm.showConfirmationNewPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"))])]},proxy:true}]),model:{value:(_vm.formData.confirm_new_password),callback:function ($$v) {_vm.$set(_vm.formData, "confirm_new_password", $$v)},expression:"formData.confirm_new_password"}})],1),_c('v-btn',{staticClass:"mt-13",attrs:{"loading":_vm.loadingSubmit,"large":!_vm.isSmallerThan(959),"color":"primary","id":"change-button","block":""},on:{"click":function($event){return _vm.onClickSubmit(_vm.formData)}}},[_vm._v("ALTERAR SENHA "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-check")])],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-6 mb-12"},[_c('p',{staticClass:"font-weight-bold font-sora main-title"},[_vm._v("Trocar senha")]),_c('p',{staticClass:"text--secondary body-2 mt-1"},[_vm._v(" Informe a nova "),_c('strong',[_vm._v("senha")]),_vm._v(" e a "),_c('strong',[_vm._v("confirmação")]),_vm._v(" para resetar sua senha. ")])])}]

export { render, staticRenderFns }