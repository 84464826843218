var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.attachment.loading)?_c('AttachmentItemSkeleton'):_c('v-card',{staticClass:"d-flex flex-column justify-center align-center card-config",attrs:{"height":"150","width":"140","flat":""},on:{"click":function($event){return _vm.viewAttachment(_vm.attachment)}}},[(_vm.imagePreview)?_c('v-img',{staticClass:"image-config",attrs:{"src":_vm.imagePreview,"height":"100%","width":"100%"}}):(_vm.attachment.content.name.toLowerCase().includes('pdf'))?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"red darken-1"}},[_vm._v("mdi-file-pdf-box")]):(
            _vm.attachment.content.name.toLowerCase().includes('doc') ||
            _vm.attachment.content.name.toLowerCase().includes('docx') ||
            _vm.attachment.content.name.toLowerCase().includes('odt')
         )?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"blue darken-1"}},[_vm._v("mdi-file-word")]):(
            _vm.attachment.content.name.toLowerCase().includes('xls') ||
            _vm.attachment.content.name.toLowerCase().includes('xlsx')
         )?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"teal darken-1"}},[_vm._v("mdi-file-excel")]):(
            _vm.attachment.content.name.toLowerCase().includes('pptx') ||
            _vm.attachment.content.name.toLowerCase().includes('pptm') ||
            _vm.attachment.content.name.toLowerCase().includes('ppt')
         )?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"orange darken-3"}},[_vm._v("mdi-file-powerpoint")]):(
            _vm.attachment.content.name.toLowerCase().includes('mp4') ||
            _vm.attachment.content.name.toLowerCase().includes('mov') ||
            _vm.attachment.content.name.toLowerCase().includes('flv') ||
            _vm.attachment.content.name.toLowerCase().includes('wmv') ||
            _vm.attachment.content.name.toLowerCase().includes('avi')
         )?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"red lighten-1"}},[_vm._v("mdi-file-video")]):(_vm.attachment.content.name.toLowerCase().includes('dwg'))?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"indigo lighten-2"}},[_vm._v("mdi-file-cad")]):(
            _vm.attachment.content.name.toLowerCase().includes('zip') ||
            _vm.attachment.content.name.toLowerCase().includes('rar') ||
            _vm.attachment.content.name.toLowerCase().includes('7-zip')
         )?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"lime lighten-1"}},[_vm._v("mdi-folder-zip")]):(_vm.attachment.content.name.toLowerCase().includes('exe'))?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"success lighten-1"}},[_vm._v("mdi-file-cog")]):(_vm.attachment.content.name.toLowerCase().includes('txt'))?_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"light-green darken-3"}},[_vm._v("mdi-text-box")]):_c('v-icon',{staticClass:"mb-11",attrs:{"size":"70","color":"indigo lighten-1"}},[_vm._v("mdi-file-question")]),_c('div',{staticClass:"div-inside-image"},[_c('div',{staticClass:"ma-n3"},[(!_vm.hideRemoveButton)?_c('v-btn',{staticClass:"delete-button",attrs:{"color":"error","fab":"","depressed":"","x-small":""},on:{"click":function($event){return _vm.remove(_vm.attachment)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-delete ")])],1):_vm._e()],1),_c('v-card',{staticClass:"card-attachment-name-config",attrs:{"color":"#E5E5E5","height":"50px","flat":""}},[_c('p',{staticClass:"caption font-weight-bold label-attachment-name"},[_vm._v(" "+_vm._s(_vm.attachment.content.name.toLowerCase())+" ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }