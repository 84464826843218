<template>
   <div v-if="unreadMessages > 0" class="div-notification">
      <p class="label-message-count">{{ unreadMessages }}</p>
   </div>
</template>

<script>
import messageService from "services/messageService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "MyRequestsListDataTableCellNotification",

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         unreadMessages: 0,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler(request) {
            if (request && request.id)
               this.getRequestMessageInfoById(request.id, "patient");
         },
      },
   },

   methods: {
      async getRequestMessageInfoById(requestId, userType) {
         try {
            const response = await messageService.getRequestMessageInfoById(
               requestId,
               userType
            );

            this.unreadMessages = response.data.total_unread_messages;
            if (this.unreadMessages > 0)
               this.$emit("onHaveNotifications", this.unreadMessages);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>
.icon-config {
   position: absolute !important;
   top: 0px;
   left: 0px;

   z-index: 0 !important;
}

.label-message-count {
   text-align: center;
   color: white;
   font-weight: 700;
   font-size: 0.685rem !important;
   letter-spacing: 0.0125em;
}

.div-notification {
   display: flex;
   justify-content: center;
   align-items: center;

   height: 21px;
   width: 21px;
   background-color: var(--v-warning-base);
   border-radius: 50% !important;

   box-shadow: var(--v-warning-base) 0px 0px 3px;

   margin-left: 10px;
   margin-right: -10px;
}
</style>