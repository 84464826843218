<template>
   <div class="d-flex justify-center align-center page-404-content">
      <div>
         <Page404Content class="mt-n16" />
      </div>
   </div>
</template>

<script>
import Page404Content from "../../components/page404/Page404Content";

export default {
   name: "Page404",

   components: { Page404Content },
};
</script>

<style scoped>
.page-404-content {
   height: 100%;
}
</style>