<template>
   <div>
      <AttachmentItemSkeleton v-if="attachment.loading" />

      <v-card
         v-else
         @click="viewAttachment(attachment)"
         class="d-flex flex-column justify-center align-center card-config"
         height="150"
         width="140"
         flat
      >
         <v-img
            v-if="imagePreview"
            :src="imagePreview"
            class="image-config"
            height="100%"
            width="100%"
         ></v-img>

         <v-icon
            v-else-if="attachment.content.name.toLowerCase().includes('pdf')"
            size="70"
            color="red darken-1"
            class="mb-11"
            >mdi-file-pdf-box</v-icon
         >

         <v-icon
            v-else-if="
               attachment.content.name.toLowerCase().includes('doc') ||
               attachment.content.name.toLowerCase().includes('docx') ||
               attachment.content.name.toLowerCase().includes('odt')
            "
            size="70"
            color="blue darken-1"
            class="mb-11"
            >mdi-file-word</v-icon
         >

         <v-icon
            v-else-if="
               attachment.content.name.toLowerCase().includes('xls') ||
               attachment.content.name.toLowerCase().includes('xlsx')
            "
            size="70"
            color="teal darken-1"
            class="mb-11"
            >mdi-file-excel</v-icon
         >

         <v-icon
            v-else-if="
               attachment.content.name.toLowerCase().includes('pptx') ||
               attachment.content.name.toLowerCase().includes('pptm') ||
               attachment.content.name.toLowerCase().includes('ppt')
            "
            size="70"
            color="orange darken-3"
            class="mb-11"
            >mdi-file-powerpoint</v-icon
         >

         <v-icon
            v-else-if="
               attachment.content.name.toLowerCase().includes('mp4') ||
               attachment.content.name.toLowerCase().includes('mov') ||
               attachment.content.name.toLowerCase().includes('flv') ||
               attachment.content.name.toLowerCase().includes('wmv') ||
               attachment.content.name.toLowerCase().includes('avi')
            "
            size="70"
            class="mb-11"
            color="red lighten-1"
            >mdi-file-video</v-icon
         >

         <v-icon
            v-else-if="attachment.content.name.toLowerCase().includes('dwg')"
            size="70"
            class="mb-11"
            color="indigo lighten-2"
            >mdi-file-cad</v-icon
         >

         <v-icon
            v-else-if="
               attachment.content.name.toLowerCase().includes('zip') ||
               attachment.content.name.toLowerCase().includes('rar') ||
               attachment.content.name.toLowerCase().includes('7-zip')
            "
            size="70"
            class="mb-11"
            color="lime lighten-1"
            >mdi-folder-zip</v-icon
         >

         <v-icon
            v-else-if="attachment.content.name.toLowerCase().includes('exe')"
            size="70"
            class="mb-11"
            color="success lighten-1"
            >mdi-file-cog</v-icon
         >

         <v-icon
            v-else-if="attachment.content.name.toLowerCase().includes('txt')"
            size="70"
            class="mb-11"
            color="light-green darken-3"
            >mdi-text-box</v-icon
         >

         <v-icon v-else size="70" class="mb-11" color="indigo lighten-1"
            >mdi-file-question</v-icon
         >

         <div class="div-inside-image">
            <div class="ma-n3">
               <v-btn
                  v-if="!hideRemoveButton"
                  @click="remove(attachment)"
                  class="delete-button"
                  color="error"
                  fab
                  depressed
                  x-small
               >
                  <v-icon dark> mdi-delete </v-icon>
               </v-btn>
            </div>

            <v-card
               class="card-attachment-name-config"
               color="#E5E5E5"
               height="50px"
               flat
            >
               <p class="caption font-weight-bold label-attachment-name">
                  {{ attachment.content.name.toLowerCase() }}
               </p>
            </v-card>
         </div>
      </v-card>
   </div>
</template>

<script>
import AttachmentItemSkeleton from "./AttachmentItemSkeleton";

export default {
   name: "AttachmentItemDetails",

   components: {
      AttachmentItemSkeleton,
   },

   props: {
      attachment: {
         typeof: Object,
      },

      hideRemoveButton: {
         typeof: Boolean,
         default: false,
      },
   },

   data() {
      return {
         imagePreview: null,

         blockClick: false,
      };
   },

   watch: {
      attachment: {
         immediate: true,
         async handler(attachment) {
            this.imagePreview = null;

            if (
               attachment &&
               attachment.content &&
               (attachment.content.name.toLowerCase().includes("jpg") ||
                  attachment.content.name.toLowerCase().includes("jpeg") ||
                  attachment.content.name.toLowerCase().includes("png") ||
                  attachment.content.name.toLowerCase().includes("tif") ||
                  attachment.content.name.toLowerCase().includes("bmp"))
            )
               this.imagePreview = attachment.content.data;
         },
      },
   },

   methods: {
      remove(attachment) {
         this.blockClick = true;
         setTimeout(() => {
            this.$emit("onRemoveAttachment", attachment);
            this.blockClick = false;
         }, 1);
      },

      viewAttachment(attachment) {
         if (!this.blockClick) this.$emit("onViewAttachment", attachment);
      },
   },
};
</script>

<style scoped>
.card-config {
   border-radius: 7px !important;
   border: 2px solid #c4c4c4 !important;
}

.card-attachment-name-config {
   display: flex;
   justify-content: center;
   align-items: center;
   border-top: 2px solid #c4c4c4 !important;
   opacity: 0.95;
   padding: 5px;
   overflow: hidden;
   border-top-left-radius: 0px !important;
   border-top-right-radius: 0px !important;
}

.label-attachment-name {
   display: -webkit-box;
   -webkit-line-clamp: 4;
   -webkit-box-orient: vertical;
   overflow: hidden;
   text-align: center;
   word-break: break-all;
   line-height: 95% !important;
}

.div-inside-image {
   position: absolute;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: stretch;
   height: 100%;
   width: 100%;
}

.image-config {
   border-radius: 5px !important;
}

.delete-button {
   z-index: 2;
}
</style>