import moment from "moment";

function emailValidation(email) {
   return /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
}

function cpfValidation(cpf) {
   if (!cpf) return false;
   if ((cpf = cpf.replace(/[^\d]/g, "")).length != 11) return false;
   if (
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
   )
      return false;
   var r;
   var s = 0;
   for (let i = 1; i <= 9; i++) s = s + parseInt(cpf[i - 1]) * (11 - i);
   r = (s * 10) % 11;
   if (r == 10 || r == 11) r = 0;
   if (r != parseInt(cpf[9])) return false;
   s = 0;
   for (let i = 1; i <= 10; i++) s = s + parseInt(cpf[i - 1]) * (12 - i);
   r = (s * 10) % 11;
   if (r == 10 || r == 11) r = 0;
   if (r != parseInt(cpf[10])) return false;
   return true;
}

function dateValidation(date, format) {
   if (date && date.length >= 10) return moment(date, format || "DD/MM/YYYY").isValid();
   return false;
}

function passwordValidation(password) {
   if (password && password.length >= 6)
      return /^(?=(?:.*?[A-Z]){1})(?=(?:.*?[!@#$%*()_+^&}{:;?.]){1})(?!.*\s)[0-9a-zA-Z!@#$%;*(){}_+^&]*$/.test(
         password
      );
   return false;
}

export default { emailValidation, cpfValidation, dateValidation, passwordValidation };
