<template>
   <div>
      <v-data-table
         :headers="headers"
         :items="avaliableSurgeries"
         :loading="loadingAvaliableSurgeries"
         :dense="!isSmallerThan(959)"
         mobile-breakpoint="0"
         loader-height="0px"
         hide-default-header
         hide-default-footer
         disable-sort
      >
         <template v-slot:footer>
            <ListFooter
               @onChangePage="onChangePage"
               @onChangeLimit="onChangeLimit"
               :page="page"
               :limit="limit"
               :totalItems="totalAvaliableSurgeries"
               :loading="loadingAvaliableSurgeries"
            />
         </template>

         <template v-slot:no-data>
            <p class="py-6">
               Desculpe, nenhuma cirurgia aceita foi encontrada.
            </p>
         </template>

         <template v-slot:loading>
            <p class="py-6">Carregando cirurgias aceitas, aguarde por favor.</p>
         </template>
      </v-data-table>
   </div>
</template>

<script>
import ListFooter from "./ListFooter";
import snackBarUtil from "utils/snackBarUtil";
import avaliableSurgeryService from "services/avaliableSurgeryService";
import surgerieService from "services/surgerieService";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "AvailableSurgeriesList",

   components: { ListFooter },

   data: function () {
      return {
         headers: [
            {
               text: "Nome",
               class: "primary--text body-2 font-weight-medium",
               value: "name",
            },
         ],

         avaliableSurgeries: [],

         totalAvaliableSurgeries: 0,
         page: 1,
         limit: 20,

         loadingAvaliableSurgeries: false,

         surgeryNameKey: 0,
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
         };

         return query;
      },
   },

   methods: {
      ...responsiveUtil,

      onChangePage(page) {
         this.page = page;
         this.getAllAvaliableSurgerys(this.query);
      },

      onChangeLimit(limit) {
         this.limit = limit;
         this.page = 1;
         this.getAllAvaliableSurgerys(this.query);
      },

      async getAllAvaliableSurgerys(query) {
         this.loadingAvaliableSurgeries = true;
         this.avaliableSurgeries = [];

         try {
            const response =
               await avaliableSurgeryService.getAllAvaliableSurgeries(query);
            this.totalAvaliableSurgeries = parseInt(
               response.headers["x-total-count"]
            );

            const requestsModifiedStatus = Object.assign([], response.data);

            const promisses = [];
            requestsModifiedStatus.forEach((item) => {
               promisses.push(
                  surgerieService
                     .getAllSurgeries({
                        page: 1,
                        limit: 1,
                        filter: [
                           {
                              attribute: "sus_code",
                              query: item.sus_code,
                           },
                        ],
                     })
                     .then((response) => {
                        this.avaliableSurgeries.push(response.data[0]);
                     })
               );
            });

            Promise.all(promisses).then(() => {
               this.loadingAvaliableSurgeries = false;
            });
         } catch (error) {
            this.loadingAvaliableSurgeries = false;
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      forceSurgeryRerender() {
         this.surgeryNameKey += 1;
      },
   },

   created() {
      this.getAllAvaliableSurgerys(this.query);
   },
};
</script>

<style scoped>
::v-deep td {
   padding: 0px !important;
   pointer-events: none !important;
   border-bottom: none !important;

   text-align: center !important;
}

@media (max-width: 959px) {
   ::v-deep td {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
   }
}

::v-deep .theme--light.v-divider {
   border: none !important;
}
</style>