import axiosService from "./axiosService";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getAllHealthUnitSurgeries(healthUnitId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;

   let url = `/healthunits/${healthUnitId}/surgeries?page=${tempPage}&limit=${tempLimit}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach((item) => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            case "status":
               url += `${item.query}`;
               break;
            default:
               url += `*${item.query}*`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function updateHealthUnitSurgerie(healthUnitId, surgerieId, surgerie) {
   return await axiosService.patch(
      `/healthunits/${healthUnitId}/surgeries/${surgerieId}`,
      surgerie
   );
}

async function updateStatusHealthUnitSurgerie(
   healthUnitId,
   surgerieId,
   surgerie
) {
   return await axiosService.put(
      `/healthunits/${healthUnitId}/surgeries/${surgerieId}/status`,
      surgerie
   );
}

async function getAllSurgeries(query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;

   let url = `/surgeries?page=${tempPage}&limit=${tempLimit}`;

   if (query && query.filter && query.filter.length) {
      query.filter.forEach((item) => {
         url += `&${item.attribute}=`;
         switch (item.attribute) {
            default:
               url += `${item.query}`;
               break;
         }
      });
   }

   return await axiosService.get(url);
}

async function getSurgerieBySusCode(surgerieId) {
   return await axiosService.get(`/surgeries/${surgerieId}`);
}

export default {
   getAllHealthUnitSurgeries,
   updateHealthUnitSurgerie,
   updateStatusHealthUnitSurgerie,
   getAllSurgeries,
   getSurgerieBySusCode,
};
