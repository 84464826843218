<template>
   <div>
      <v-dialog
         v-model="dialogAvailableSurgeries"
         :fullscreen="isSmallerThanComputed"
         :hide-overlay="isSmallerThanComputed"
         :transition="
            isSmallerThanComputed
               ? 'dialog-bottom-transition'
               : 'slide-y-transition'
         "
         max-width="600"
      >
         <v-card tile>
            <Close
               @close="closeAvailableSurgeries"
               :desktop="!isSmallerThanComputed"
            />

            <div class="standard-padding padding-top-mobile">
               <p class="font-weight-bold font-sora main-title mb-4">
                  Cirurgias aceitas
               </p>

               <AvailableSurgeriesList />
            </div>
         </v-card>
      </v-dialog>

      <v-dialog
         v-model="dialogForm"
         :fullscreen="isSmallerThanComputed"
         :hide-overlay="isSmallerThanComputed"
         :persistent="isSmallerThanComputed"
         :transition="
            isSmallerThanComputed
               ? 'dialog-bottom-transition'
               : 'slide-y-transition'
         "
         width="500px"
      >
         <v-card tile id="scrollTop">
            <Close @close="closeDialogForm" :desktop="!isSmallerThanComputed" />

            <Message
               :request="requestMessageComputed"
               class="standard-padding padding-top-mobile"
            />
         </v-card>
      </v-dialog>

      <div v-if="request.id">
         <div class="mb-10">
            <p class="font-weight-bold font-sora main-title">
               Minha solicitação
            </p>

            <p class="text--secondary body-2 mt-1">
               Cadastrada por
               <strong>{{
                  request.responsibles && request.responsibles.length
                     ? "operador"
                     : "você"
               }}</strong>
               em
               {{ formatDate(request.created_at, "DD/MM/YYYY [às] HH:mm") }}.
            </p>
         </div>

         <v-alert
            v-if="request.is_archived"
            class="caption mb-7 py-4 px-5"
            color="warning"
            border="left"
            dense
            text
         >
            <div class="d-flex align-center">
               <v-icon class="mr-3" color="warning" size="30"
                  >mdi-alert-outline</v-icon
               >

               <p class="body-2">
                  Sua solicitação foi
                  <strong>arquivada</strong>! Entre em contato com a
                  <strong>secretaria de saúde</strong> do seu município para
                  mais informações.
               </p>
            </div>
         </v-alert>

         <v-alert
            v-else-if="request.status === 'answered'"
            class="caption mb-7 py-4 px-5"
            color="success"
            border="left"
            dense
            text
         >
            <span v-if="!request.is_closed" class="notification-schedule-bell">
               <v-icon size="14" dark>mdi-check</v-icon>
            </span>

            <div class="d-flex align-center">
               <v-icon class="mr-3" color="success" size="30"
                  >mdi-checkbox-marked-circle-outline</v-icon
               >

               <p class="body-2">
                  Sua cirurgia foi
                  <strong>agendada</strong>! Visualize seus agendamentos.
               </p>
            </div>

            <v-btn
               @click="onNavigateToSchedules"
               class="mt-3"
               color="success"
               small
               block
               >Visualizar agendamentos</v-btn
            >
         </v-alert>

         <div class="mb-5">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="16">
                  mdi-text-box-search-outline
               </v-icon>
               Código
            </p>
            <p class="body-2 mt-1">{{ request.code }}</p>
            <v-divider class="mt-2 mb-6"></v-divider>
         </div>

         <div v-if="request.was_created_by_patient" class="mb-5">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="18">
                  mdi-map-marker-outline
               </v-icon>
               Município
            </p>
            <p v-if="request.municipality.name" class="body-2 mt-1">
               {{ request.municipality.name }}
            </p>
            <v-skeleton-loader
               v-else
               class="rounded-pill mt-2 mb-3"
               type="image"
               width="70%"
               height="12px"
            ></v-skeleton-loader>

            <v-divider class="mt-2 mb-6"></v-divider>
         </div>
         <div v-else class="mb-5">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="16">
                  mdi-home-city-outline
               </v-icon>
               Unidade de origem
            </p>
            <p v-if="request.source_health_entity.name" class="body-2 mt-1">
               {{ request.source_health_entity.name }}
            </p>
            <v-skeleton-loader
               v-else
               class="rounded-pill mt-2 mb-3"
               type="image"
               width="70%"
               height="12px"
            ></v-skeleton-loader>

            <v-divider class="mt-2 mb-6"></v-divider>
         </div>

         <div v-if="request.doctor_opinion">
            <div v-if="request.doctor_opinion.sus_code" class="mb-5">
               <p class="label-small text--secondary">
                  <v-icon class="margin-icon" size="16">
                     $medical-bag-outline
                  </v-icon>
                  Cirurgia solicitada
               </p>

               <p v-if="surgery.name" class="body-2 mt-1">
                  {{ surgery.name }}
               </p>
               <v-skeleton-loader
                  v-else
                  class="rounded-pill mt-2 mb-3"
                  type="image"
                  width="70%"
                  height="12px"
               ></v-skeleton-loader>

               <v-divider class="mt-2 mb-6"></v-divider>
            </div>

            <div v-if="request.doctor_opinion.justification" class="mb-5">
               <p class="label-small text--secondary">
                  <v-icon class="margin-icon" size="16"> mdi-doctor </v-icon>
                  Justificativa médica
               </p>

               <p class="body-2 mt-1">
                  {{ request.doctor_opinion.justification }}
               </p>

               <v-divider class="mt-2 mb-6"></v-divider>
            </div>
         </div>

         <div class="mb-5">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="16">mdi-clock-outline</v-icon>
               Estado
            </p>

            <v-chip
               v-if="!request.is_archived"
               class="mt-1"
               :color="generateState(request.status).color"
               small
            >
               {{ generateState(request.status).text }}
            </v-chip>

            <v-chip v-else class="mt-1" color="warning" small>
               Arquivada
            </v-chip>

            <v-divider class="mt-3 mb-6"></v-divider>
         </div>
      </div>
      <div v-else class="mb-10">
         <p class="font-weight-bold font-sora main-title">Nova solicitação</p>

         <p class="text--secondary body-2 mt-1">
            Preencha os <strong>documentos</strong> abaixo para cadastrar uma
            nova solicitação de cirurgia, em seguida clique em
            <strong> salvar</strong> .
         </p>

         <v-alert class="mt-5 mb-6" color="primary" text>
            <div class="d-flex flex-column align-start ma-n1">
               <p class="body-2 ma-1">
                  Você tem dúvida se sua cirurgia é aceita pelo
                  <strong>OperaPB</strong>? Verifique abaixo as cirurgias
                  aceitas.
               </p>

               <v-btn
                  @click="openAvailableSurgeries"
                  class="ma-1"
                  color="primary"
                  small
                  >Cirurgias aceitas</v-btn
               >
            </div>
         </v-alert>
      </div>

      <div v-if="request.id">
         <p class="label-small text--secondary mt-2 mb-4">
            <v-icon class="margin-icon" size="16">mdi-paperclip</v-icon>
            Documentos (comprovante de residência e Laudo médico)
         </p>

         <MyRequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            :editMode="getMode() === 'save'"
            :hideRemoveButton="request.is_closed"
            ref="requestattachmentsupdate"
         />

         <div class="mt-4">
            <p
               v-if="invalidAttachmentValidationUpdate"
               class="caption error--text mb-2"
            >
               <v-icon class="mr-1" color="error" small
                  >mdi-alert-outline</v-icon
               >
               Adicione pelo um documento <strong>novo</strong> para continuar.
            </p>
            <p
               v-if="sizeAttachmentValidationUpdate"
               class="caption error--text"
            >
               <v-icon class="mr-1" color="error" small
                  >mdi-alert-outline</v-icon
               >
               Os documentos juntos não podem <strong>exceder</strong> 5 MB.
            </p>
         </div>
      </div>
      <div v-else>
         <p class="body-2 mt-2 mb-4">
            <v-icon class="margin-icon" size="16">mdi-paperclip</v-icon>
            Comprovante de residência
         </p>

         <MyRequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            ref="requestattachmentscreate1"
            editMode
         />

         <p class="body-2 mt-8 mb-4">
            <v-icon class="margin-icon" size="16">mdi-paperclip</v-icon>
            Laudo médico e exames
         </p>

         <MyRequestAttachments
            :request="request"
            :allowedTypes="ALLOWED_TYPES"
            :maximumSize="MAXIMUM_SIZE"
            ref="requestattachmentscreate2"
            editMode
         />

         <div class="mt-4">
            <p
               v-if="invalidAttachmentValidationCreate"
               class="caption error--text mb-2"
            >
               <v-icon class="mr-1" color="error" small
                  >mdi-alert-outline</v-icon
               >
               Adicione no mínimo um
               <strong>comprovante de residência</strong> e no mínimo um
               <strong>laudo médico/exame</strong>.
            </p>
            <p
               v-if="sizeAttachmentValidationCreate"
               class="caption error--text"
            >
               <v-icon class="mr-1" color="error" small
                  >mdi-alert-outline</v-icon
               >
               Os documentos juntos não podem <strong>exceder</strong> 5 MB.
            </p>
         </div>
      </div>

      <div v-if="isSmallerThanComputed" class="mt-8">
         <v-btn
            @click="notification"
            v-if="request.id && getMode() === 'read' && !request.is_archived"
            class="mb-3"
            color="success"
            dark
            block
         >
            <span v-if="request.unreadMessages > 0" class="notification-bell">
               <p class="font-weight-bold caption">
                  {{ request.unreadMessages }}
               </p>
            </span>
            Notificações
            <v-icon right>mdi-message-reply-text-outline</v-icon>
         </v-btn>

         <div v-if="!request.is_closed && !request.is_archived">
            <v-btn
               @click="edit"
               v-if="request.id && getMode() === 'read'"
               dark
               block
            >
               Adicionar documentos
               <v-icon right>mdi-plus</v-icon>
            </v-btn>

            <v-btn
               v-else
               @click="save"
               :loading="loadingSave"
               color="primary"
               block
            >
               Salvar
               <v-icon right>mdi-check</v-icon>
            </v-btn>
         </div>

         <v-btn @click="back" color="primary" class="mt-3" block text>
            Voltar
         </v-btn>
      </div>
      <div v-else class="d-flex justify-end align-center mt-8 mx-n1 mb-n1">
         <v-btn
            @click="notification"
            v-if="request.id && getMode() === 'read' && !request.is_archived"
            color="success"
            class="ma-1"
            dark
         >
            <span v-if="request.unreadMessages > 0" class="notification-bell">
               <p class="font-weight-bold caption">
                  {{ request.unreadMessages }}
               </p>
            </span>
            Notificações
            <v-icon right>mdi-message-reply-text-outline</v-icon>
         </v-btn>

         <div
            v-if="!request.is_closed && !request.is_archived"
            class="d-flex align-center"
         >
            <v-btn
               @click="edit"
               v-if="request.id && getMode() === 'read'"
               class="ma-1"
               dark
            >
               Adicionar documentos
               <v-icon right>mdi-plus</v-icon>
            </v-btn>

            <div v-else class="d-flex align-center ma-n1">
               <v-btn @click="back" class="ma-1" color="primary" text>
                  Voltar
               </v-btn>

               <v-btn
                  @click="save"
                  :loading="loadingSave"
                  class="ma-1"
                  color="primary"
               >
                  Salvar
                  <v-icon right>mdi-check</v-icon>
               </v-btn>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import MyRequestAttachments from "./MyRequestAttachments";
import AvailableSurgeriesList from "./availableSurgeries/AvailableSurgeriesList";
import Close from "components/base/Close";
import Message from "components/message/Message";
import snackBarUtil from "utils/snackBarUtil";
import surgerieService from "services/surgerieService";
import util from "utils/util";
import formatUtil from "utils/formatUtil";
import patientService from "services/patientService";
import attachmentService from "services/attachmentService";
import responsiveUtil from "utils/responsiveUtil";
import { SURGERY_REQUEST } from "utils/translateUtil";

const ALLOWED_TYPES = ["application/pdf", "image/png", "image/jpeg"];
const MAXIMUM_SIZE = 5000000;

export default {
   name: "MyRequestsForm",

   components: { MyRequestAttachments, Close, Message, AvailableSurgeriesList },

   props: {
      request: {
         type: Object,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         ALLOWED_TYPES: ALLOWED_TYPES,
         MAXIMUM_SIZE: MAXIMUM_SIZE,

         mode: "read",
         surgery: {},

         invalidAttachmentValidationUpdate: false,
         sizeAttachmentValidationUpdate: false,
         invalidAttachmentValidationCreate: false,
         sizeAttachmentValidationCreate: false,

         loadingSave: false,
         dialogForm: false,
         dialogAvailableSurgeries: false,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler(request) {
            if (request.id && !this.editMode) this.setMode("read");
            else this.setMode("save");

            this.resetData();
            this.resetValidation();

            /* Busca a cirurgia solicitada caso tenha sido cadastrada */
            if (
               request &&
               request.doctor_opinion &&
               request.doctor_opinion.sus_code
            )
               this.getAllSurgeries(request.doctor_opinion.sus_code);
         },
      },
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      /* Função necessária para realizar a requisição GET das mensagens somente ao abrir o dialog das notificações. */
      requestMessageComputed() {
         return this.dialogForm ? this.request : null;
      },

      isSmallerThanComputed() {
         return this.isSmallerThan(959);
      },
   },

   methods: {
      ...formatUtil,
      ...responsiveUtil,
      ...util,

      openDialogForm() {
         this.dialogForm = true;
      },

      closeDialogForm() {
         this.dialogForm = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },

      setMode(mode) {
         this.mode = mode;
      },

      getMode() {
         return this.mode;
      },

      back() {
         this.setMode("read");
         this.$emit("onClickBack");
      },

      edit() {
         this.setMode("save");
      },

      notification() {
         this.openDialogForm();
      },

      save() {
         if (this.request.id) this.update(this.request.id);
         else this.created();
      },

      resetData() {
         this.surgery = {};
      },

      resetValidation() {
         this.invalidAttachmentValidationUpdate = false;
         this.sizeAttachmentValidationUpdate = false;

         this.invalidAttachmentValidationCreate = false;
         this.sizeAttachmentValidationCreate = false;
      },

      validateAttachmentsUpdate() {
         this.invalidAttachmentValidationUpdate = false;
         this.sizeAttachmentValidationUpdate = false;
         let attachmentsAdded = [];

         if (this.$refs.requestattachmentsupdate)
            attachmentsAdded = this.$refs.requestattachmentsupdate
               .getAttachments()
               .filter((item) => item.raw);

         let totalSize;

         /* Verificação se todos os arquivos são menores que 5 MB. */
         if (this.$refs.requestattachmentsupdate)
            totalSize = this.$refs.requestattachmentsupdate
               .getAttachments()
               .reduce(
                  (previousValue, currentValue) =>
                     previousValue + currentValue.content.size,
                  0
               );

         const validationResult =
            !attachmentsAdded.length || totalSize > MAXIMUM_SIZE;

         setTimeout(() => {
            this.invalidAttachmentValidationUpdate = !attachmentsAdded.length;
            this.sizeAttachmentValidationUpdate = totalSize > MAXIMUM_SIZE;
         }, 300);

         return !validationResult;
      },

      validateAttachmentsCreate() {
         this.invalidAttachmentValidationCreate = false;
         this.sizeAttachmentValidationCreate = false;
         let attachmentsAdded1 = [];
         let attachmentsAdded2 = [];

         if (this.$refs.requestattachmentscreate1)
            attachmentsAdded1 = this.$refs.requestattachmentscreate1
               .getAttachments()
               .filter((item) => item.raw);

         if (this.$refs.requestattachmentscreate2)
            attachmentsAdded2 = this.$refs.requestattachmentscreate2
               .getAttachments()
               .filter((item) => item.raw);

         /* Verificação se todos os arquivos são menores que 5 MB. */
         const totalSize = attachmentsAdded1
            .concat(attachmentsAdded2)
            .reduce(
               (previousValue, currentValue) =>
                  previousValue + currentValue.content.size,
               0
            );

         const validationResult =
            !attachmentsAdded1.length ||
            !attachmentsAdded2.length ||
            totalSize > MAXIMUM_SIZE;

         setTimeout(() => {
            this.invalidAttachmentValidationCreate =
               !attachmentsAdded1.length || !attachmentsAdded2.length;
            this.sizeAttachmentValidationCreate = totalSize > MAXIMUM_SIZE;
         }, 300);

         return !validationResult;
      },

      onNavigateToSchedules() {
         if (this.$route.path !== "/meus-agendamentos")
            this.$router.push({ path: "/meus-agendamentos" });
      },

      async getAllSurgeries(susCode) {
         try {
            const response = await surgerieService.getAllSurgeries({
               page: 1,
               limit: 1,
               filter: [
                  {
                     attribute: "sus_code",
                     query: susCode,
                  },
               ],
            });

            this.surgery = Object.assign({}, response.data[0]);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },

      async update(requestId) {
         if (
            !this.validateAttachmentsUpdate() ||
            !this.userStore ||
            !this.userStore.sub
         )
            return;

         this.loadingSave = true;

         try {
            /* 1. Salva os anexos */
            if (this.$refs.requestattachmentsupdate && requestId) {
               this.attachmentsAdded = this.$refs.requestattachmentsupdate
                  .getAttachments()
                  .filter((item) => item.raw);

               if (this.attachmentsAdded.length) {
                  for (
                     let index = 0;
                     index < this.attachmentsAdded.length;
                     index++
                  ) {
                     const item = this.attachmentsAdded[index];

                     let attachment = new FormData();
                     attachment.append("attachment", item.content.originalData);

                     await attachmentService.createRequestAttachment(
                        requestId,
                        attachment
                     );
                  }
               }
            }

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi atualizada!",
            });

            this.setMode("read");
            this.$emit("onUpdate");
         } catch (error) {
            this.catch(error);
         } finally {
            this.loadingSave = false;
         }
      },

      async created() {
         if (
            !this.validateAttachmentsCreate() ||
            !this.userStore ||
            !this.userStore.sub
         )
            return;

         this.loadingSave = true;

         try {
            /* 1. Salva a soliticação */
            const response = await patientService.createPatientSurgeryRequest(
               this.userStore.sub
            );

            /* 2. Salva os anexos */
            if (
               this.$refs.requestattachmentscreate1 &&
               this.$refs.requestattachmentscreate2 &&
               response.data &&
               response.data.id
            ) {
               /* Adiciona os anexos da parte de 'Comprovante de residência'. */
               this.attachmentsAdded = this.$refs.requestattachmentscreate1
                  .getAttachments()
                  .filter((item) => item.raw);

               /* Adiciona os anexos da parte de 'Laudo médico e exames'. */
               this.attachmentsAdded = this.attachmentsAdded.concat(
                  this.$refs.requestattachmentscreate2
                     .getAttachments()
                     .filter((item) => item.raw)
               );

               if (this.attachmentsAdded.length) {
                  for (
                     let index = 0;
                     index < this.attachmentsAdded.length;
                     index++
                  ) {
                     const item = this.attachmentsAdded[index];

                     let attachment = new FormData();
                     attachment.append("attachment", item.content.originalData);

                     await attachmentService.createRequestAttachment(
                        response.data.id,
                        attachment
                     );
                  }
               }
            }

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "A solicitação foi cadastrada!",
            });

            this.setMode("read");
            this.$emit("onCreated");
         } catch (error) {
            this.catch(error);
         } finally {
            this.loadingSave = false;
         }
      },

      catch(error) {
         if (error.response && error.response.status === 400)
            snackBarUtil.showCustomSnackBar(this.translateError400(error));
         else snackBarUtil.showErrorSnackBar(error);
      },

      translateError400(error) {
         let message = "Verifique os dados informados e tente novamente.";
         if (SURGERY_REQUEST[400][error.response.data.message])
            message = SURGERY_REQUEST[400][error.response.data.message];
         else if (SURGERY_REQUEST[400][error.response.data.description])
            message = SURGERY_REQUEST[400][error.response.data.description];

         return {
            title: "DADOS INCORRETOS",
            message: message,
            icon: "mdi-text-box-remove-outline",
            color: "warning",
            timeout: 6000,
         };
      },

      closeAvailableSurgeries() {
         this.dialogAvailableSurgeries = false;
      },

      openAvailableSurgeries() {
         this.dialogAvailableSurgeries = true;
      },
   },
};
</script>

<style scoped>
.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

.label-small {
   font-size: 0.685rem !important;
   letter-spacing: 0.0125em;
}

.margin-icon {
   margin-right: 3px;
}

.notification-bell {
   position: absolute;
   top: -20px;
   right: -24px;

   height: 20px;
   width: 20px;
   background-color: var(--v-warning-base);
   border-radius: 50% !important;

   box-shadow: var(--v-warning-base) 0px 0px 3px;

   display: flex;
   justify-content: center;
   align-items: center;
}

.notification-schedule-bell {
   position: absolute;
   top: -7px;
   right: -7px;

   height: 20px;
   width: 20px;
   background-color: var(--v-warning-base);
   border-radius: 50% !important;

   box-shadow: var(--v-warning-base) 0px 0px 3px;

   display: flex;
   justify-content: center;
   align-items: center;
}

@media (max-width: 959px) {
   .padding-top-mobile {
      padding-top: 40px;
   }
}
</style>