<template>
   <div class="d-flex justify-center align-center retrieve-account-div">
      <RetrieveAccountCard class="retrieve-account-card" />
   </div>
</template>

<script>
import RetrieveAccountCard from "../../components/auth/RetrieveAccountCard";

export default {
   name: "RetrieveAccount",

   components: { RetrieveAccountCard },
};
</script>

<style scoped>
.retrieve-account-div {
   height: 100%;
}

.retrieve-account-card {
   width: 400px;
}
</style>