<template>
   <div>
      <v-divider class="mb-5"></v-divider>

      <div class="d-flex justify-center align-center">
         <v-skeleton-loader
            v-if="loading"
            class="rounded-pill mr-4"
            type="image"
            height="15px"
            width="120px"
         ></v-skeleton-loader>
         <p v-else class="body-2 text--secondary mr-2">
            Exibindo {{ rangeItems.min }}-{{ rangeItems.max }} de
            {{ totalItems }}
         </p>

         <v-pagination
            @input="changePage"
            :value="page"
            :total-visible="0"
            :length="paginationLength"
            :disabled="loading"
            class="mr-n2"
            color="primaryLight"
            circle
         ></v-pagination>
      </div>
   </div>
</template>

<script>
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "ListFooter",

   props: {
      page: {
         type: Number,
         default: 1,
      },

      limit: {
         type: Number,
         default: 20,
      },

      totalItems: {
         type: Number,
         default: 0,
      },

      loading: {
         type: Boolean,
         default: false,
      },
   },

   computed: {
      paginationLength() {
         return Math.ceil(this.totalItems / this.limit);
      },

      rangeItems() {
         let min = 0;
         let max = 0;
         if (this.totalItems > 0) {
            min =
               (this.page - 1) * this.limit > 0
                  ? (this.page - 1) * this.limit
                  : 1;

            max =
               this.page * this.limit <= this.totalItems
                  ? this.page * this.limit
                  : this.totalItems;
         }

         return {
            min,
            max,
         };
      },
   },

   methods: {
      ...responsiveUtil,

      changePageFromInput(page) {
         let pageTemp = page;

         if (page > this.paginationLength) pageTemp = this.paginationLength;
         else if (page < 1) pageTemp = 1;

         if (this.page !== pageTemp) this.$emit("onChangePage", pageTemp);
      },

      changePage(page) {
         if (this.page !== page) this.$emit("onChangePage", page);
      },

      changeLimit(limit) {
         if (this.limit !== limit) this.$emit("onChangeLimit", limit);
      },
   },
};
</script>

<style scoped>
.column-limit {
   display: flex;
   align-items: center;
   justify-content: flex-start;
}

@media (max-width: 959px) {
   .column-limit {
      justify-content: center;
      padding-bottom: 0px;
   }
}

.column-pagination {
   display: flex;
   align-items: center;
   justify-content: flex-end;
}

@media (max-width: 959px) {
   .column-pagination {
      justify-content: center;
   }
}

.width-config {
   max-width: 60px;
}
</style>