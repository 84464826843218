<template>
   <!-- Desktop -->
   <v-card
      v-if="!isSmallerThan(959)"
      class="card-border overflow-hidden"
      width="1000px"
   >
      <v-row no-gutters>
         <v-col
            class="d-flex flex-column justify-center align-stretch"
            cols="6"
         >
            <div class="px-12 py-16">
               <v-img
                  :src="require('@/assets/images/operapb.png')"
                  height="180px"
                  contain
               >
               </v-img>

               <AuthCardBase
                  @onCheckCpf="checkCpf"
                  @onAuth="auth"
                  @changeExistingEmail="changeExistingEmail"
                  :loadingSubmit="loadingSubmit || loading"
                  :existingAccount="existingAccount"
                  class="mt-16"
                  ref="authcarddesktop"
               />
            </div>
         </v-col>

         <v-col cols="6">
            <div class="side-right">
               <v-img
                  :src="require('@/assets/images/consult-image-circle.png')"
                  height="70%"
                  contain
               >
               </v-img>
            </div>
         </v-col>
      </v-row>
   </v-card>

   <!-- Mobile -->
   <div v-else class="background-mobile">
      <v-img
         :src="require('@/assets/images/operapb-white-circle.png')"
         height="40%"
         class="image-sticky-top-position"
         contain
      >
      </v-img>

      <v-card
         class="
            float-bottom-card
            border-bottom-card
            box-shadow-bottom-card
            standard-padding-whopping
            pb-8
         "
      >
         <AuthCardBase
            @onCheckCpf="checkCpf"
            @onAuth="auth"
            @changeExistingEmail="changeExistingEmail"
            :loadingSubmit="loadingSubmit || loading"
            :existingAccount="existingAccount"
            class="mt-5 mb-7"
            ref="authcardmobile"
         />

         <FooterBase class="mb-n3" :divider="false" />
      </v-card>
   </div>
</template>

<script>
import AuthCardBase from "./AuthCardBase";
import FooterBase from "components/base/FooterBase";
import responsiveUtil from "utils/responsiveUtil";
import patientService from "services/patientService";
import snackBarUtil from "utils/snackBarUtil";
import authService from "services/authService";

export default {
   name: "AuthCard",

   components: { AuthCardBase, FooterBase },

   props: {
      loading: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         loadingSubmit: false,
         existingAccount: false,
      };
   },

   computed: {
      emailStore: {
         get() {
            return this.$store.getters["patient/getEmail"];
         },

         set(value) {
            this.$store.commit("patient/setEmail", value);
         },
      },

      cpfStore: {
         get() {
            return this.$store.getters["patient/getCpf"];
         },

         set(value) {
            this.$store.commit("patient/setCpf", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      async checkCpf(data) {
         this.loadingSubmit = true;

         try {
            this.cpfStore = data.cpf;

            const response = await patientService.check(data.cpf, data.token);

            if (
               response &&
               response.data &&
               response.data.registered_with_auth
            ) {
               /* 1 caso - Paciente cadastrado na plataforma COM email e senha. */
               this.existingAccount = true;
               this.resetRecaptcha();
            } else {
               /* 2 caso - Paciente cadastrado na plataforma SEM email e senha. */
               this.$emit("onFindRegisteredUserWithoutEmail");
            }
         } catch (error) {
            if (error.response && error.response.status === 404) {
               /* 3 caso - Paciente não cadastrado na plataforma. */
               this.$emit("onFindUnregisteredUser");
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSubmit = false;
         }
      },

      async auth(data) {
         this.loadingSubmit = true;

         try {
            const response = await authService.auth(
               data.email,
               data.password,
               data.token
            );

            /* Reseta o store do cpf e email. */
            this.emailStore = null;
            this.cpfStore = null;

            this.$emit("onAuth", response);
         } catch (error) {
            if (error.response && error.response.status === 401) {
               snackBarUtil.showCustomSnackBar({
                  color: "warning",
                  icon: "mdi-alert-circle-outline",
                  title: "CREDENCIAIS INVÁLIDAS!",
                  message: "Seu email ou senha podem estar incorretos!",
               });

               this.clearPassword();
            } else snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.resetRecaptcha();
            this.loadingSubmit = false;
         }
      },

      resetRecaptcha() {
         if (this.$refs.authcarddesktop)
            this.$refs.authcarddesktop.resetRecaptcha();
         if (this.$refs.authcardmobile)
            this.$refs.authcardmobile.resetRecaptcha();
      },

      clearPassword() {
         if (this.$refs.authcarddesktop)
            this.$refs.authcarddesktop.clearPassword();
         if (this.$refs.authcardmobile)
            this.$refs.authcardmobile.clearPassword();
      },

      changeExistingEmail(value) {
         this.existingAccount = value;
      },
   },
};
</script>

<style scoped>
/* Desktop */
.card-border {
   border-radius: 8px !important;
}

.side-right {
   display: flex;
   justify-content: center;
   align-items: center;

   height: 100%;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );

   padding: 10px;
}

/* Mobile */
.background-mobile {
   height: 100vh !important;
   width: 100vw !important;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );
}
</style>