<template>
   <div>
      <router-link
         class="router-link-back-auth"
         to="/autenticacao"
         id="go-to-back-auth"
      >
         <p
            @click="onNavigateToAuth"
            class="caption primary--text label-back-auth"
         >
            <v-icon color="primary" size="15">mdi-arrow-left</v-icon>
            Voltar para tela de autenticação
         </p>
      </router-link>

      <p class="font-weight-bold font-sora main-title mt-5">Cadastramento</p>
      <p class="text--secondary body-2 mt-1">
         Seu <strong>CPF</strong> não foi encontrado, informe os dados abaixo
         para realizar o seu <strong>cadastro</strong>.
      </p>

      <v-stepper v-model="stepper" class="mt-7" vertical>
         <v-stepper-step
            :complete="stepper > 1"
            class="body-2"
            color="primary"
            step="1"
         >
            Autenticação
         </v-stepper-step>

         <v-stepper-content step="1">
            <v-form ref="formAuth">
               <v-row class="mt-0">
                  <v-col cols="12" md="12">
                     <v-text-field
                        v-model="formPatient.email"
                        :rules="[
                           (value) => !!value || 'Email obrigatório.',
                           (value) => emailRules(value) || 'Email inválido.',
                        ]"
                        type="email"
                        label="Email*"
                        placeholder="Digite seu email"
                        id="email-patient-form-input"
                        autofocus
                     ></v-text-field>
                  </v-col>
               </v-row>

               <v-row class="mt-3">
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="formPatient.password"
                        :type="showPassword ? 'text' : 'password'"
                        :rules="[
                           (value) => !!value || 'Senha obrigatória.',
                           (value) =>
                              passwordValidation(value) ||
                              'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                        ]"
                        id="password-patient-form-input"
                        label="Senha* (mínimo 6 caracteres)"
                        placeholder="Digite sua senha"
                     >
                        <template v-slot:append>
                           <v-icon
                              class="margin-top-eye-icon"
                              @click="showPassword = !showPassword"
                              id="visualization-password"
                              left
                              small
                              >{{
                                 showPassword
                                    ? "mdi-eye-outline"
                                    : "mdi-eye-off-outline"
                              }}</v-icon
                           >
                        </template>
                     </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="formPatient.confirm_password"
                        :type="showConfirmPassword ? 'text' : 'password'"
                        :rules="[
                           (value) => !!value || 'Confirmação obrigatória.',
                           (value) =>
                              formPatient.password === value ||
                              'Confirmação inválida',
                        ]"
                        id="confirm-password-patient-form-input"
                        label="Confirmar senha*"
                        placeholder="Digite a confirmação de senha"
                     >
                        <template v-slot:append>
                           <v-icon
                              class="margin-top-eye-icon"
                              @click="
                                 showConfirmPassword = !showConfirmPassword
                              "
                              id="visualization-confirm-password"
                              left
                              small
                              >{{
                                 showConfirmPassword
                                    ? "mdi-eye-outline"
                                    : "mdi-eye-off-outline"
                              }}
                           </v-icon>
                        </template>
                     </v-text-field>
                  </v-col>
               </v-row>

               <v-row class="mt-5">
                  <v-col cols="12" md="12">
                     <v-alert
                        class="caption ma-0"
                        color="warning"
                        border="left"
                        dense
                        text
                     >
                        <div class="d-flex align-center">
                           <v-icon color="warning" left small
                              >mdi-lock-alert-outline</v-icon
                           >
                           <p class="caption">
                              A senha deve ter no mínimo 6 caracteres, 1
                              caracter especial e 1 letra maiúscula.
                           </p>
                        </div>
                     </v-alert>
                  </v-col>
               </v-row>

               <div class="mt-6 mb-1">
                  <RegistrationStepperControl
                     @onClickNextStep="authNextStep"
                     hidePreviousStep
                  />
               </div>
            </v-form>
         </v-stepper-content>

         <v-stepper-step
            :complete="stepper > 2"
            complete-icon="mdi-check"
            class="body-2"
            color="primary"
            step="2"
         >
            Dados pessoais
         </v-stepper-step>

         <v-stepper-content step="2">
            <v-form ref="formPersonalData">
               <v-row class="mt-0">
                  <v-col cols="12" md="3">
                     <v-text-field
                        v-model="formPatient.cpf"
                        v-maska="'###.###.###-##'"
                        :rules="[
                           (value) => !!value || 'CPF obrigatório.',
                           (value) => cpfValidation(value) || 'CPF Inválido.',
                        ]"
                        type="tel"
                        id="cpf-patient-form-input"
                        label="CPF*"
                        placeholder="000.000.000-00"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="9">
                     <v-text-field
                        v-model="formPatient.name"
                        :rules="[(value) => !!value || 'Nome obrigatório.']"
                        id="name-patient-form-input"
                        label="Nome*"
                        placeholder="Digite seu nome"
                     ></v-text-field>
                  </v-col>
               </v-row>

               <v-row class="mt-3">
                  <v-col cols="12" md="3">
                     <v-select
                        v-model="formPatient.gender"
                        :items="genderItems"
                        :rules="[(value) => !!value || 'Sexo obrigatório.']"
                        label="Sexo*"
                        placeholder="Selecione seu sexo"
                        id="gender-patient-form-input"
                     >
                        <template v-slot:item="{ item, on, attrs }">
                           <v-list-item v-on="on" v-bind="attrs">
                              <v-list-item-content
                                 :id="`item-${attrs.id.split('-')[3]}`"
                              >
                                 <v-list-item-title>
                                    {{ item.text }}
                                 </v-list-item-title>
                              </v-list-item-content>
                           </v-list-item>
                        </template>
                     </v-select>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="formPatient.address.birth_city"
                        :rules="[
                           (value) =>
                              !!value || 'Cidade de nascimento obrigatória.',
                        ]"
                        label="Cidade de nascimento*"
                        placeholder="Digite sua cidade de nascimento"
                        id="birth_city-patient-form-input"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                     <v-text-field
                        v-model="formPatient.phone"
                        v-maska="['(##) ####-####', '(##) #####-####']"
                        :rules="[
                           (value) => !!value || 'Telefone obrigatório.',
                           (value) =>
                              (value && value.length >= 14) ||
                              'Telefone inválido.',
                        ]"
                        type="tel"
                        label="Telefone*"
                        placeholder="(00) 00000-0000"
                        id="phone-patient-form-input"
                     ></v-text-field>
                  </v-col>
               </v-row>

               <v-row class="mt-3">
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="formPatient.birth_date"
                        v-maska="'##/##/####'"
                        :rules="[
                           (value) =>
                              !!value || 'Data de nascimento obrigatória.',
                           (value) => dateValidation(value) || 'Data inválida.',
                        ]"
                        type="tel"
                        label="Data de nascimento*"
                        placeholder="00/00/0000"
                        id="birth_date-patient-form-input"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="formPatient.sus_card"
                        v-maska="'###############'"
                        :rules="[
                           (value) =>
                              !value
                                 ? true
                                 : (value && value.length >= 15) ||
                                   'Cartão do SUS inválido.',
                        ]"
                        type="tel"
                        label="Cartão do SUS (Opcional)"
                        placeholder="000000000000000"
                        id="sus_card-patient-form-input"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="formPatient.rg"
                        v-maska="'##########'"
                        type="tel"
                        label="RG (Opcional)"
                        placeholder="0000-000"
                        id="rg-patient-form-input"
                     ></v-text-field>
                  </v-col>
               </v-row>

               <div class="mt-6 mb-1">
                  <RegistrationStepperControl
                     @onClickPreviousStep="previousStep"
                     @onClickNextStep="personalDataNextStep"
                  />
               </div>
            </v-form>
         </v-stepper-content>

         <v-stepper-step
            :complete="stepper > 3"
            class="body-2"
            color="primary"
            step="3"
         >
            Endereço
         </v-stepper-step>

         <v-stepper-content step="3">
            <v-form ref="formAddress">
               <v-row class="mt-0">
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="formPatient.address.zip_code"
                        v-maska="'########'"
                        :rules="[
                           (value) => !!value || 'CEP obrigatório.',
                           (value) =>
                              (value && value.length >= 8) || 'CEP inválido.',
                           (value) =>
                              cepParaibaOnlyValidation(value) ||
                              'O CEP informado não é da Paraíba.',
                        ]"
                        :loading="loadingSearchZipCode"
                        type="tel"
                        id="zip_code-patient-form-input"
                        label="CEP*"
                        placeholder="00000000"
                        maxlength="8"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="formPatient.address.street"
                        :rules="[(value) => !!value || 'Rua obrigatória.']"
                        label="Rua*"
                        placeholder="Digite o nome da rua"
                        id="street-patient-form-input"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2">
                     <v-text-field
                        v-model="formPatient.address.number"
                        :rules="[(value) => !!value || 'Número obrigatório.']"
                        label="Número*"
                        placeholder="Digite o número"
                        id="number-patient-form-input"
                     ></v-text-field>
                  </v-col>
               </v-row>

               <v-row class="mt-3">
                  <v-col cols="12" md="4">
                     <v-text-field
                        v-model="formPatient.address.district"
                        :rules="[(value) => !!value || 'Bairro obrigatório.']"
                        label="Bairro*"
                        placeholder="Digite o nome do bairro"
                        id="district-patient-form-input"
                     ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="6">
                     <v-text-field
                        v-model="formPatient.address.city"
                        :rules="[(value) => !!value || 'Cidade obrigatória.']"
                        label="Cidade*"
                        placeholder="Digite o nome da cidade"
                        id="city-patient-form-input"
                     ></v-text-field>
                  </v-col>

                  <v-col cols="12" md="2">
                     <v-select
                        v-model="formPatient.address.state"
                        :items="['PB']"
                        :rules="[(value) => !!value || 'Estado obrigatório.']"
                        menu-props="auto"
                        id="state-patient-form-input"
                        label="Estado*"
                        placeholder="Selecione o estado"
                        return-object
                     >
                     </v-select>
                  </v-col>
               </v-row>

               <v-row class="mt-3">
                  <v-col cols="12">
                     <v-text-field
                        v-model="formPatient.address.complement"
                        label="Complemento (Opcional)"
                        placeholder="Digite o complemento"
                        id="complement-patient-form-input"
                     ></v-text-field>
                  </v-col>
               </v-row>

               <div v-if="nodeEnvComputed" class="mt-7">
                  <vue-recaptcha
                     ref="recaptcha"
                     id="recaptcha"
                     @verify="recaptchaVerify"
                     @render="recaptchaRender"
                     @expired="recaptchaExpired"
                     :sitekey="siteKey"
                  ></vue-recaptcha>

                  <p
                     v-if="recaptchaValidation"
                     class="ma-0 caption error--text"
                  >
                     Preencha a confirmação para continuar.
                  </p>
               </div>

               <div class="my-6">
                  <RegistrationStepperControl
                     @onClickPreviousStep="previousStep"
                     @onClickNextStep="addressNextStep"
                     :loading="loading"
                     nextStepText="Finalizar"
                     nextStepIcon="mdi-check"
                  />
               </div>
            </v-form>
         </v-stepper-content>
      </v-stepper>
   </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import RegistrationStepperControl from "./RegistrationStepperControl";
import validationUtil from "utils/validationUtil";
import snackBarUtil from "utils/snackBarUtil";
import zipCodeService from "services/zipCodeService";
import { NODE_ENV } from "utils/defaultUtil";
import util from "utils/util";
import formatUtil from "utils/formatUtil";

export default {
   name: "RegistrationCardBase",

   components: { VueRecaptcha, RegistrationStepperControl },

   props: {
      loading: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         formPatient: {
            address: {},
         },
         stepper: 1,

         genderItems: [
            {
               text: "Masculino",
               value: "male",
            },
            {
               text: "Feminino",
               value: "female",
            },
         ],

         loadingSearchZipCode: false,
         showPassword: false,
         showConfirmPassword: false,

         recaptchaToken: null,
         recaptchaValidation: false,
         recaptchaRendered: false,
      };
   },

   watch: {
      "formPatient.address.zip_code": async function handler(newZip, oldZip) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  if (response.data && response.data.uf === "PB") {
                     this.formPatient.address.street = response.data.logradouro;
                     this.formPatient.address.district = response.data.bairro;
                     this.formPatient.address.city = response.data.localidade;
                     this.formPatient.address.state = response.data.uf;
                     if (response.data.complemento)
                        this.formPatient.address.complement =
                           response.data.complemento;
                  }
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },
   },

   computed: {
      siteKey() {
         return (
            process.env.VUE_APP_RECAPTCHA_SITE_KEY ||
            "VUE_APP_RECAPTCHA_SITE_KEY"
         );
      },

      nodeEnvComputed() {
         return NODE_ENV === "production" ? true : false;
      },

      emailStore: {
         get() {
            return this.$store.getters["patient/getEmail"];
         },

         set(value) {
            this.$store.commit("patient/setEmail", value);
         },
      },

      cpfStore: {
         get() {
            return this.$store.getters["patient/getCpf"];
         },

         set(value) {
            this.$store.commit("patient/setCpf", value);
         },
      },
   },

   methods: {
      ...validationUtil,

      nextStep() {
         this.stepper += 1;
      },

      previousStep() {
         this.stepper -= 1;
      },

      cepParaibaOnlyValidation(cep) {
         if (cep && cep.length >= 8)
            return parseInt(cep) >= 58000000 && parseInt(cep) <= 58999999;

         return false;
      },

      emailRules(value) {
         if (value) return validationUtil.emailValidation(value);
      },

      executePersonalDataValidation() {
         if (this.$refs.formPersonalData)
            return this.$refs.formPersonalData.validate();
         else return false;
      },

      executeAddressValidation() {
         if (this.$refs.formAddress) return this.$refs.formAddress.validate();
         else return false;
      },

      executeAuthValidation() {
         if (this.$refs.formAuth) return this.$refs.formAuth.validate();
         else return false;
      },

      resetValidation() {
         if (this.$refs.formPersonalData)
            this.$refs.formPersonalData.resetValidation();
         if (this.$refs.formAddress) this.$refs.formAddress.resetValidation();
         if (this.$refs.formAuth) this.$refs.formAuth.resetValidation();
      },

      recaptchaRender() {
         this.recaptchaRendered = true;
      },

      recaptchaVerify(value) {
         value ? (this.recaptchaValidation = false) : null;
         this.recaptchaToken = value;
      },

      recaptchaExpired() {
         this.recaptchaVerify(null);
      },

      validateRecaptcha() {
         this.recaptchaValidation = !this.recaptchaToken;
         return !this.recaptchaValidation;
      },

      resetRecaptcha() {
         if (this.$refs.recaptcha) {
            this.$refs.recaptcha.reset();
            this.recaptchaToken = null;
            this.recaptchaValidation = false;
         }
      },

      personalDataNextStep() {
         if (this.executePersonalDataValidation()) this.nextStep();
      },

      addressNextStep() {
         let recaptchaValidation = true;
         if (this.nodeEnvComputed)
            recaptchaValidation = this.validateRecaptcha();

         if (this.executeAddressValidation() && recaptchaValidation)
            this.registrationFinish();
      },

      authNextStep() {
         if (this.executeAuthValidation()) this.nextStep();
      },

      registrationFinish() {
         if (
            this.executePersonalDataValidation() &&
            this.executeAddressValidation() &&
            this.executeAuthValidation()
         )
            this.$emit("onRegistrationFinish", {
               ...this.formatObjectToSend(this.formPatient),
               token: this.recaptchaToken,
            });
      },

      formatObjectToSend(patient, removeEmptyStrings) {
         let patientTemp = util.removeBlankAttributes(patient, {
            emptyStrings: removeEmptyStrings,
         });

         if (patientTemp.birth_date)
            patientTemp.birth_date = formatUtil.formatDate(
               patientTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (patientTemp.cpf)
            patientTemp.cpf = patientTemp.cpf.replace(/[^0-9]/g, "");

         if (patientTemp.phone)
            patientTemp.phone = patientTemp.phone.replace(/[^0-9]/g, "");

         return patientTemp;
      },

      onNavigateToAuth() {
         /* Reseta o store do email. */
         this.emailStore = null;
         this.cpfStore = null;
      },
   },

   created() {
      this.stepper = 1;

      if (this.cpfStore && validationUtil.cpfValidation(this.cpfStore))
         this.formPatient.cpf = this.cpfStore;
   },
};
</script>

<style scoped>
.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

.label-back-auth {
   cursor: pointer !important;
}

.router-link-back-auth {
   text-decoration: none;
}

::v-deep .v-stepper {
   box-shadow: none !important;
   padding: 0px !important;
   padding-bottom: 10px !important;
}

::v-deep .v-stepper__step {
   padding-left: 0px !important;
   padding-right: 0px !important;
}

::v-deep .v-stepper__content {
   margin-left: 11px !important;
   padding-top: 0px !important;
   padding-bottom: 10px !important;
}

.margin-top-eye-icon {
   margin-top: 3px;
}

#recaptcha {
   max-width: 1px !important;
   transform: scale(1);
   -webkit-transform: scale(1);
   transform-origin: 0 0;
   -webkit-transform-origin: 0 0;
}

@media (max-width: 400px) {
   #recaptcha {
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      transform-origin: 100 5;
      -webkit-transform-origin: 100 5;
      height: 58px !important;
   }
}
</style>