<template>
   <div>
      <div class="mb-7">
         <p class="font-weight-bold font-sora main-title">Alterar senha</p>

         <p class="text--secondary body-2 mt-1">
            Digite sua senha <strong>atual</strong>, em seguinda preencha a
            <strong>nova</strong> senha e confirme a mesma.
         </p>
      </div>

      <v-form ref="form">
         <v-row>
            <v-col cols="12">
               <v-text-field
                  v-model="auth.old_password"
                  :rules="[(value) => !!value || 'Senha atual obrigatória.']"
                  :type="showOldPassword ? 'text' : 'password'"
                  label="Senha atual*"
                  placeholder="Digite sua senha atual"
               >
                  <template v-slot:append>
                     <v-icon
                        @click="showOldPassword = !showOldPassword"
                        class="margin-top-eye-icon"
                        left
                        small
                        >{{
                           showOldPassword
                              ? "mdi-eye-outline"
                              : "mdi-eye-off-outline"
                        }}</v-icon
                     >
                  </template>
               </v-text-field>
            </v-col>

            <v-col cols="12">
               <v-text-field
                  v-model="auth.new_password"
                  :rules="[
                     (value) => !!value || 'Nova senha obrigatória.',
                     (value) =>
                        passwordValidation(value) ||
                        'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
                  ]"
                  :type="showNewPassword ? 'text' : 'password'"
                  label="Nova senha*"
                  placeholder="Digite sua nova senha"
               >
                  <template v-slot:append>
                     <v-icon
                        @click="showNewPassword = !showNewPassword"
                        class="margin-top-eye-icon"
                        left
                        small
                        >{{
                           showNewPassword
                              ? "mdi-eye-outline"
                              : "mdi-eye-off-outline"
                        }}</v-icon
                     >
                  </template>
               </v-text-field>
            </v-col>

            <v-col cols="12">
               <v-text-field
                  v-model="auth.confirm_new_password"
                  :rules="[
                     (value) => !!value || 'Confirmação de senha obrigatória.',
                     (value) =>
                        value === auth.new_password ||
                        'Confirmação da nova senha e a nova senha são diferentes.',
                  ]"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  label="Confirmação de senha*"
                  placeholder="Digite a nova senha novamente"
               >
                  <template v-slot:append>
                     <v-icon
                        @click="showConfirmPassword = !showConfirmPassword"
                        class="margin-top-eye-icon"
                        left
                        small
                        >{{
                           showConfirmPassword
                              ? "mdi-eye-outline"
                              : "mdi-eye-off-outline"
                        }}</v-icon
                     >
                  </template>
               </v-text-field>
            </v-col>
         </v-row>
      </v-form>

      <!-- Mobile -->
      <div v-if="isSmallerThan(959)" class="mt-8">
         <v-btn @click="save" :loading="loadingSave" color="primary" block>
            Salvar
            <v-icon right>mdi-check</v-icon>
         </v-btn>

         <v-btn @click="back" color="primary" class="mt-3" block text>
            Voltar
         </v-btn>
      </div>
      <!-- Desktop -->
      <div v-else class="d-flex justify-end align-center mt-8 mx-n1 mb-n1">
         <v-btn @click="back" class="ma-1" color="primary" text> Voltar </v-btn>

         <v-btn
            @click="save"
            :loading="loadingSave"
            class="ma-1"
            color="primary"
         >
            Salvar
            <v-icon right>mdi-check</v-icon>
         </v-btn>
      </div>
   </div>
</template>

<script>
import validationUtil from "utils/validationUtil";
import responsiveUtil from "utils/responsiveUtil";
import util from "utils/util";
import authService from "services/authService";
import axiosService from "services/axiosService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "MyAccountChangePasswordForm",

   props: {
      myAccount: {
         type: Object,
      },
   },

   data: function () {
      return {
         auth: {},
         loadingSave: false,

         showOldPassword: false,
         showNewPassword: false,
         showConfirmPassword: false,
      };
   },

   methods: {
      ...validationUtil,
      ...responsiveUtil,

      formatObjectToSend(auth) {
         let authTemp = util.removeBlankAttributes(auth);

         if (this.myAccount && this.myAccount.email)
            authTemp.email = this.myAccount.email;

         return authTemp;
      },

      back() {
         this.$emit("onClickBack");
      },

      save() {
         this.authChangePassword(this.auth);
      },

      resetValidation() {
         this.$refs.form.resetValidation();
      },

      executeValidation() {
         return this.$refs.form.validate();
      },

      async authChangePassword(changePassword) {
         if (!this.executeValidation()) return;

         this.loadingSave = true;

         try {
            await authService.authChangePassword(
               this.formatObjectToSend(changePassword),
               axiosService.defaults.headers.common["Authorization"]
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Sua senha foi atualizada!",
            });

            this.auth = {};
            this.resetValidation();

            this.$emit("onUpdatedPassword");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSave = false;
         }
      },
   },
};
</script>

<style scoped>
.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

.margin-top-eye-icon {
   margin-top: 3px;
}
</style>