<template>
   <!-- Desktop -->
   <div v-if="!isSmallerThan(959)">
      <v-card class="box-shadow-data-table" :loading="loading">
         <p
            class="
               font-weight-bold font-sora
               main-title
               standard-padding
               primary--text
               py-4
            "
         >
            <v-icon class="mb-1" color="primary" size="26" left
               >mdi-account</v-icon
            >
            MEUS DADOS
         </p>

         <v-divider></v-divider>

         <div class="standard-padding">
            <p
               v-if="loading"
               class="
                  text-center
                  font-sora
                  loading-account-label
                  standard-padding
                  primary--text
               "
            >
               Carregando seus <br />
               dados...
            </p>

            <div v-else>
               <MyAccountFormBase
                  :myAccount="formData"
                  :editMode="getMode() !== 'read'"
                  ref="myaccountformbase"
               />

               <div class="mt-8">
                  <v-alert
                     v-if="validationErrors.length"
                     class="caption"
                     color="error"
                     border="left"
                     dense
                     text
                  >
                     <div class="d-flex align-center">
                        <v-icon color="error" left small
                           >mdi-alert-outline</v-icon
                        >

                        <p class="caption">
                           <strong>Campos inválidos:</strong>
                           {{ validationErrors.join(", ") }}.
                        </p>
                     </div>
                  </v-alert>

                  <div class="d-flex align-center ma-n1">
                     <div
                        v-if="getMode() === 'save'"
                        class="d-flex justify-end align-center width-100"
                     >
                        <v-btn
                           @click="cancel"
                           color="primary"
                           class="ma-1"
                           text
                        >
                           Cancelar
                        </v-btn>

                        <v-btn
                           @click="save"
                           :loading="loadingSave"
                           class="ma-1"
                           color="primary"
                        >
                           Salvar
                           <v-icon right>mdi-check</v-icon>
                        </v-btn>
                     </div>

                     <div
                        v-else
                        class="
                           d-flex
                           justify-space-between
                           align-center
                           width-100
                        "
                     >
                        <div class="d-flex align-center">
                           <v-btn
                              @click="changePassword"
                              class="ma-1"
                              color="primary"
                              dark
                           >
                              Alterar senha
                              <v-icon right>mdi-lock-outline</v-icon>
                           </v-btn>

                           <v-btn
                              @click="logout"
                              class="ma-1"
                              color="error"
                              dark
                           >
                              Sair da conta
                              <v-icon right>mdi-logout-variant</v-icon>
                           </v-btn>
                        </div>

                        <v-btn @click="edit" class="ma-1" dark>
                           Editar dados
                           <v-icon right>mdi-pencil-outline</v-icon>
                        </v-btn>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </v-card>
   </div>

   <!-- Mobile -->
   <div v-else>
      <div
         v-if="loading"
         class="
            d-flex
            flex-column
            justify-center
            align-center
            standard-padding
            mt-16
         "
      >
         <p
            class="
               text-center
               font-sora
               loading-account-label
               primary--text
               mb-5
            "
         >
            Carregando seus <br />
            dados...
         </p>

         <v-progress-linear
            style="width: 50%"
            color="primary"
            indeterminate
            rounded
            height="4"
         ></v-progress-linear>
      </div>

      <v-card
         v-else
         class="
            box-shadow-bottom-card
            border-bottom-card
            standard-padding
            mt-n7
            pt-6
         "
      >
         <MyAccountFormBase
            :myAccount="formData"
            :editMode="getMode() !== 'read'"
            ref="myaccountformbase"
         />

         <div class="mt-8">
            <v-alert
               v-if="validationErrors.length"
               class="caption"
               color="error"
               border="left"
               dense
               text
            >
               <div class="d-flex align-center">
                  <v-icon color="error" left small>mdi-alert-outline</v-icon>

                  <p class="caption">
                     <strong>Campos inválidos:</strong>
                     {{ validationErrors.join(", ") }}.
                  </p>
               </div>
            </v-alert>

            <div v-if="getMode() === 'save'">
               <v-btn
                  @click="save"
                  :loading="loadingSave"
                  color="primary"
                  block
               >
                  Salvar
                  <v-icon right>mdi-check</v-icon>
               </v-btn>

               <v-btn @click="cancel" color="primary" class="mt-3" block text>
                  Cancelar
               </v-btn>
            </div>

            <div v-else>
               <v-btn @click="edit" dark block>
                  Editar dados
                  <v-icon right>mdi-pencil-outline</v-icon>
               </v-btn>

               <v-btn
                  @click="changePassword"
                  class="mt-3"
                  color="primary"
                  dark
                  block
               >
                  Alterar senha
                  <v-icon right>mdi-lock-outline</v-icon>
               </v-btn>

               <v-btn @click="logout" class="mt-3" color="error" dark block>
                  Sair da conta
                  <v-icon right>mdi-logout-variant</v-icon>
               </v-btn>
            </div>
         </div>
      </v-card>
   </div>
</template>

<script>
import MyAccountFormBase from "./MyAccountFormBase";
import formatUtil from "../../utils/formatUtil";
import util from "../../utils/util";
import responsiveUtil from "utils/responsiveUtil";
import snackBarUtil from "utils/snackBarUtil";
import patientService from "services/patientService";

export default {
   name: "MyAccountForm",

   components: {
      MyAccountFormBase,
   },

   props: {
      myAccount: {
         type: Object,
      },

      loading: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         mode: "read",
         formData: {},
         loadingSave: false,
         validationErrors: [],
      };
   },

   watch: {
      myAccount: {
         immediate: true,
         handler(myAccount) {
            this.formData = this.formatObjectToView(myAccount);

            this.resetValidation();
         },
      },
   },

   methods: {
      ...responsiveUtil,

      formatObjectToView(myAccount) {
         let myAccountTemp = util.mergeObject(
            {
               address: {},
            },
            myAccount
         );

         if (myAccountTemp.birth_date)
            myAccountTemp.birth_date = formatUtil.formatDate(
               myAccountTemp.birth_date
            );

         if (myAccountTemp.cpf)
            myAccountTemp.cpf = formatUtil.formatCPF(myAccountTemp.cpf);

         if (myAccountTemp.phone)
            myAccountTemp.phone = formatUtil.formatPhone(myAccountTemp.phone);

         return myAccountTemp;
      },

      formatObjectToSend(myAccount, removeEmptyStrings) {
         let myAccountTemp = util.removeBlankAttributes(myAccount, {
            emptyStrings: removeEmptyStrings,
         });

         if (myAccountTemp.birth_date)
            myAccountTemp.birth_date = formatUtil.formatDate(
               myAccountTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (myAccountTemp.cpf)
            myAccountTemp.cpf = myAccountTemp.cpf.replace(/[^0-9]/g, "");

         if (myAccountTemp.phone)
            myAccountTemp.phone = myAccountTemp.phone.replace(/[^0-9]/g, "");

         return myAccountTemp;
      },

      resetValidation() {
         if (
            this.$refs.myaccountformbase &&
            this.$refs.myaccountformbase.$refs.form
         )
            this.$refs.myaccountformbase.$refs.form.resetValidation();

         this.validationErrors = [];
      },

      executeValidation() {
         if (
            this.$refs.myaccountformbase &&
            this.$refs.myaccountformbase.$refs.form
         ) {
            this.validationErrors =
               this.$refs.myaccountformbase.$refs.form.inputs
                  .filter((item) => item.hasError && item.label)
                  .map((item) => item.label.replace(/\*/g, ""));
            return this.$refs.myaccountformbase.$refs.form.validate();
         } else return false;
      },

      getMode() {
         return this.mode;
      },

      setMode(mode) {
         this.mode = mode;
      },

      edit() {
         this.setMode("save");
      },

      logout() {
         this.$emit("onLogout");
      },

      changePassword() {
         this.$emit("onChangePassword");
      },

      cancel() {
         this.setMode("read");
         this.formData = this.formatObjectToView(this.myAccount);
         this.resetValidation();
      },

      async save() {
         this.updateMyAccount(this.myAccount.id, this.formData);
      },

      async updateMyAccount(myAccountId, myAccount) {
         if (!this.executeValidation() || !myAccountId || !myAccount) return;

         this.loadingSave = true;

         try {
            await patientService.updatePatient(
               myAccountId,
               this.formatObjectToSend(myAccount, false)
            );

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Seus dados foram atualizados!",
            });

            this.setMode("read");
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingSave = false;
         }
      },
   },
};
</script>

<style scoped>
.loading-account-label {
   font-weight: 400 !important;
   font-size: 0.875rem !important;
   letter-spacing: 0.0178571429em !important;
}

.main-title {
   font-size: 1.1rem !important;
   letter-spacing: 0.0125em;
}
</style>