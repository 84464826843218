<template>
   <div>
      <v-dialog
         v-model="dialogChangePasswordForm"
         :fullscreen="isSmallerThanComputed"
         :hide-overlay="isSmallerThanComputed"
         :persistent="isSmallerThanComputed"
         :transition="
            isSmallerThanComputed
               ? 'dialog-bottom-transition'
               : 'slide-y-transition'
         "
         max-width="500"
      >
         <v-card tile id="scrollTop">
            <Close
               @close="closeChangePasswordForm"
               :desktop="!isSmallerThanComputed"
            />

            <MyAccountChangePasswordForm
               @onUpdatedPassword="closeChangePasswordForm"
               @onClickBack="closeChangePasswordForm"
               :myAccount="myAccount"
               class="standard-padding padding-top-mobile"
            />
         </v-card>
      </v-dialog>

      <MyAccountForm
         @onLogout="logout"
         @onChangePassword="openChangePasswordForm"
         :myAccount="myAccount"
         :loading="loadingMyAccount"
      />
   </div>
</template>

<script>
import MyAccountForm from "../../components/myAccount/MyAccountForm";
import Close from "components/base/Close";
import MyAccountChangePasswordForm from "components/myAccount/MyAccountChangePasswordForm";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "../../utils/snackBarUtil";
import patientService from "services/patientService";
import userService from "services/userService";

export default {
   name: "MyAccount",

   components: {
      MyAccountForm,
      Close,
      MyAccountChangePasswordForm,
   },

   data: function () {
      return {
         loadingMyAccount: false,
         myAccount: {},

         dialogChangePasswordForm: false,
      };
   },

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      isSmallerThanComputed() {
         return this.isSmallerThan(959);
      },
   },

   methods: {
      ...responsiveUtil,

      openChangePasswordForm() {
         this.dialogChangePasswordForm = true;
      },

      closeChangePasswordForm() {
         this.dialogChangePasswordForm = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },

      async getMyAccount(userId) {
         this.loadingMyAccount = true;

         try {
            let response;

            switch (this.userStore.sub_type) {
               case "patient":
                  response = await patientService.getPatientById(userId);
                  break;
            }

            this.myAccount = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingMyAccount = false;
         }
      },

      logout() {
         userService.removeUserSession();

         if (this.$route.path !== "/autenticacao")
            this.$router.push({ path: "/autenticacao" });
      },
   },

   created() {
      this.getMyAccount(this.userStore.sub);
   },
};
</script>

<style scoped>
@media (max-width: 959px) {
   .padding-top-mobile {
      padding-top: 40px;
   }
}
</style>