<template>
   <div>
      <div class="attatchment-controls standard-padding-tiny">
         <p
            class="
               white--text
               text--center
               attachment-label-overflow
               font-sora
               ml-3
            "
         >
            {{ attachment.content.name }}
         </p>

         <div class="d-flex align-center">
            <v-btn
               @click="
                  download(attachment.content.data, attachment.content.name)
               "
               :loading="loadingDownload"
               dark
               fab
               text
               small
            >
               <v-icon>mdi-download</v-icon>
            </v-btn>

            <v-btn @click="closeOverlay" class="ml-2" dark fab text small>
               <v-icon>mdi-close</v-icon>
            </v-btn>
         </div>
      </div>

      <div class="preview">
         <div v-if="preview">
            <!-- Visualizador de imagem -->
            <v-img
               v-if="attachment.content.type.includes('image')"
               @load="finishLoadingImage"
               :src="preview"
               height="80vh"
               width="100vw"
               contain
            >
               <template v-slot:placeholder>
                  <div v-if="preview && loadingPreview" class="loading-preview">
                     <p
                        class="
                           text-center
                           font-sora
                           loading-requests-label
                           white--text
                        "
                     >
                        Carregando o <br />
                        documento...
                     </p>
                  </div>
               </template>
            </v-img>
         </div>

         <div v-else-if="attachment.content.data && attachment.content.name">
            <p class="text-center font-sora primary--text">
               Nenhuma visualização disponível <br />
               baixe para visualizar!
            </p>

            <p class="text-center caption white--text mt-2">
               (Arquivo muito grande ou com visualizador indisponível)
            </p>

            <v-img
               :src="require('@/assets/images/image-not-preview.png')"
               max-height="95px"
               width="95px"
               class="mt-4 mx-auto"
               contain
            >
            </v-img>
         </div>
      </div>
   </div>
</template>

<script>
import FileSaver from "file-saver";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "AttachmentViewer",

   props: {
      attachment: {
         typeof: Object,
      },
   },

   data() {
      return {
         preview: null,

         loadingDownload: false,
         loadingPreview: false,
      };
   },

   watch: {
      attachment: {
         immediate: true,
         async handler(attachment) {
            this.preview = null;
            this.loadingPreview = false;

            if (
               attachment &&
               attachment.content &&
               attachment.content.size <= 15000000 &&
               attachment.content.data.length < 6000000
            ) {
               if (
                  attachment.content.name.toLowerCase().includes("jpg") ||
                  attachment.content.name.toLowerCase().includes("jpeg") ||
                  attachment.content.name.toLowerCase().includes("png") ||
                  attachment.content.name.toLowerCase().includes("tif") ||
                  attachment.content.name.toLowerCase().includes("bmp")
               ) {
                  this.loadingPreview = true;
                  this.preview = attachment.content.data;
               }
            }
         },
      },
   },

   methods: {
      closeOverlay() {
         this.$emit("onCloseOverlay");
      },

      finishLoadingImage() {
         this.loadingPreview = false;
      },

      download(file, fileName) {
         this.loadingDownload = true;

         new Promise((resolve) => {
            resolve(FileSaver.saveAs(file, fileName));
         })
            .catch(() =>
               snackBarUtil.showCustomSnackBar({
                  color: "error",
                  title: "ERROR AO BAIXAR",
                  icon: "mdi-download-off",
                  message:
                     "Error ao baixar o arquivo, por favor tente novamente.",
               })
            )
            .finally(() => (this.loadingDownload = false));
      },
   },
};
</script>

<style scoped>
.pdf-viewer-content {
   height: 75vh;
   width: 80vw;
   border-radius: 20px;
}

@media (max-height: 900px) {
   .pdf-viewer-content {
      height: 70vh;
      width: 90vw;
      border-radius: 20px;
   }
}

.label-attachment-name {
   text-overflow: ellipsis;
   text-align: center;
   word-break: break-all;
}

.div-button-close {
   position: absolute;
   width: 100%;
   pointer-events: none;
}

.attachment-label-overflow {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
   width: 65%;
}

.attatchment-controls {
   position: absolute;
   top: 0px;
   left: 0px;

   display: flex;
   justify-content: space-between;
   align-items: center;

   width: 100vw;
}

.preview {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100vw;
   height: 100vh;
}

.loading-preview {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 100%;
}
</style>