export const PUBLIC_ROUTES = [
   "/autenticacao",
   "/password-reset",
   "/cadastro",
   "/recuperar-conta",
   "/page404",
];

export const PROTECTED_ROUTES = [
   {
      text: "Minhas solicitações",
      icon: "mdi-file-document-outline",
      link: "/minhas-solicitacoes",
   },
   {
      text: "Meus agendamentos",
      icon: "mdi-calendar-outline",
      link: "/meus-agendamentos",
   },
   {
      text: "Minha conta",
      icon: "mdi-cog-outline",
      link: "/minha-conta",
   },
];

export const API_VERSION = "/v1";

export const NODE_ENV = process.env.VUE_APP_NODE_ENV || "VUE_APP_NODE_ENV";
