<template>
   <div class="d-flex justify-center align-center">
      <RegistrationCard />
   </div>
</template>

<script>
import RegistrationCard from "components/registration/RegistrationCard";

export default {
   name: "Registration",

   components: { RegistrationCard },
};
</script>

<style scoped>
</style>