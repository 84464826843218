import { render, staticRenderFns } from "./MyRequestsListItem.vue?vue&type=template&id=ed07cf60&scoped=true"
import script from "./MyRequestsListItem.vue?vue&type=script&lang=js"
export * from "./MyRequestsListItem.vue?vue&type=script&lang=js"
import style0 from "./MyRequestsListItem.vue?vue&type=style&index=0&id=ed07cf60&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed07cf60",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VChip,VIcon,VSkeletonLoader})
