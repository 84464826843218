<template>
   <v-app id="app">
      <!-- Desktop e Mobile - Janela do termo de compromisso -->
      <AuthorizationTerm v-if="termVisibilityStore" />

      <!-- Desktop e Mobile - Notificações para o usuário -->
      <SnackBar />

      <!-- Desktop - Barra de ações -->
      <AppBar v-if="!isSmallerThanComputed && userStore" />

      <!-- Mobile - Imagem fixa no topo representando a rota -->
      <TopImageFloating v-if="isSmallerThanComputed && userStore" />

      <!-- Desktop e Mobile - Content da aplicação -->
      <Content :bottomNavigationBarActive="!!userStore" />

      <!-- Mobile - Barra de ações na parte inferior junto com o Footer mobile -->
      <BottomNavigationBar v-if="isSmallerThanComputed && userStore" />

      <!-- Desktop - Logo do nutes e desenvolvido por -->
      <Footer v-if="!isSmallerThanComputed" />
   </v-app>
</template>

<script>
import SnackBar from "./components/base/SnackBar";
import AppBar from "./components/base/AppBar";
import Content from "./components/base/Content";
import BottomNavigationBar from "components/base/BottomNavigationBar";
import TopImageFloating from "components/base/TopImageFloating.vue";
import Footer from "components/base/Footer.vue";
import AuthorizationTerm from "components/authorizationTerm/AuthorizationTerm";
import userService from "./services/userService";
import snackBarUtil from "./utils/snackBarUtil";
import { PUBLIC_ROUTES } from "./utils/defaultUtil";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "App",

   components: {
      SnackBar,
      AppBar,
      Content,
      BottomNavigationBar,
      TopImageFloating,
      Footer,
      AuthorizationTerm,
   },

   data: () => ({}),

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      getUserRoutesStore() {
         return this.$store.getters["user/getUserRoutes"];
      },

      isSmallerThanComputed() {
         return this.isSmallerThan(959);
      },

      termVisibilityStore: {
         get() {
            return this.$store.getters["term/getVisibility"];
         },

         set(value) {
            this.$store.commit("term/setVisibility", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      showInformation() {
         const commonStyle =
            "font-weight: bold;font-style: italic;font-style: italic;";
         const style1 = `${commonStyle};font-size: 20px;color: #5c6bc0;`;
         const style2 = `${commonStyle};font-size: 12px;padding-left: 5px;color: #555;`;

         console.log("%cOperaPB\n%cby NUTES/UEPB\nv1.4.0", style1, style2);
      },

      showUnauthorizedSnackBar() {
         snackBarUtil.showCustomSnackBar({
            title: "NÃO AUTORIZADO",
            message:
               "Usuário não possui permissão para acessar o recurso solicitado.",
            icon: "mdi-alert-octagon-outline",
            color: "warning",
         });
      },

      checkLocalStorage() {
         const publicRoutes = PUBLIC_ROUTES;

         if (userService.retrieveUserSession()) {
            if (!this.getUserRoutesStore.includes(this.$route.path)) {
               if (!publicRoutes.includes(this.$route.path))
                  this.showUnauthorizedSnackBar();

               if (this.$route.path !== this.getUserRoutesStore[0])
                  this.$router.push({ path: this.getUserRoutesStore[0] });
            }
         } else {
            if (!publicRoutes.includes(this.$route.path)) {
               if (this.$route.path !== "/autenticacao")
                  this.$router.push({ path: "/autenticacao" });
            }
         }
      },
   },

   created() {
      this.checkLocalStorage();
      this.showInformation();
   },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@200;400;700&display=swap");

.font-sora {
   font-family: "Sora", sans-serif !important;
}

#app {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

::-webkit-scrollbar {
   width: 4px;
   background-color: white;
}

::-webkit-scrollbar-thumb {
   background-color: #c2c2c2;
}

@media (max-width: 959px) {
   ::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
   }

   ::-webkit-scrollbar-thumb {
      background-color: transparent;
   }
}

.height-100 {
   height: 100% !important;
}

.width-100 {
   width: 100% !important;
}

.standard-padding-whopping {
   padding: 36px;
}

p::selection {
   background: var(--v-primary-base);
   color: white;
}

@media (max-width: 959px) {
   .standard-padding-whopping {
      padding: 22px;
   }
}

.standard-padding-x-large {
   padding: 32px;
}

@media (max-width: 959px) {
   .standard-padding-x-large {
      padding: 20px;
   }
}

.standard-padding-large {
   padding: 28px;
}

@media (max-width: 959px) {
   .standard-padding-large {
      padding: 18px;
   }
}

.standard-padding {
   padding: 24px;
}

@media (max-width: 959px) {
   .standard-padding {
      padding: 16px;
   }
}

.standard-padding-small {
   padding: 20px;
}

@media (max-width: 959px) {
   .standard-padding-small {
      padding: 14px;
   }
}

.standard-padding-x-small {
   padding: 16px;
}

@media (max-width: 959px) {
   .standard-padding-x-small {
      padding: 12px;
   }
}

.standard-padding-tiny {
   padding: 12px;
}

@media (max-width: 959px) {
   .standard-padding-tiny {
      padding: 10px;
   }
}

.standard-padding-x-tiny {
   padding: 8px;
}

@media (max-width: 959px) {
   .standard-padding-x-tiny {
      padding: 6px;
   }
}

.standard-padding-data-list {
   padding: 24px;
}

.standard-padding-data-list #header {
   margin-bottom: 16px;
}

@media (max-width: 959px) {
   .standard-padding-data-list {
      padding: 0px;
   }

   .standard-padding-data-list #header {
      padding: 0px 16px;
      padding-top: 16px;
      margin-bottom: -16px;
   }
}

.shadow-floating-primary-button {
   box-shadow: rgba(92, 107, 192, 0.5) 0px 2px 7px 0px;
}

.shadow-floating-primary-light-button {
   box-shadow: rgba(197, 202, 230, 0.5) 0px 2px 7px 0px;
}

.shadow-floating-error-button {
   box-shadow: rgba(255, 82, 82, 0.5) 0px 2px 7px 0px;
}

.shadow-floating-black-button {
   box-shadow: rgba(39, 39, 39, 0.5) 0px 2px 7px 0px;
}

/* Retira as margens padrão da tag <p> do Vuetify. */
p {
   margin: 0px !important;
}

/* Torna o cursor clicável em todas as listagens. */
td {
   cursor: pointer;
}

/* Redefine a altura padrão da tag <v-text-field> do Vuetify. */
.v-input {
   height: 33px;
   padding: 0px !important;
   margin-top: 3px !important;

   font-weight: 400 !important;
   font-size: 0.875rem !important;
   letter-spacing: 0.0178571429em !important;
}

.v-label {
   font-weight: 400 !important;
   font-size: 0.875rem !important;
   letter-spacing: 0.0178571429em !important;
}

/* Redefine alguns estilos visuais da tag <v-pagination> do Vuetify. */
.v-pagination__item,
.v-pagination__navigation {
   box-shadow: none !important;
   font-size: 0.875rem !important;
   margin: 0px !important;
   padding: 0px !important;
   min-width: 35px !important;
   height: 35px !important;
}

.v-pagination__item--active {
   color: var(--v-primary-base) !important;
}

/* Redefine alguns estilos visuais da tag <v-skeleton-loader> do Vuetify. */
.theme--light.v-skeleton-loader .v-skeleton-loader__bone::after {
   background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0)
   ) !important;
}

.v-progress-circular__underlay {
   stroke: rgba(255, 255, 255, 1);
   stroke-width: 3px;
}

.image-sticky-top-position {
   position: sticky !important;
   top: 0px !important;
   height: 18vh;
   width: 100vw;
   margin-top: 10px;
}

.float-bottom-card {
   position: absolute !important;
   bottom: 0px;

   width: 100vw !important;
   max-height: 80vh !important;
}

.border-bottom-card {
   border-top-left-radius: 20px !important;
   border-top-right-radius: 20px !important;
   border-bottom-left-radius: 0px !important;
   border-bottom-right-radius: 0px !important;
}

.box-shadow-bottom-card {
   box-shadow: rgba(17, 17, 26, 0.13) 0px -14px 13px !important;
}

.box-shadow-data-table {
   box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 10px !important;
}

.cursor-default {
   cursor: default !important;
}
</style>
