<template>
   <div
      class="div-powered-by"
      :class="divider ? 'div-divider-powered-by' : null"
   >
      <p class="font-sora label-footer black--text">Desenvolvido por</p>

      <img
         :src="require('@/assets/images/nutes-full.png')"
         class="nutes-logo"
      />

      <span class="div-divider"></span>

      <img
         :src="require('@/assets/images/paraiba-logo.png')"
         class="paraiba-logo"
      />
   </div>
</template>

<script>
export default {
   props: {
      divider: {
         type: Boolean,
         default: true,
      },
   },
};
</script>

<style scoped>
.div-powered-by {
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   padding-top: 2px;
   padding-bottom: 4px;
}

@media (max-width: 959px) {
   .div-divider-powered-by {
      border-top: 1px solid rgb(148, 148, 148, 0.2);
   }
}

.nutes-logo {
   height: 30px;
}

@media (max-width: 959px) {
   .nutes-logo {
      height: 18px;
   }
}

.label-footer {
   font-size: 0.825rem !important;
   letter-spacing: 0.0125em;

   margin-top: 7px !important;
   margin-right: 5px !important;
}

@media (max-width: 959px) {
   .label-footer {
      font-size: 0.65rem !important;
      letter-spacing: 0.0125em;

      margin-top: 4px !important;
      margin-right: 4px !important;
   }
}

.paraiba-logo {
   height: 27px;
}

@media (max-width: 959px) {
   .paraiba-logo {
      height: 17px;
   }
}

.div-divider {
   background-color: rgba(0, 0, 0, 0.2);
   height: 22px;

   width: 1px !important;

   margin-right: 12px;
   margin-left: 12px;
}

@media (max-width: 959px) {
   .div-divider {
      height: 14px;

      margin-right: 9px;
      margin-left: 9px;
   }
}
</style>