export default {
   namespaced: true,

   state: {
      visibility: false,
      color: "",
      title: "",
      message: "",
      icon: null,
      timeout: -1,
      action: {
         text: null,
         method: null,
         loading: null,
         closeOnFinished: true
      }
   },

   getters: {
      getVisibility(state) {
         return state.visibility;
      },

      getColor(state) {
         return state.color;
      },

      getTitle(state) {
         return state.title;
      },

      getMessage(state) {
         return state.message;
      },

      getIcon(state) {
         return state.icon;
      },

      getTimeout(state) {
         return state.timeout;
      },

      getActionText(state) {
         return state.action.text;
      },

      getActionMethod(state) {
         return state.action.method;
      },

      getActionLoading(state) {
         return state.action.loading;
      },

      getActionCloseOnFinished(state) {
         return state.action.closeOnFinished;
      }
   },

   mutations: {
      setVisibility(state, value) {
         state.visibility = value;
      },

      setColor(state, value) {
         state.color = value;
      },

      setTitle(state, value) {
         state.title = value;
      },

      setMessage(state, value) {
         state.message = value;
      },

      setIcon(state, value) {
         state.icon = value;
      },

      setTimeout(state, value) {
         state.timeout = value;
      },

      setActionText(state, value) {
         state.action.text = value;
      },

      setActionMethod(state, value) {
         state.action.method = value;
      },

      setActionLoading(state, value) {
         state.action.loading = value;
      },

      setActionCloseOnFinished(state, value) {
         state.action.closeOnFinished = value === undefined ? true : value;
      }
   },

   actions: {
      customSnackbar(context, configuration) {
         if (!(typeof configuration === "boolean")) {
            context.commit("setColor", configuration.color);
            context.commit("setTitle", configuration.title);
            context.commit("setMessage", configuration.message);
            context.commit("setIcon", configuration.icon);

            if (configuration.action) {
               context.commit("setActionText", configuration.action.text);
               context.commit("setActionMethod", configuration.action.method);
               context.commit("setTimeout", -1);
               context.commit(
                  "setActionCloseOnFinished",
                  configuration.action.closeOnFinished
               );
            } else {
               context.commit("setActionText", null);
               context.commit("setActionMethod", null);
               context.commit("setActionLoading", false);
               context.commit("setTimeout", configuration.timeout || 4000);
            }
         }
      }
   }
};
