<template>
   <v-card
      @click="view(request)"
      class="standard-padding-small item-shadow ma-3"
   >
      <span v-if="unreadMessages > 0" class="notification-bell">
         <p class="font-weight-bold white--text caption">
            {{ request.unreadMessages }}
         </p>
      </span>

      <span
         v-else-if="request.status === 'answered' && !request.is_closed"
         class="notification-schedule-bell"
      >
         <v-icon size="14" dark>mdi-check</v-icon>
      </span>

      <div>
         <p class="body-2">
            <v-icon class="mr-1" size="20">mdi-text-box-search-outline</v-icon>
            {{ request.code }}
         </p>

         <div
            v-if="request.was_created_by_patient"
            class="d-flex align-center mt-1"
         >
            <v-icon class="mr-2" size="20">mdi-map-marker-outline</v-icon>

            <p
               v-if="request.municipality.name"
               class="body-2 truncate-ellipsis"
            >
               {{ request.municipality.name }}
            </p>

            <v-skeleton-loader
               v-else
               class="rounded-pill"
               type="image"
               width="70%"
               height="12px"
            ></v-skeleton-loader>
         </div>
         <div v-else class="d-flex align-center mt-1">
            <v-icon class="mr-2" size="20">mdi-home-city-outline</v-icon>

            <p
               v-if="request.source_health_entity.name"
               class="body-2 truncate-ellipsis"
            >
               {{ request.source_health_entity.name }}
            </p>

            <v-skeleton-loader
               v-else
               class="rounded-pill"
               type="image"
               width="70%"
               height="12px"
            ></v-skeleton-loader>
         </div>

         <div class="d-flex align-center mt-1">
            <v-icon class="mr-2" size="19">mdi-clock-outline</v-icon>

            <v-chip
               v-if="!request.is_archived"
               class="chip-status"
               :color="generateState(request.status).color"
               x-small
            >
               {{ generateState(request.status).text }}
            </v-chip>

            <v-chip v-else class="chip-status" color="warning" x-small>
               Arquivada
            </v-chip>
         </div>

         <p class="created-at-label mt-1">
            Cadastrada por
            <strong>{{
               request.was_created_by_patient ? "você" : "operador"
            }}</strong>
            em
            {{ formatDate(request.created_at, "DD/MM/YYYY") }}.
         </p>
      </div>
   </v-card>
</template>

<script>
import formatUtil from "utils/formatUtil";
import util from "utils/util";
import healthEntityService from "services/healthEntityService";
import snackBarUtil from "utils/snackBarUtil";
import messageService from "services/messageService";

export default {
   name: "MyRequestsListItem",

   props: {
      request: {
         type: Object,
      },
   },

   data: function () {
      return {
         unreadMessages: 0,
      };
   },

   watch: {
      request: {
         immediate: true,
         handler(request) {
            if (request && request.id)
               this.getRequestMessageInfoById(request.id, "patient");
         },
      },
   },

   methods: {
      ...formatUtil,
      ...util,

      view(request) {
         this.$emit("onClickViewRequest", request);
      },

      async getRequestMessageInfoById(requestId, userType) {
         try {
            const response = await messageService.getRequestMessageInfoById(
               requestId,
               userType
            );

            this.unreadMessages = response.data.total_unread_messages;
            if (this.unreadMessages > 0)
               this.$emit("onHaveNotifications", this.unreadMessages);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>
.item-shadow {
   box-shadow: rgba(14, 30, 37, 0.1) 0px 2px 4px 0px,
      rgba(14, 30, 37, 0.2) 0px 2px 16px 0px !important;
}

.created-at-label {
   font-weight: 400 !important;
   font-size: 0.7rem !important;
   letter-spacing: 0.0178571429em !important;
   color: #939393;
}

.chip-status {
   border-radius: 20px !important;
   padding-left: 8px;
   padding-right: 8px;
   height: 18px;
}

.truncate-ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.notification-bell {
   position: absolute;
   top: -5px;
   right: -5px;

   height: 20px;
   width: 20px;
   background-color: var(--v-warning-base);
   border-radius: 50% !important;

   box-shadow: var(--v-warning-base) 0px 0px 3px;

   display: flex;
   justify-content: center;
   align-items: center;
}

.notification-schedule-bell {
   position: absolute;
   top: -5px;
   right: -5px;

   height: 20px;
   width: 20px;
   background-color: var(--v-warning-base);
   border-radius: 50% !important;

   box-shadow: var(--v-warning-base) 0px 0px 3px;

   display: flex;
   justify-content: center;
   align-items: center;
}
</style>