import axios from "axios";
import { API_VERSION } from "../utils/defaultUtil";
import router from "../router";
import userService from "../services/userService";

const axiosInstance = axios.create({
   baseURL: `${process.env.VUE_APP_API_URL ||
      "http://localhost:3000"}${API_VERSION}`
});

const success = res => res;

const error = error => {
   if (
      error.response.status === 401 &&
      error.response.data.message === "UNAUTHORIZED"
   ) {
      userService.removeUserSession();
      if (router.currentRoute.path !== "/autenticacao")
         router.push({ path: "/autenticacao" });
   }

   return Promise.reject(error);
};

axiosInstance.interceptors.response.use(success, error);

export default axiosInstance;
