<template>
   <v-card v-if="!isSmallerThan(959)" width="800px">
      <div class="topbar">
         <v-img
            :src="require('@/assets/images/registration-image-circle.png')"
            height="230px"
            class="mx-auto"
            contain
         >
         </v-img>
      </div>

      <RegistrationCardBase
         @onRegistrationFinish="registration"
         :loading="loadingRegistration"
         class="standard-padding-x-large"
         ref="registrationcarddesktop"
      />
   </v-card>

   <div v-else class="background-mobile">
      <v-img
         :src="require('@/assets/images/registration-image-circle.png')"
         class="image-sticky-top-position"
         contain
      >
      </v-img>

      <v-card
         class="
            float-bottom-card
            border-bottom-card
            box-shadow-bottom-card
            standard-padding-whopping
            pb-8
         "
      >
         <RegistrationCardBase
            @onRegistrationFinish="registration"
            :loading="loadingRegistration"
            ref="registrationcardmobile"
         />
      </v-card>
   </div>
</template>

<script>
import RegistrationCardBase from "./RegistrationCardBase";
import responsiveUtil from "utils/responsiveUtil";
import snackBarUtil from "utils/snackBarUtil";
import patientService from "services/patientService";
import { PATIENT } from "utils/translateUtil";

export default {
   name: "RegistrationCard",

   components: { RegistrationCardBase },

   data: function () {
      return {
         loadingRegistration: false,
      };
   },

   computed: {
      emailStore: {
         get() {
            return this.$store.getters["patient/getEmail"];
         },

         set(value) {
            this.$store.commit("patient/setEmail", value);
         },
      },

      cpfStore: {
         get() {
            return this.$store.getters["patient/getCpf"];
         },

         set(value) {
            this.$store.commit("patient/setCpf", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      async registration(data) {
         this.loadingRegistration = true;

         try {
            const response = await patientService.createPatient(data);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Cadastro realizado com sucesso!",
            });

            /* Seta o email que foi retornado do post, para que na tela de autenticação seja reaproveitado. */
            if (response.data && response.data.email)
               this.emailStore = response.data.email;
            this.cpfStore = null;

            if (this.$route.path !== "/autenticacao")
               this.$router.push({ path: "/autenticacao" });
         } catch (error) {
            this.catch(error);
         } finally {
            this.resetRecaptcha();
            this.loadingRegistration = false;
         }
      },

      catch(error) {
         if (error.response && error.response.status === 400)
            snackBarUtil.showCustomSnackBar(this.translateError400(error));
         else if (error.response && error.response.status === 404)
            snackBarUtil.showCustomSnackBar(this.translateError404(error));
         else if (error.response && error.response.status === 409)
            snackBarUtil.showCustomSnackBar(this.translateError409(error));
         else snackBarUtil.showErrorSnackBar(error);
      },

      translateError400(error) {
         let message = "Verifique os dados informados e tente novamente.";
         if (PATIENT[400][error.response.data.message])
            message = PATIENT[400][error.response.data.message];
         else if (PATIENT[400][error.response.data.description])
            message = PATIENT[400][error.response.data.description];

         return {
            title: "DADOS INCORRETOS",
            message: message,
            icon: "mdi-text-box-remove-outline",
            color: "warning",
            timeout: 6000,
         };
      },

      translateError404(error) {
         let message =
            "Recurso solicitado encontra-se indisponível ou inexistente.";
         if (PATIENT[404][error.response.data.message])
            message = PATIENT[404][error.response.data.message];
         else if (PATIENT[404][error.response.data.description])
            message = PATIENT[404][error.response.data.description];

         return {
            title: "NÃO ENCONTRADO",
            message: message,
            icon: "mdi-robot-dead-outline",
            color: "warning",
            timeout: 6000,
         };
      },

      translateError409(error) {
         let message =
            "O dado que você está tentando gravar ou editar já existe.";
         if (PATIENT[409][error.response.data.message])
            message = PATIENT[409][error.response.data.message];
         else if (PATIENT[409][error.response.data.description])
            message = PATIENT[409][error.response.data.description];

         return {
            title: "DADOS DUPLICADOS",
            message: message,
            icon: "mdi-content-copy",
            color: "warning",
            timeout: 6000,
         };
      },

      resetRecaptcha() {
         if (this.$refs.registrationcarddesktop)
            this.$refs.registrationcarddesktop.resetRecaptcha();
         if (this.$refs.registrationcardmobile)
            this.$refs.registrationcardmobile.resetRecaptcha();
      },
   },
};
</script>

<style scoped>
/* Desktop */
.topbar {
   display: flex;
   justify-content: center;
   align-items: center;

   height: 150px;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );

   overflow: hidden;
}

/* Mobile */
.background-mobile {
   height: 100vh !important;
   width: 100vw !important;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );
}

.float-bottom-card {
   max-height: 90vh !important;
}

.image-sticky-top-position {
   margin-top: 0px;
}

.image-position {
   position: sticky !important;
   top: 0px !important;

   height: 20%;
   width: 100vw;
}
</style>