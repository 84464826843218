<template>
   <v-card
      class="d-flex flex-column justify-space-between align-center card-config"
      height="150"
      width="140"
      flat
   >
      <v-skeleton-loader
         class="mt-5 rounded"
         type="image"
         width="60px"
         height="60px"
      ></v-skeleton-loader>

      <v-skeleton-loader
         type="image"
         width="100%"
         height="50px"
      ></v-skeleton-loader>
   </v-card>
</template>

<script>
export default {
   name: "AttachmentItemSkeleton",
};
</script>

<style scoped>
.card-config {
   border-radius: 7px !important;
   border: 2px solid #c4c4c4 !important;
}
</style>