export default {
   namespaced: true,

   state: {
      user: null,

      userRoutes: ["/minha-conta", "/page404"],

      fullUser: null,
   },

   getters: {
      getUser(state) {
         return state.user;
      },

      getUserRoutes(state) {
         return state.userRoutes;
      },

      getFullUser(state) {
         return state.fullUser;
      },
   },

   mutations: {
      setUser(state, user) {
         state.user = user;

         if (user && user.sub_type) {
            switch (user.sub_type) {
               case "patient":
                  state.userRoutes = [
                     "/minhas-solicitacoes",
                     "/meus-agendamentos",
                  ].concat(state.userRoutes);
                  break;
            }
         } else state.userRoutes = ["/minha-conta", "/page404"];
      },

      setFullUser(state, fullUser) {
         state.fullUser = fullUser;
      },
   },
};
