<template>
   <div class="d-flex justify-center align-center">
      <AuthCard
         @onAuth="onAuth"
         @onFindUnregisteredUser="onFindUnregisteredUser"
         @onFindRegisteredUserWithoutEmail="onFindRegisteredUserWithoutEmail"
         :loading="loadingPatient"
      />
   </div>
</template>

<script>
import AuthCard from "../../components/auth/AuthCard";
import userService from "../../services/userService";
import jwtUtil from "../../utils/jwtUtil";
import responsiveUtil from "../../utils/responsiveUtil";
import snackBarUtil from "utils/snackBarUtil";
import axiosService from "services/axiosService";

export default {
   name: "Auth",

   components: { AuthCard },

   data: function () {
      return {
         dialogVisibility: false,
         loadingPatient: false,
      };
   },

   computed: {
      getUserRoutesStore() {
         return this.$store.getters["user/getUserRoutes"];
      },

      fullUserStore: {
         get() {
            return this.$store.getters["user/getFullUser"];
         },

         set(value) {
            this.$store.commit("user/setFullUser", value);
         },
      },

      termVisibilityStore: {
         get() {
            return this.$store.getters["term/getVisibility"];
         },

         set(value) {
            this.$store.commit("term/setVisibility", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      async onAuth(response) {
         const userTemp = jwtUtil.decodeJwtToken(response.data.access_token);

         switch (userTemp.sub_type) {
            case "patient":
               /* 1. Busca o paciente e seta no fullUserStore. */
               await this.getPatientByIdRaw(
                  userTemp.sub,
                  response.data.access_token
               );

               /* 2. Verifica se o paciente já aceitou o não o termo.
               Caso já tenha aceitado, envia para tela inicial. Caso não tenha, abre o termo. */
               if (!this.fullUserStore.is_authorized)
                  this.termVisibilityStore = true;
               else {
                  userService.setUserSession({
                     token: response.data.access_token,
                  });

                  if (this.$route.path !== this.getUserRoutesStore[0])
                     this.$router.push({ path: this.getUserRoutesStore[0] });
               }

               break;
         }
      },

      onFindUnregisteredUser() {
         this.$router.push({ path: "/cadastro" });
      },

      onFindRegisteredUserWithoutEmail() {
         this.$router.push({ path: "/recuperar-conta" });
      },

      /* Busca o paciente de forma crua, pois o token ainda não foi setado na store. */
      async getPatientByIdRaw(patientId, token) {
         this.loadingPatient = true;

         /* Seta o token temporariamente. */
         axiosService.defaults.headers.common[
            "Authorization"
         ] = `bearer ${token}`;

         try {
            const response = await axiosService.get(`/patients/${patientId}`);

            this.fullUserStore = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingPatient = false;
         }
      },
   },
};
</script>

<style scoped>
</style>