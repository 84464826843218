<template>
   <v-card
      v-if="!isSmallerThan(959)"
      class="card-border overflow-hidden"
      width="1000px"
   >
      <v-row no-gutters>
         <v-col
            class="d-flex flex-column justify-center align-stretch"
            cols="6"
         >
            <div class="px-12 py-16">
               <RetrieveAccountCardBase
                  @onRetrieveAccount="retrieveAccount"
                  :loadingSubmit="loadingRetrieveAccount"
                  ref="retrieveaccountcarddesktop"
               />
            </div>
         </v-col>

         <v-col cols="6">
            <div class="side-right">
               <v-img
                  :src="require('@/assets/images/retrieve-image-circle.png')"
                  height="70%"
                  contain
               >
               </v-img>
            </div>
         </v-col>
      </v-row>
   </v-card>

   <!-- Mobile -->
   <div v-else class="background-mobile">
      <v-img
         :src="require('@/assets/images/retrieve-image-circle.png')"
         height="35%"
         class="image-sticky-top-position"
         contain
      >
      </v-img>

      <v-card
         class="
            float-bottom-card
            border-bottom-card
            box-shadow-bottom-card
            standard-padding-whopping
            pb-8
         "
      >
         <RetrieveAccountCardBase
            @onRetrieveAccount="retrieveAccount"
            :loadingSubmit="loadingRetrieveAccount"
            class="mt-2 mb-7"
            ref="retrieveaccountcardmobile"
         />

         <FooterBase class="mb-n3" :divider="false" />
      </v-card>
   </div>
</template>

<script>
import RetrieveAccountCardBase from "./RetrieveAccountCardBase";
import FooterBase from "components/base/FooterBase.vue";
import responsiveUtil from "utils/responsiveUtil";
import snackBarUtil from "utils/snackBarUtil";
import patientService from "services/patientService";
import util from "utils/util";
import formatUtil from "utils/formatUtil";
import { PATIENT_AUTH } from "utils/translateUtil";

export default {
   name: "RetrieveAccountCard",

   components: { RetrieveAccountCardBase, FooterBase },

   data: function () {
      return {
         loadingRetrieveAccount: false,
      };
   },

   computed: {
      emailStore: {
         get() {
            return this.$store.getters["patient/getEmail"];
         },

         set(value) {
            this.$store.commit("patient/setEmail", value);
         },
      },

      cpfStore: {
         get() {
            return this.$store.getters["patient/getCpf"];
         },

         set(value) {
            this.$store.commit("patient/setCpf", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,

      formatObjectToSend(data) {
         let dataTemp = util.removeBlankAttributes(data);

         if (dataTemp.birth_date)
            dataTemp.birth_date = formatUtil.formatDate(
               dataTemp.birth_date,
               "YYYY-MM-DD",
               "DD/MM/YYYY"
            );

         if (dataTemp.cpf) delete dataTemp.cpf;
         if (dataTemp.cpfStore) delete dataTemp.cpfStore;

         return dataTemp;
      },

      async retrieveAccount(data) {
         this.loadingRetrieveAccount = true;

         try {
            await patientService.retrieve(
               data.cpfStore,
               this.formatObjectToSend(data),
               data.token
            );

            this.emailStore = data.email;
            this.cpfStore = null;

            if (this.$route.path !== "/autenticacao")
               this.$router.push({ path: "/autenticacao" });

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Sua conta foi atualizada!",
            });
         } catch (error) {
            this.catch(error);
         } finally {
            this.resetRecaptcha();
            this.loadingRetrieveAccount = false;
         }
      },

      catch(error) {
         if (error.response && error.response.status === 400)
            snackBarUtil.showCustomSnackBar(this.translateError400(error));
         else if (error.response && error.response.status === 404)
            snackBarUtil.showCustomSnackBar(this.translateError404(error));
         else if (error.response && error.response.status === 409)
            snackBarUtil.showCustomSnackBar(this.translateError409(error));
         else snackBarUtil.showErrorSnackBar(error);
      },

      translateError400(error) {
         let message = "Verifique os dados informados e tente novamente.";
         if (PATIENT_AUTH[400][error.response.data.message])
            message = PATIENT_AUTH[400][error.response.data.message];
         else if (PATIENT_AUTH[400][error.response.data.description])
            message = PATIENT_AUTH[400][error.response.data.description];

         return {
            title: "DADOS INCORRETOS",
            message: message,
            icon: "mdi-text-box-remove-outline",
            color: "warning",
            timeout: 6000,
         };
      },

      translateError404(error) {
         let message =
            "Recurso solicitado encontra-se indisponível ou inexistente.";
         if (PATIENT_AUTH[404][error.response.data.message])
            message = PATIENT_AUTH[404][error.response.data.message];
         else if (PATIENT_AUTH[404][error.response.data.description])
            message = PATIENT_AUTH[404][error.response.data.description];

         return {
            title: "NÃO ENCONTRADO",
            message: message,
            icon: "mdi-robot-dead-outline",
            color: "warning",
            timeout: 6000,
         };
      },

      translateError409(error) {
         let message =
            "O dado que você está tentando gravar ou editar já existe.";
         if (PATIENT_AUTH[409][error.response.data.message])
            message = PATIENT_AUTH[409][error.response.data.message];
         else if (PATIENT_AUTH[409][error.response.data.description])
            message = PATIENT_AUTH[409][error.response.data.description];

         return {
            title: "DADOS DUPLICADOS",
            message: message,
            icon: "mdi-content-copy",
            color: "warning",
            timeout: 6000,
         };
      },

      resetRecaptcha() {
         if (this.$refs.retrieveaccountcarddesktop)
            this.$refs.retrieveaccountcarddesktop.resetRecaptcha();
         if (this.$refs.retrieveaccountcardmobile)
            this.$refs.retrieveaccountcardmobile.resetRecaptcha();
      },
   },
};
</script>

<style scoped>
/* Desktop */
.card-border {
   border-radius: 8px !important;
}

.side-right {
   display: flex;
   justify-content: center;
   align-items: center;

   height: 100%;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );

   padding: 10px;
}

/* Mobile */
.background-mobile {
   height: 100vh !important;
   width: 100vw !important;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );
}
</style>