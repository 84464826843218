import axiosService from "./axiosService";

/* 
   query: Objeto com dados de paginação, ordenação e filtros. O objeto pode
   apresentar os atributos abaixo:
      page: Number com o número da página desejada;
      limit: Number com o número de itens desejado na resposta;
      sort: String com o nome do atributo que deseja ordenar os itens;
      filter: Objeto com dois atributos, attribute e query:
         attribute: String com o nome do atributo que deseja filtrar os itens;
         query: String com a palavra que deseja filtrar;
*/

async function getAllRequestAttachments(requestId, query) {
   const tempPage = query && query.page ? query.page : 1;
   const tempLimit = query && query.limit ? query.limit : 20;
   const tempSort = query && query.sort ? query.sort : "-created_at";

   let url = `/requests/${requestId}/attachments?page=${tempPage}&limit=${tempLimit}&sort=${tempSort}`;

   if (query && query.filter && query.filter.attribute && query.filter.query) {
      url += `&${query.filter.attribute}=`;
      switch (query.filter.attribute) {
         default:
            url += `*${query.filter.query}*`;
            break;
      }
   }

   return await axiosService.get(url);
}

async function getRequestAttachmentById(requestId, attachmentId) {
   return await axiosService.get(
      `/requests/${requestId}/attachments/${attachmentId}`,
      {
         responseType: "blob"
      }
   );
}

async function createRequestAttachment(
   requestId,
   attachment,
   onUploadProgress
) {
   return await axiosService.post(
      `/requests/${requestId}/attachments`,
      attachment,
      {
         onUploadProgress: onUploadProgress,
         headers: {
            "Content-Type": "multipart/form-data"
         }
      }
   );
}

async function removeRequestAttachment(requestId, attachmentId) {
   return await axiosService.delete(
      `/requests/${requestId}/attachments/${attachmentId}`
   );
}

export default {
   getAllRequestAttachments,
   getRequestAttachmentById,
   createRequestAttachment,
   removeRequestAttachment
};
