<template>
   <div>
      <v-form ref="form" :readonly="!editMode">
         <v-card :disabled="!editMode" flat>
            <p class="font-sora font-weight-bold mb-2">Dados pessoais</p>

            <v-row class="mt-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="myAccount.name"
                     :rules="[(value) => !!value || 'Nome obrigatório.']"
                     id="name-patient-form-input"
                     label="Nome*"
                     placeholder="Digite seu nome"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="myAccount.cpf"
                     v-maska="'###.###.###-##'"
                     :rules="[
                        (value) => !!value || 'CPF obrigatório.',
                        (value) => cpfValidation(value) || 'CPF Inválido.',
                     ]"
                     type="tel"
                     id="cpf-patient-form-input"
                     label="CPF*"
                     placeholder="000.000.000-00"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="3">
                  <v-select
                     v-model="myAccount.gender"
                     :items="genderItems"
                     :rules="[(value) => !!value || 'Sexo obrigatório.']"
                     label="Sexo*"
                     placeholder="Selecione seu sexo"
                     id="gender-patient-form-input"
                  >
                     <template v-slot:item="{ item, on, attrs }">
                        <v-list-item v-on="on" v-bind="attrs">
                           <v-list-item-content
                              :id="`item-${attrs.id.split('-')[3]}`"
                           >
                              <v-list-item-title>
                                 {{ item.text }}
                              </v-list-item-title>
                           </v-list-item-content>
                        </v-list-item>
                     </template>
                  </v-select>
               </v-col>
            </v-row>

            <v-row class="mt-3">
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="myAccount.phone"
                     v-maska="['(##) ####-####', '(##) #####-####']"
                     :rules="[
                        (value) => !!value || 'Telefone obrigatório.',
                        (value) =>
                           (value && value.length >= 14) ||
                           'Telefone inválido.',
                     ]"
                     type="tel"
                     label="Telefone*"
                     placeholder="(00) 00000-0000"
                     id="phone-patient-form-input"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="myAccount.birth_date"
                     v-maska="'##/##/####'"
                     :rules="[
                        (value) => !!value || 'Data de nascimento obrigatória.',
                        (value) => dateValidation(value) || 'Data inválida.',
                     ]"
                     type="tel"
                     label="Data de nascimento*"
                     placeholder="00/00/0000"
                     id="birth_date-patient-form-input"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="myAccount.address.birth_city"
                     :rules="[
                        (value) =>
                           !!value || 'Cidade de nascimento obrigatória.',
                     ]"
                     label="Cidade de nascimento*"
                     placeholder="Digite sua cidade de nascimento"
                     id="birth_city-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-3">
               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="myAccount.sus_card"
                     v-maska="'###############'"
                     :rules="[
                        (value) =>
                           !value
                              ? true
                              : (value && value.length >= 15) ||
                                'Cartão do SUS inválido.',
                     ]"
                     type="tel"
                     label="Cartão do SUS (Opcional)"
                     placeholder="000000000000000"
                     id="sus_card-patient-form-input"
                  ></v-text-field>
               </v-col>

               <v-col cols="12" md="3">
                  <v-text-field
                     v-model="myAccount.rg"
                     v-maska="'##########'"
                     type="tel"
                     label="RG (Opcional)"
                     placeholder="0000-000"
                     id="rg-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="font-sora font-weight-bold mt-10 mb-2">Endereço</p>

            <v-row class="mt-0">
               <v-col cols="12" md="4">
                  <v-text-field
                     v-model="myAccount.address.zip_code"
                     v-maska="'########'"
                     :rules="[
                        (value) => !!value || 'CEP obrigatório.',
                        (value) =>
                           (value && value.length >= 8) || 'CEP inválido.',
                        (value) =>
                           cepParaibaOnlyValidation(value) ||
                           'O CEP informado não é da Paraíba.',
                     ]"
                     :loading="loadingSearchZipCode"
                     type="tel"
                     id="zip_code-patient-form-input"
                     label="CEP*"
                     placeholder="00000000"
                     maxlength="8"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="myAccount.address.street"
                     :rules="[(value) => !!value || 'Rua obrigatória.']"
                     label="Rua*"
                     placeholder="Digite o nome da rua"
                     id="street-patient-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="2">
                  <v-text-field
                     v-model="myAccount.address.number"
                     :rules="[(value) => !!value || 'Número obrigatório.']"
                     label="Número*"
                     placeholder="Digite o número"
                     id="number-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-3">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="myAccount.address.district"
                     :rules="[(value) => !!value || 'Bairro obrigatório.']"
                     label="Bairro*"
                     placeholder="Digite o nome do bairro"
                     id="district-patient-form-input"
                  ></v-text-field>
               </v-col>
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="myAccount.address.city"
                     :rules="[(value) => !!value || 'Cidade obrigatória.']"
                     label="Cidade*"
                     placeholder="Digite o nome da cidade"
                     id="city-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <v-row class="mt-3">
               <v-col cols="12">
                  <v-text-field
                     v-model="myAccount.address.complement"
                     label="Complemento (Opcional)"
                     placeholder="Digite o complemento"
                     id="complement-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>

            <p class="font-sora font-weight-bold mt-10 mb-2">
               Dados de autenticação
            </p>

            <v-row class="my-0">
               <v-col cols="12" md="6">
                  <v-text-field
                     v-model="myAccount.email"
                     :rules="[
                        (value) => !!value || 'Email obrigatório.',
                        (value) => emailRules(value) || 'Email inválido.',
                     ]"
                     type="email"
                     label="Email"
                     placeholder="Digite seu email"
                     id="email-patient-form-input"
                  ></v-text-field>
               </v-col>
            </v-row>
         </v-card>
      </v-form>
   </div>
</template>

<script>
import formatUtil from "utils/formatUtil";
import snackBarUtil from "utils/snackBarUtil";
import zipCodeService from "services/zipCodeService";
import validationUtil from "utils/validationUtil";

export default {
   name: "MyAccountFormBase",

   props: {
      myAccount: {
         type: Object,
      },

      editMode: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         loadingSearchZipCode: false,

         genderItems: [
            {
               text: "Masculino",
               value: "male",
            },
            {
               text: "Feminino",
               value: "female",
            },
         ],
      };
   },

   watch: {
      "myAccount.address.zip_code": async function handler(newZip, oldZip) {
         if (newZip && newZip.length === 8 && oldZip && oldZip.length === 7) {
            this.loadingSearchZipCode = true;

            try {
               const response = await zipCodeService.getZipCode(newZip);

               if (response.data.erro) {
                  snackBarUtil.showCustomSnackBar({
                     title: "NÃO ENCONTRADO",
                     message:
                        "O cep digitado não foi encontrado, tente novamente!",
                     icon: "mdi-map-marker-off",
                     color: "info",
                  });
               } else {
                  if (response.data && response.data.uf === "PB") {
                     this.myAccount.address.street = response.data.logradouro;
                     this.myAccount.address.district = response.data.bairro;
                     this.myAccount.address.city = response.data.localidade;
                     this.myAccount.address.state = response.data.uf;
                     if (response.data.complemento)
                        this.myAccount.address.complement =
                           response.data.complemento;
                  }
               }
            } catch (error) {
               snackBarUtil.showErrorSnackBar(error);
            } finally {
               this.loadingSearchZipCode = false;
            }
         }
      },
   },

   methods: {
      ...formatUtil,
      ...validationUtil,

      emailRules(value) {
         if (value) return validationUtil.emailValidation(value);
      },

      cepParaibaOnlyValidation(cep) {
         if (cep && cep.length >= 8)
            return parseInt(cep) >= 58000000 && parseInt(cep) <= 58999999;

         return false;
      },
   },
};
</script>

<style scoped>
</style>