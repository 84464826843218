<template>
   <div class="ma-n1">
      <v-btn
         @click="nextStep"
         :loading="loading"
         class="ma-1 py-3"
         color="primary"
         depressed
      >
         {{ nextStepText }}
         <v-icon class="ml-1" small>{{ nextStepIcon }}</v-icon>

         <template v-slot:loader>
            <v-progress-circular
               indeterminate
               color="white"
               size="16"
               width="2"
            ></v-progress-circular>
         </template>
      </v-btn>

      <v-btn
         v-if="!hidePreviousStep"
         @click="previousStep"
         class="ma-1 py-3"
         color="primary"
         text
      >
         {{ previousStepText }}
      </v-btn>
   </div>
</template>

<script>
export default {
   name: "RegistrationStepperControl",

   props: {
      nextStepIcon: {
         type: String,
         default: "mdi-arrow-right",
      },

      nextStepText: {
         type: String,
         default: "Próximo",
      },

      previousStepText: {
         type: String,
         default: "Voltar",
      },

      hidePreviousStep: {
         type: Boolean,
         default: false,
      },

      loading: {
         type: Boolean,
         default: false,
      },
   },

   methods: {
      nextStep() {
         this.$emit("onClickNextStep");
      },

      previousStep() {
         this.$emit("onClickPreviousStep");
      },
   },
};
</script>

<style scoped>
</style>