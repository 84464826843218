<template>
   <div class="d-flex justify-center align-center change-password-div">
      <ChangePasswordCard class="change-password-card" />
   </div>
</template>

<script>
import ChangePasswordCard from "../../components/auth/ChangePasswordCard";

export default {
   name: "ChangePassword",

   components: { ChangePasswordCard },
};
</script>

<style scoped>
.change-password-div {
   height: 100%;
}

.change-password-card {
   width: 400px;
}
</style>