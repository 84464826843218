<template>
   <div>
      <v-bottom-navigation
         v-model="tabIndex"
         class="bottom-navigation-border flex-column"
         color="primary"
         height="90px"
         fixed
         shift
         grow
      >
         <div class="bottom-buttons-config">
            <v-btn
               v-for="(item, index) in menuRouteList"
               v-ripple="{ center: true }"
               :to="item.link"
               :key="index"
            >
               <span class="caption">{{ item.text }}</span>
               <v-icon>{{ item.icon }}</v-icon>
            </v-btn>
         </div>

         <FooterBase />
      </v-bottom-navigation>
   </div>
</template>

<script>
import FooterBase from "./FooterBase";

export default {
   name: "BottomNavigationBar",

   components: { FooterBase },

   data: function () {
      return {
         menuRouteList: [
            {
               icon: "mdi-file-document-outline",
               text: "Solicitações",
               link: "/minhas-solicitacoes",
            },
            {
               icon: "mdi-calendar-outline",
               text: "Agendamentos",
               link: "/meus-agendamentos",
            },
            {
               icon: "mdi-account-outline",
               text: "Dados",
               link: "/minha-conta",
            },
         ],

         tabIndex: 0,
      };
   },

   created() {
      const activeIndex = this.menuRouteList.findIndex(
         (item) => item.link === this.$route.path
      );

      if (activeIndex !== -1) this.tabIndex = activeIndex;
   },
};
</script>

<style scoped>
::v-deep .v-btn:before {
   opacity: 0 !important;
}

.bottom-navigation-border {
   border-top: 1px solid rgb(148, 148, 148, 0.2);
}

.bottom-buttons-config {
   display: flex;
   align-items: center;
   width: 100%;
   height: 100%;
}
</style>