import store from "../store";

function showCustomSnackBar(configuration) {
   store.dispatch("snackBar/customSnackbar", configuration);
   store.commit("snackBar/setVisibility", true);
}

function showErrorSnackBar(error) {
   let title, message, icon, color;

   if (error.response)
      switch (error.response.status) {
         case 400:
            title = "DADOS INCORRETOS";
            message = "Verifique os dados informados e tente novamente.";
            icon = "mdi-text-box-remove-outline";
            color = "warning";
            break;
         case 401:
            title = "NÃO AUTENTICADO";
            message = "Usuário não está devidamente autenticado.";
            icon = "mdi-alert-octagon-outline";
            color = "warning";
            break;
         case 403:
            title = "NÃO AUTORIZADO";
            message =
               "Usuário não possui permissão para acessar o recurso solicitado.";
            icon = "mdi-alert-octagon-outline";
            color = "warning";
            break;
         case 404:
            title = "NÃO ENCONTRADO";
            message =
               "Recurso solicitado encontra-se indisponível ou inexistente.";
            icon = "mdi-robot-dead-outline";
            color = "warning";
            break;
         case 409:
            title = "DADOS DUPLICADOS";
            message =
               "O dado que você está tentando gravar ou editar já existe.";
            icon = "mdi-content-copy";
            color = "warning";
            break;
         case 429:
            title = "TENTATIVAS EXCEDIDAS";
            message = "Aguarde o tempo de 1 hora e tente novamente.";
            color = "warning";
            break;
         case 500:
            title = "ERRO INTERNO DO SERVIDOR";
            message = "Ocorreu um erro durante a operação, tente novamente.";
            color = "error";
            break;
         case 502:
            title = "SERVIÇO INDISPONÍVEL";
            message =
               "Serviço solicitado encontra-se indisponível, contate o administrador.";
            color = "error";
            break;
         default:
            title = "FALHA DURANTE A OPERAÇÃO";
            message =
               "Um erro não esperado ocorreu durante a operação, tente novamente.";
            color = "error";
            break;
      }
   else {
      title = "FALHA NA CONEXÃO";
      message = "Verifique sua conexão e tente novamente.";
      icon = "mdi-wifi-off";
      color = "error";
   }

   this.showCustomSnackBar({
      title,
      message,
      icon,
      color,
   });
}

export default { showCustomSnackBar, showErrorSnackBar };
