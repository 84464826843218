<template>
   <div class="d-flex flex-column justify-center align-center">
      <p class="text-404">404</p>

      <p class="text-title mb-9">OPS! NADA FOI ENCONTRADO</p>

      <p class="text-center body-1">
         A página que você está procurando pode ter sido removida ou está <br />
         temporariamente indisponível.
      </p>

      <span class="divider my-9"></span>

      <v-btn @click="back" color="primary" depressed large>
         <v-icon left>mdi-arrow-left</v-icon>
         VOLTAR</v-btn
      >
   </div>
</template>

<script>
export default {
   name: "Page404Content",

   computed: {
      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },

      getUserRoutesStore() {
         return this.$store.getters["user/getUserRoutes"];
      },
   },

   methods: {
      back() {
         if (this.userStore) {
            if (this.$route.path !== this.getUserRoutesStore[0])
               this.$router.push({ path: this.getUserRoutesStore[0] });
         } else {
            if (this.$route.path !== "/autenticacao")
               this.$router.push({ path: "/autenticacao" });
         }
      },
   },
};
</script>

<style scoped>
.text-404 {
   letter-spacing: 1rem !important;
   font-size: 20vh;
   font-weight: 400;
   text-align: center;
   opacity: 0.1;
   height: 19vh;
}

.text-title {
   letter-spacing: 0.1666666667em;
   font-size: 1.7rem;
   font-weight: 900;
   text-align: center;
   color: rgba(92, 107, 192, 1);
}

.divider {
   height: 3px;
   width: 70px;
   background-color: rgba(92, 107, 192, 1);
}
</style>