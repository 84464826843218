import vuetify from "../plugins/vuetify";

function isSmallerThan(value) {
   return vuetify.framework.breakpoint.width <= value;
}

async function scrollTo(target) {
   await vuetify.framework.goTo(target, {
      duration: 200,
      offset: 0,
      easing: "easeInOutCubic",
   });
}

export default { isSmallerThan, scrollTo };
