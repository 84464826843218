<template>
   <div>
      <v-dialog
         v-model="dialogForm"
         :fullscreen="isSmallerThanComputed"
         :hide-overlay="isSmallerThanComputed"
         :persistent="isSmallerThanComputed"
         :transition="
            isSmallerThanComputed
               ? 'dialog-bottom-transition'
               : 'slide-y-transition'
         "
         width="550px"
      >
         <v-card tile id="scrollTop">
            <Close @close="closeDialogForm" :desktop="!isSmallerThanComputed" />

            <MyRequestsForm
               @onClickBack="closeDialogForm"
               @onUpdate="closeDialogForm()"
               @onCreated="
                  closeDialogForm();
                  refreshRequestsList();
               "
               :request="requestSelected"
               class="standard-padding padding-top-mobile"
            />
         </v-card>
      </v-dialog>

      <MyRequestsList
         @onClickView="onClickView"
         @onClickAdd="onClickAdd"
         ref="myrequestslist"
      />
   </div>
</template>

<script>
import Close from "components/base/Close";
import MyRequestsList from "components/myRequests/MyRequestsList";
import MyRequestsForm from "components/myRequests/MyRequestsForm.vue";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "MyRequests",

   components: { MyRequestsList, MyRequestsForm, Close },

   data: function () {
      return {
         dialogForm: false,

         requestSelected: {},
      };
   },

   computed: {
      isSmallerThanComputed() {
         return this.isSmallerThan(959);
      },
   },

   methods: {
      ...responsiveUtil,

      openDialogForm() {
         this.dialogForm = true;
      },

      closeDialogForm() {
         this.dialogForm = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },

      onClickView(request) {
         this.requestSelected = Object.assign({}, request);
         this.openDialogForm();
      },

      onClickAdd(request) {
         this.requestSelected = Object.assign({}, request);
         this.openDialogForm();
      },

      refreshRequestsList() {
         this.$refs.myrequestslist.refreshAllRequestsByRef();
      },
   },
};
</script>

<style scoped>
@media (max-width: 959px) {
   .padding-top-mobile {
      padding-top: 40px;
   }
}
</style>