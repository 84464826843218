export const PATIENT_AUTH = {
   400: {
      "Invalid email address!":
         "Email inválido, tente novamente para continuar!",
      "name, birth_date, mother_name does not match the provided cpf.":
         "Nome, nome da mãe e data de nascimento não correspondem com as informações cadastradas!",

      "birth_date, mother_name does not match the provided cpf.":
         "Nome da mãe e data de nascimento não correspondem com as informações cadastradas!",
      "name, birth_date does not match the provided cpf.":
         "Nome e data de nascimento não correspondem com as informações cadastradas!",
      "name, mother_name does not match the provided cpf.":
         "Nome e nome da mãe não correspondem com as informações cadastradas!",

      "name does not match the provided cpf.":
         "Nome não corresponde com a informação cadastrada!",
      "mother_name does not match the provided cpf.":
         "Nome da mãe não corresponde com a informação cadastrada!",
      "birth_date does not match the provided cpf.":
         "Data de nascimento não corresponde com a informação cadastrada!",
   },

   404: {
      "Patient not found!":
         "Não encontramos nenhum cadastro com as informações fornecidas, tente novamente!",
      "CPF not found!": "CPF não encontrado, tente novamente!",
   },

   409: {
      "A user with email is already registered!":
         "O email fornecido já está em uso!",
   },
};

export const PATIENT = {
   400: {
      "Invalid email address!":
         "Email inválido, tente novamente para continuar!",
      "Cns must be a valid string of digits!":
         "Cartão do SUS inválido, tente novamente para continuar!",
      "The zip_code is from an address belonging to another Municipality.":
         "O CEP fornecido pertence a outro município!",
   },

   404: {
      "Municipality not found!":
         "A cidade fornecida não existe na nossa base de dados, tente novamente para continuar!",
      "zip_code not found in external API.":
         "O CEP fornecido não existe na nossa base de dados, tente novamente para continuar!",
   },

   409: {
      "A user with this cpf, email or sus_card is already registered!":
         "O CPF, email ou cartão do SUS fornecido já está em uso!",
   },
};

export const SURGERY_REQUEST = {
   400: {
      "SurgeryRequest cannot be registered!":
         "Você tem várias solicitações abertas, aguarde serem finalizadas para cadastrar!",
      "The Municipality provided does not have a registration.":
         "Seu município não foi encontrado na Paraíba, contacte a Secretaria de Saúde do seu municípío para mais informações.",
      "There is no HealthSecretary registered for this Municipality.":
         "Não achamos a Secretaria de Saúde do seu município.",
      "The Attachment size must be less than or equal to 5mb.":
         "Os documentos juntos não podem exceder 5 MB.",
   },
};
