
<template>
   <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      style="enable-background: new 0 0 24 24"
      xml:space="preserve"
      class="medical-bag-outline"
   >
      <g id="Camada_1"></g>
      <g id="Camada_2">
         <g>
            <path
               d="M22,18.7c-0.3-3.2-0.6-6.5-0.9-9.7c0-0.2-0.1-1-0.9-1.6C19.7,7.1,19.3,7,19,7c-1,0-2,0-3,0V5l-2-2h-4L8,5v2H5.2
			c0,0-0.1,0-0.1,0C4.8,7,4.4,7,3.9,7.3C3,8,2.9,9.5,2.9,9.5c-0.3,3.2-0.6,6.4-0.9,9.6c0,0.2,0,0.7,0.4,1.2C2.8,20.9,3.5,21,3.8,21
			c0.1,0,0.1,0,0.1,0c5.4,0,10.8,0,16.3,0c0,0,0,0,0,0c0.2,0,1,0,1.5-0.7C22.2,19.6,22,18.8,22,18.7z M10,5h4v2c-1.1,0-2.1,0-3.2,0
			H10V5z M4,19l0.5-4.7l0.5-4.6l0-0.2l0,0C5,9.3,5,9.1,5.1,9c0,0,0,0,0,0l0,0l0,0h0h5.6h8.1c0,0,0,0,0.1,0C19,9.1,19,9.2,19,9.2
			l0.1,0.9l0.9,8.8l0,0.1H4z"
            />
            <polygon
               points="13,10 11,10 11,13 8,13 8,15 11,15 11,18 13,18 13,15 16,15 16,13 13,13 		"
            />
         </g>
      </g>
   </svg>
</template>

<script>
export default {
   name: "MedicalBagOutline",
};
</script>

<style scoped>
.medical-bag-outline {
   fill: currentColor;
   margin: 0px;
   padding: 0px;
}
</style>