<template>
   <div>
      <v-dialog
         v-model="dialogForm"
         :fullscreen="isSmallerThanComputed"
         :hide-overlay="isSmallerThanComputed"
         :persistent="isSmallerThanComputed"
         :transition="
            isSmallerThanComputed
               ? 'dialog-bottom-transition'
               : 'slide-y-transition'
         "
         width="550px"
      >
         <v-card tile id="scrollTop">
            <Close @close="closeDialogForm" :desktop="!isSmallerThanComputed" />

            <MySchedulesForm
               @onClickBack="closeDialogForm"
               :schedule="scheduleSelected"
               class="standard-padding padding-top-mobile"
            />
         </v-card>
      </v-dialog>

      <MySchedulesList @onClickView="onClickView" />
   </div>
</template>

<script>
import MySchedulesList from "components/mySchedules/MySchedulesList";
import MySchedulesForm from "components/mySchedules/MySchedulesForm";
import Close from "components/base/Close";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "MyRequests",

   components: { MySchedulesList, MySchedulesForm, Close },

   data: function () {
      return {
         dialogForm: false,

         scheduleSelected: {},
      };
   },

   computed: {
      isSmallerThanComputed() {
         return this.isSmallerThan(959);
      },
   },

   methods: {
      ...responsiveUtil,

      openDialogForm() {
         this.dialogForm = true;
      },

      closeDialogForm() {
         this.dialogForm = false;

         /* Reseta o scroll do dialog. */
         const element = document.getElementById("scrollTop");
         if (element) element.parentElement.scrollTop = 0;
      },

      onClickView(schedule) {
         this.scheduleSelected = Object.assign({}, schedule);
         this.openDialogForm();
      },
   },
};
</script>

<style scoped>
@media (max-width: 959px) {
   .padding-top-mobile {
      padding-top: 40px;
   }
}
</style>