import jwtUtil from "../utils/jwtUtil";
import store from "../store";
import axiosService from "./axiosService";

const storageKey =
   process.env.VUE_APP_LOCAL_STORAGE_KEY || "VUE_APP_LOCAL_STORAGE_KEY";

function setUserStoreAndAxiosAuthorization(data) {
   if (data && data.token) {
      axiosService.defaults.headers.common[
         "Authorization"
      ] = `bearer ${data.token}`;

      let decodedUser = jwtUtil.decodeJwtToken(data.token);
      store.commit("user/setUser", decodedUser);
   } else {
      delete axiosService.defaults.headers.common["Authorization"];
      store.commit("user/setUser", null);
      store.commit("user/setFullUser", null);
   }
}

function setUserSession(data) {
   localStorage.setItem(storageKey, JSON.stringify(data));
   setUserStoreAndAxiosAuthorization(data);
   return store.getters["user/getUser"];
}

function removeUserSession() {
   localStorage.removeItem(storageKey);
   setUserStoreAndAxiosAuthorization(null);
}

function retrieveUserSession() {
   let localStorageData = JSON.parse(localStorage.getItem(storageKey));
   setUserStoreAndAxiosAuthorization(
      localStorageData ? localStorageData : null
   );
   return store.getters["user/getUser"];
}

function getUserSession() {
   return JSON.parse(localStorage.getItem(storageKey));
}

async function resetPassword(userId, password) {
   return await axiosService.put(`/users/${userId}/password`, { password });
}

async function activateUser(userId, activationData) {
   return await axiosService.put(`/users/${userId}/active`, activationData);
}

async function authorizedUser(userId, authorization) {
   return await axiosService.put(`/users/${userId}/authorized`, {
      is_authorized: authorization,
   });
}

export default {
   setUserSession,
   removeUserSession,
   retrieveUserSession,
   getUserSession,

   resetPassword,
   activateUser,
   authorizedUser,
};
