<template>
   <!-- Desktop -->
   <div v-if="!isSmallerThan(959)">
      <v-data-table
         @click:row="view"
         :headers="headers"
         :items="requests"
         :loading="loadingRequests"
         :items-per-page="-1"
         class="box-shadow-data-table"
         hide-default-header
         hide-default-footer
      >
         <template v-slot:top>
            <div
               class="
                  d-flex
                  justify-space-between
                  align-center
                  standard-padding-large
                  py-4
               "
            >
               <p class="font-weight-bold font-sora main-title primary--text">
                  <v-icon class="mb-1" color="primary" size="26" left
                     >mdi-file-document</v-icon
                  >
                  MINHAS SOLICITAÇÕES
               </p>

               <v-btn @click="add" color="primary">
                  Nova solicitação
                  <v-icon right small>mdi-plus</v-icon>
               </v-btn>
            </div>

            <v-divider></v-divider>
         </template>

         <template v-slot:no-data>
            <div
               class="
                  d-flex
                  flex-column
                  justify-center
                  align-center
                  standard-padding
                  cursor-default
               "
            >
               <p
                  class="
                     text-center
                     font-sora
                     loading-requests-label
                     primary--text
                  "
               >
                  Você não tem nenhuma <br />
                  solicitação cadastrada.
               </p>

               <v-img
                  :src="require('@/assets/images/empty-folder.png')"
                  height="95px"
                  class="my-4"
                  contain
               >
               </v-img>
            </div>
         </template>

         <template v-slot:loading>
            <p
               class="
                  text-center
                  font-sora
                  loading-requests-label
                  primary--text
                  standard-padding
                  cursor-default
               "
            >
               Carregando as <br />
               solicitações...
            </p>
         </template>

         <template v-slot:[`item.code`]="{ item }">
            <div class="d-flex align-center">
               <div class="code-cell">
                  <p class="label-small text--secondary">
                     <v-icon class="margin-icon" size="16">
                        mdi-text-box-search-outline
                     </v-icon>
                     Código
                  </p>
                  <p class="body-2 mt-1">{{ item.code }}</p>
               </div>

               <MyRequestsListDataTableCellNotification
                  @onHaveNotifications="(value) => addNotification(item, value)"
                  :request="item"
               />
            </div>
         </template>

         <template v-slot:[`item.origin`]="{ item }">
            <MyRequestsListDataTableCellOrigin :request="item" />
         </template>

         <template v-slot:[`item.status`]="{ item }">
            <p class="label-small text--secondary">
               <v-icon class="margin-icon" size="16">mdi-clock-outline</v-icon>
               Estado
            </p>

            <v-chip
               v-if="!item.is_archived"
               class="chip-status mt-1"
               :color="generateState(item.status).color"
               x-small
            >
               {{ generateState(item.status).text }}
            </v-chip>

            <v-chip v-else class="chip-status mt-1" color="warning" x-small>
               Arquivada
            </v-chip>
         </template>

         <template v-slot:[`item.was_created_by_patient`]="{ item }">
            <div class="registred-by-cell">
               <p class="label-small text--secondary">
                  <v-icon class="margin-icon" size="18">
                     mdi-account-outline
                  </v-icon>
                  Cadastrada por
               </p>
               <p class="body-2 mt-1">
                  {{ item.was_created_by_patient ? "Você" : "Operador" }}
               </p>
            </div>
         </template>
      </v-data-table>
   </div>

   <!-- Mobile -->
   <div v-else class="standard-padding">
      <v-btn
         @click="add"
         class="button-plus"
         color="primary"
         fab
         dark
         fixed
         bottom
         right
         height="45px"
         width="45px"
      >
         <v-icon>mdi-plus</v-icon>
      </v-btn>

      <div
         v-if="loadingRequests"
         class="d-flex flex-column justify-center align-center mt-16"
      >
         <p
            class="
               text-center
               font-sora
               loading-requests-label
               primary--text
               mb-5
            "
         >
            Carregando as <br />
            solicitações...
         </p>

         <v-progress-linear
            style="width: 50%"
            color="primary"
            indeterminate
            rounded
            height="4"
         ></v-progress-linear>
      </div>

      <div v-else-if="requests.length" class="mx-n3 mb-n3 mt-n12">
         <MyRequestsListItem
            @onClickViewRequest="view"
            @onHaveNotifications="(value) => addNotification(request, value)"
            v-for="(request, index) in requests"
            :key="index"
            :request="request"
         />
      </div>

      <div v-else class="d-flex flex-column justify-center align-center mt-16">
         <p class="text-center font-sora loading-requests-label primary--text">
            Você não tem nenhuma <br />
            solicitação cadastrada.
         </p>

         <v-img
            :src="require('@/assets/images/empty-folder.png')"
            height="95px"
            class="my-4"
            contain
         >
         </v-img>

         <p class="text-center font-sora loading-requests-label primary--text">
            Clique no
            <span class="button-add-tutorial">
               <v-icon dark small>mdi-plus</v-icon>
            </span>
            para <br />
            adicionar!
         </p>
      </div>
   </div>
</template>

<script>
import MyRequestsListItem from "./mobile/MyRequestsListItem";
import MyRequestsListDataTableCellOrigin from "./desktop/MyRequestsListDataTableCellOrigin";
import MyRequestsListDataTableCellNotification from "./desktop/MyRequestsListDataTableCellNotification";
import responsiveUtil from "utils/responsiveUtil";
import snackBarUtil from "utils/snackBarUtil";
import patientService from "services/patientService";
import formatUtil from "utils/formatUtil";
import util from "utils/util";

export default {
   name: "MyRequestsList",

   components: {
      MyRequestsListItem,
      MyRequestsListDataTableCellOrigin,
      MyRequestsListDataTableCellNotification,
   },

   data: function () {
      return {
         loadingRequests: false,
         requests: [],

         page: 1,
         limit: 100,
         sort: "-created_at",

         headers: [
            { value: "code", align: "center" },
            { value: "origin", align: "center" },
            { value: "status", align: "center" },
            {
               value: "was_created_by_patient",
               align: "center",
            },
         ],
      };
   },

   computed: {
      query() {
         const query = {
            page: this.page,
            limit: this.limit,
            sort: this.sort,
         };

         return query;
      },

      userStore: {
         get() {
            return this.$store.getters["user/getUser"];
         },

         set(value) {
            this.$store.commit("user/setUser", value);
         },
      },
   },

   methods: {
      ...formatUtil,
      ...responsiveUtil,
      ...util,

      view(request) {
         this.$emit("onClickView", request);
      },

      add(request) {
         this.$emit("onClickAdd", request);
      },

      addNotification(request, unreadMessages) {
         request.unreadMessages = unreadMessages;
      },

      async refreshAllRequestsByRef() {
         this.getAllPatientSurgeryRequests(this.userStore.sub, this.query);
      },

      async getAllPatientSurgeryRequests(patientId, query) {
         this.loadingRequests = true;
         await responsiveUtil.scrollTo(0);

         try {
            const response = await patientService.getAllPatientSurgeryRequests(
               patientId,
               query
            );

            /* Alterar status 'visualmente' da solicitação */
            const requestsModifiedStatus = response.data.map((item) => {
               let result = item;
               if (item.status === "rejected" && !item.is_closed)
                  item.status = "rejected_by_executor";
               return result;
            });

            this.requests = Object.assign([], requestsModifiedStatus);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingRequests = false;
         }
      },
   },

   created() {
      this.getAllPatientSurgeryRequests(this.userStore.sub, this.query);
   },
};
</script>

<style scoped>
.loading-requests-label {
   font-weight: 400 !important;
   font-size: 0.875rem !important;
   letter-spacing: 0.0178571429em !important;
}

.button-plus {
   bottom: 106px !important;
}

.button-add-tutorial {
   height: 23px;
   width: 23px;
   background-color: var(--v-primary-base);
   border-radius: 50%;
   display: inline-block;

   margin-left: 3px;
   margin-right: 3px;
}

.label-small {
   font-size: 0.685rem !important;
   letter-spacing: 0.0125em;
}

.margin-icon {
   margin-right: 3px;
}

.chip-status {
   border-radius: 20px !important;
   padding-left: 8px;
   padding-right: 8px;
   height: 18px;
}

::v-deep td {
   padding: 15px 30px !important;
}

.code-cell {
   width: 140px !important;
}

.registred-by-cell {
   width: 105px !important;
}

.main-title {
   font-size: 1.1rem !important;
   letter-spacing: 0.0125em;
}
</style>