import Vue from "vue";
import Vuex from "vuex";

import snackBar from "./base/snackBar";
import user from "./base/user";

import patient from "./patient/patient";
import term from "./term/term";

Vue.use(Vuex);

export default new Vuex.Store({
   modules: {
      snackBar,
      user,
      patient,
      term,
   },
});
