<template>
   <div>
      <router-link
         class="router-link-back-auth"
         to="/autenticacao"
         id="go-to-auth-button"
      >
         <p
            @click="onNavigateToAuth"
            class="caption primary--text label-back-auth"
         >
            <v-icon color="primary" size="15">mdi-arrow-left</v-icon>
            Voltar para tela de autenticação
         </p>
      </router-link>

      <div class="mt-6 mb-12">
         <p class="font-weight-bold font-sora main-title">Recuperar conta</p>
         <p class="text--secondary body-2 mt-1">
            Verificamos que sua conta já foi <strong>cadastrada</strong> porém
            não tem <strong>email</strong> e <strong>senha</strong>. Preencha as
            informações e cadastre suas credenciais para acessar o sistema.
         </p>
      </div>

      <v-form ref="form">
         <v-text-field
            v-if="!cpfStore"
            v-model="formData.cpf"
            v-maska="'###.###.###-##'"
            :rules="[
               (value) => !!value || 'CPF obrigatório.',
               (value) => cpfValidation(value) || 'CPF Inválido.',
            ]"
            class="body-2 label-position"
            type="tel"
            id="cpf-patient-form-input"
            label="CPF*"
            placeholder="000.000.000-00"
            height="40"
            autofocus
         >
            <template v-slot:prepend-inner>
               <v-icon size="18" left
                  >mdi-badge-account-horizontal-outline</v-icon
               >
            </template>
         </v-text-field>

         <v-text-field
            v-model="formData.name"
            :rules="[(value) => !!value || 'Nome obrigatório.']"
            :autofocus="!!cpfStore"
            class="mt-10 body-2 label-position"
            id="name-patient-form-input"
            label="Nome*"
            placeholder="Digite seu nome"
            height="40"
         >
            <template v-slot:prepend-inner>
               <v-icon size="20" left>mdi-account-outline</v-icon>
            </template>
         </v-text-field>

         <v-text-field
            v-model="formData.mother_name"
            :rules="[(value) => !!value || 'Nome da mãe obrigatório.']"
            class="mt-10 body-2 label-position"
            id="mother_name-patient-form-input"
            label="Nome da mãe*"
            placeholder="Digite o nome da sua mãe"
            height="40"
         >
            <template v-slot:prepend-inner>
               <v-icon size="18" left>mdi-face-woman-outline</v-icon>
            </template>
         </v-text-field>

         <v-text-field
            v-model="formData.birth_date"
            v-maska="'##/##/####'"
            :rules="[
               (value) => !!value || 'Data de nascimento obrigatória.',
               (value) => dateValidation(value) || 'Data inválida.',
            ]"
            type="tel"
            class="mt-10 body-2 label-position"
            label="Data de nascimento*"
            placeholder="00/00/0000"
            id="birth_date-patient-form-input"
            height="40"
         >
            <template v-slot:prepend-inner>
               <v-icon size="18" left>mdi-calendar-outline</v-icon>
            </template>
         </v-text-field>

         <v-text-field
            v-model="formData.email"
            :rules="[
               (value) => !!value || 'Email obrigatório.',
               (value) => emailRules(value) || 'Email inválido.',
            ]"
            type="email"
            class="mt-10 body-2 label-position"
            id="email-forgot-password-input"
            label="Email*"
            placeholder="Digite seu email"
            height="40"
            ><template v-slot:prepend-inner>
               <v-icon size="18" left>mdi-email-outline</v-icon>
            </template>
         </v-text-field>

         <v-text-field
            v-model="formData.password"
            @keyup.enter="onClickSubmit(formData)"
            :type="showPassword ? 'text' : 'password'"
            :rules="[
               (value) => !!value || 'Senha obrigatória.',
               (value) =>
                  passwordValidation(value) ||
                  'Senha com no mínimo 6 caracteres, 1 caracter especial e 1 letra maiúscula.',
            ]"
            class="mt-10 body-2 label-position"
            id="password-patient-form-input"
            label="Senha* (mínimo 6 caracteres)"
            placeholder="Digite sua senha"
            height="40"
         >
            <template v-slot:prepend-inner>
               <v-icon size="18" left>mdi-lock-outline</v-icon>
            </template>

            <template v-slot:append>
               <v-icon
                  class="margin-top-eye-icon"
                  @click="showPassword = !showPassword"
                  id="visualization-password"
                  left
                  small
                  >{{
                     showPassword ? "mdi-eye-outline" : "mdi-eye-off-outline"
                  }}</v-icon
               >
            </template>
         </v-text-field>
      </v-form>

      <div v-if="nodeEnvComputed" class="mt-10 mb-n3">
         <vue-recaptcha
            ref="recaptcha"
            id="recaptcha"
            @verify="recaptchaVerify"
            @render="recaptchaRender"
            @expired="recaptchaExpired"
            :sitekey="siteKey"
         ></vue-recaptcha>

         <p v-if="recaptchaValidation" class="ma-0 mb-n5 caption error--text">
            Preencha a confirmação para continuar.
         </p>
      </div>

      <v-btn
         @click="onClickSubmit(formData)"
         :loading="loadingSubmit"
         :large="!isSmallerThan(959)"
         class="mt-11"
         color="primary"
         id="retrieve-button"
         block
         >RECUPERAR
         <v-icon right>mdi-check</v-icon>
      </v-btn>
   </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { NODE_ENV } from "utils/defaultUtil";
import validationUtil from "utils/validationUtil";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "RetrieveAccountCardBase",

   components: { VueRecaptcha },

   props: {
      loadingSubmit: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         formData: {},

         showPassword: false,

         recaptchaToken: null,
         recaptchaValidation: false,
         recaptchaRendered: false,
      };
   },

   computed: {
      siteKey() {
         return (
            process.env.VUE_APP_RECAPTCHA_SITE_KEY ||
            "VUE_APP_RECAPTCHA_SITE_KEY"
         );
      },

      nodeEnvComputed() {
         return NODE_ENV === "production" ? true : false;
      },

      emailStore: {
         get() {
            return this.$store.getters["patient/getEmail"];
         },

         set(value) {
            this.$store.commit("patient/setEmail", value);
         },
      },

      cpfStore: {
         get() {
            return this.$store.getters["patient/getCpf"];
         },

         set(value) {
            this.$store.commit("patient/setCpf", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,
      ...validationUtil,

      onClickSubmit() {
         /* Valida o formulário */
         const dataValidation = this.validateForm();
         /* Valida o recaptcha */
         let recaptchaValidation = true;
         if (this.nodeEnvComputed)
            recaptchaValidation = this.validateRecaptcha();

         if (!dataValidation || !recaptchaValidation) return;

         /* Cria um novo objeto formDataTemp para que quando a máscara do CPF seja removida, não altere na interface. */
         const formDataTemp = Object.assign({}, this.formData);
         if (formDataTemp.cpf)
            /* Remove a máscara do CPF. */
            formDataTemp.cpf = formDataTemp.cpf.replace(/[^0-9]/g, "");

         const emitData = {
            cpfStore: this.cpfStore || formDataTemp.cpf,
            ...this.formData,
            token: this.recaptchaToken,
         };

         this.$emit("onRetrieveAccount", emitData);
      },

      validateForm() {
         return this.$refs.form.validate();
      },

      emailRules(value) {
         if (value) return validationUtil.emailValidation(value);
      },

      recaptchaRender() {
         this.recaptchaRendered = true;
      },

      recaptchaVerify(value) {
         value ? (this.recaptchaValidation = false) : null;
         this.recaptchaToken = value;
      },

      recaptchaExpired() {
         this.recaptchaVerify(null);
      },

      validateRecaptcha() {
         this.recaptchaValidation = !this.recaptchaToken;
         return !this.recaptchaValidation;
      },

      resetRecaptcha() {
         if (this.$refs.recaptcha) {
            this.$refs.recaptcha.reset();
            this.recaptchaToken = null;
            this.recaptchaValidation = false;
         }
      },

      onNavigateToAuth() {
         this.cpfStore = null;
         this.emailStore = null;
      },
   },

   created() {
      if (this.emailStore && validationUtil.emailValidation(this.emailStore)) {
         this.formData.email = this.emailStore;
      }
   },
};
</script>

<style scoped>
.label-back-auth {
   cursor: pointer !important;
}

.router-link-back-auth {
   text-decoration: none;
}

::v-deep .label-position > div > div > div > input {
   margin-bottom: 12px !important;
}

#recaptcha {
   max-width: 1px !important;
   transform: scale(1);
   -webkit-transform: scale(1);
   transform-origin: 0 0;
   -webkit-transform-origin: 0 0;
}

@media (max-width: 400px) {
   #recaptcha {
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      transform-origin: 100 5;
      -webkit-transform-origin: 100 5;
   }
}

.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

@media (max-width: 959px) {
   ::v-deep .v-btn {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
   }
}
</style>