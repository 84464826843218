import axiosService from "../services/axiosService";
import axios from "axios";
import { API_VERSION } from "../utils/defaultUtil";

async function auth(email, password, token) {
   let data = {
      email,
      password,
   };

   if (token) data["recaptcha-token"] = token;

   return await axiosService.post(`/auth`, data);
}

async function authForgot(email, token) {
   let data = {
      email,
   };

   if (token) data["recaptcha-token"] = token;

   return await axiosService.post(`/auth/forgot`, data);
}

async function authChangePassword(changePassword, token) {
   return await axios.patch(
      `${
         process.env.VUE_APP_API_URL || "http://localhost:3000"
      }${API_VERSION}/auth/password`,
      changePassword,
      {
         headers: {
            Authorization: token,
         },
      }
   );
}

export default {
   auth,
   authForgot,
   authChangePassword,
};
