import { render, staticRenderFns } from "./RegistrationCard.vue?vue&type=template&id=4e9d9cb2&scoped=true"
import script from "./RegistrationCard.vue?vue&type=script&lang=js"
export * from "./RegistrationCard.vue?vue&type=script&lang=js"
import style0 from "./RegistrationCard.vue?vue&type=style&index=0&id=4e9d9cb2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e9d9cb2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VCard,VImg})
