<template>
   <div>
      <div class="mb-10">
         <p class="font-weight-bold font-sora main-title">Meu agendamento</p>

         <p class="text--secondary body-2 mt-1">
            Sua cirurgia foi <strong>agendada</strong>! Segue abaixo todos os
            detalhes.
         </p>
      </div>

      <div class="mb-5">
         <p class="label-small text--secondary">
            <v-icon class="margin-icon" size="16">mdi-clock-outline</v-icon>
            Estado
         </p>
         <v-chip
            class="mt-1"
            :color="generateSurgeryStatus(schedule.status).color"
            small
         >
            {{ generateSurgeryStatus(schedule.status).text }}
         </v-chip>
         <v-divider class="mt-3 mb-6"></v-divider>
      </div>

      <div class="mb-5">
         <p class="label-small text--secondary">
            <v-icon class="margin-icon" size="16">
               mdi-home-city-outline
            </v-icon>
            Local de realização
         </p>
         <p v-if="targetHealthEntity.name" class="body-2 mt-1">
            {{ targetHealthEntity.name }}
         </p>
         <v-skeleton-loader
            v-else
            class="rounded-pill mt-2 mb-3"
            type="image"
            width="70%"
            height="12px"
         ></v-skeleton-loader>

         <v-divider class="mt-2 mb-6"></v-divider>
      </div>

      <div class="mb-5">
         <p class="label-small text--secondary">
            <v-icon class="margin-icon" size="16">
               $medical-bag-outline
            </v-icon>
            Cirurgia solicitada
         </p>
         <p class="body-2 mt-1">{{ schedule.name }}</p>
         <v-divider class="mt-2 mb-6"></v-divider>
      </div>

      <div class="mb-5">
         <p class="label-small text--secondary">
            <v-icon class="margin-icon" size="16"> mdi-doctor </v-icon>
            Médico
         </p>
         <p class="body-2 mt-1">{{ schedule.doctor_name || "-" }}</p>
         <v-divider class="mt-2 mb-6"></v-divider>
      </div>

      <div class="mb-5">
         <p class="label-small text--secondary">
            <v-icon class="margin-icon" size="16">
               mdi-calendar-outline
            </v-icon>
            Data do agendamento
         </p>
         <p class="body-2 mt-1 font-weight-bold">
            {{ formatDate(schedule.scheduling_date, "DD/MM/YYYY") }}
         </p>
         <v-divider class="mt-2 mb-6"></v-divider>
      </div>

      <div v-if="schedule.execution_date" class="mb-5">
         <p class="label-small text--secondary">
            <v-icon class="margin-icon" size="16">
               mdi-calendar-check-outline
            </v-icon>
            Data de realização
         </p>
         <p class="body-2 mt-1">
            {{ formatDate(schedule.execution_date, "DD/MM/YYYY") }}
         </p>
         <v-divider class="mt-2 mb-6"></v-divider>
      </div>

      <div class="mb-5">
         <p class="label-small text--secondary">
            <v-icon class="margin-icon" size="16">
               mdi-file-document-outline
            </v-icon>
            Observações
         </p>
         <p class="body-2 mt-1">
            {{ schedule.notes || "-" }}
         </p>
         <v-divider class="mt-2 mb-6"></v-divider>
      </div>

      <div v-if="isSmallerThan(959)" class="mt-8">
         <v-btn @click="back" color="primary" class="mt-3" block text>
            Voltar
         </v-btn>
      </div>
   </div>
</template>

<script>
import healthEntityService from "services/healthEntityService";
import snackBarUtil from "utils/snackBarUtil";
import formatUtil from "utils/formatUtil";
import util from "utils/util";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "MySchedulesForm",

   props: {
      schedule: {
         type: Object,
      },
   },

   data: function () {
      return {
         targetHealthEntity: {},
      };
   },

   watch: {
      schedule: {
         immediate: true,
         handler(schedule) {
            this.resetData();

            if (schedule && schedule.healthunit_id)
               this.getHealthEntityById(schedule.healthunit_id);
         },
      },
   },

   methods: {
      ...util,
      ...formatUtil,
      ...responsiveUtil,

      resetData() {
         this.targetHealthEntity = {};
      },

      back() {
         this.$emit("onClickBack");
      },

      async getHealthEntityById(healthEntityId) {
         try {
            const response = await healthEntityService.getAllHealthEntityById(
               healthEntityId
            );

            this.targetHealthEntity = Object.assign({}, response.data);
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         }
      },
   },
};
</script>

<style scoped>
.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}

.label-small {
   font-size: 0.685rem !important;
   letter-spacing: 0.0125em;
}

.margin-icon {
   margin-right: 3px;
}
</style>