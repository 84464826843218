import { render, staticRenderFns } from "./AttachmentItemDetails.vue?vue&type=template&id=1bfa7804&scoped=true"
import script from "./AttachmentItemDetails.vue?vue&type=script&lang=js"
export * from "./AttachmentItemDetails.vue?vue&type=script&lang=js"
import style0 from "./AttachmentItemDetails.vue?vue&type=style&index=0&id=1bfa7804&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bfa7804",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VBtn,VCard,VIcon,VImg})
