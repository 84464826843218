<template>
   <v-card
      v-if="!isSmallerThan(959)"
      class="card-border overflow-hidden"
      width="1000px"
   >
      <v-row no-gutters>
         <v-col
            class="d-flex flex-column justify-center align-stretch"
            cols="6"
         >
            <div class="px-12 py-16">
               <v-img
                  :src="require('@/assets/images/operapb.png')"
                  height="180px"
                  class="mx-auto"
                  contain
               >
               </v-img>

               <ChangePasswordCardBase
                  @onChangePassword="changePassword"
                  :loadingSubmit="loadingChangePassword"
                  class="mt-16"
               />
            </div>
         </v-col>

         <v-col cols="6">
            <div class="side-right">
               <v-img
                  :src="require('@/assets/images/forgot-password-circle.png')"
                  height="70%"
                  contain
               >
               </v-img>
            </div>
         </v-col>
      </v-row>
   </v-card>

   <!-- Mobile -->
   <div v-else class="background-mobile">
      <v-img
         :src="require('@/assets/images/forgot-password-circle.png')"
         height="35%"
         class="image-sticky-top-position"
         contain
      >
      </v-img>

      <v-card
         class="
            float-bottom-card
            border-bottom-card
            box-shadow-bottom-card
            standard-padding-whopping
            pb-8
         "
      >
         <ChangePasswordCardBase
            @onChangePassword="changePassword"
            :loadingSubmit="loadingChangePassword"
            class="mt-2 mb-7"
         />

         <FooterBase class="mb-n3" :divider="false" />
      </v-card>
   </div>
</template>

<script>
import ChangePasswordCardBase from "./ChangePasswordCardBase";
import FooterBase from "components/base/FooterBase.vue";
import responsiveUtil from "utils/responsiveUtil";
import authService from "services/authService";
import snackBarUtil from "utils/snackBarUtil";

export default {
   name: "ChangePasswordCard",

   components: { ChangePasswordCardBase, FooterBase },

   data: function () {
      return {
         loadingChangePassword: false,
      };
   },

   methods: {
      ...responsiveUtil,

      async changePassword(data) {
         this.loadingChangePassword = true;

         try {
            await authService.authChangePassword(data, data.token);

            snackBarUtil.showCustomSnackBar({
               color: "success",
               title: "SUCESSO!",
               message: "Sua senha foi atualizada!",
            });

            if (this.$route.path !== "/autenticacao")
               this.$router.push({ path: "/autenticacao" });
         } catch (error) {
            snackBarUtil.showErrorSnackBar(error);
         } finally {
            this.loadingChangePassword = false;
         }
      },
   },
};
</script>

<style scoped>
/* Desktop */
.card-border {
   border-radius: 8px !important;
}

.side-right {
   display: flex;
   justify-content: center;
   align-items: center;

   height: 100%;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );

   padding: 10px;
}

/* Mobile */
.background-mobile {
   height: 100vh !important;
   width: 100vw !important;

   background: rgb(111, 121, 226);
   background: linear-gradient(
      90deg,
      rgba(111, 121, 226, 0.9) 0%,
      rgba(111, 121, 226, 1) 100%
   );
}
</style>