import { render, staticRenderFns } from "./ListFooter.vue?vue&type=template&id=0f72cc32&scoped=true"
import script from "./ListFooter.vue?vue&type=script&lang=js"
export * from "./ListFooter.vue?vue&type=script&lang=js"
import style0 from "./ListFooter.vue?vue&type=style&index=0&id=0f72cc32&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f72cc32",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VDivider,VPagination,VSkeletonLoader})
