<template>
   <div>
      <div v-if="!existingAccount" class="mb-16">
         <p class="font-weight-bold font-sora main-title">Consultar CPF</p>
         <p class="text--secondary body-2 mt-1">
            Informe seu <strong>CPF</strong> para consultarmos se você já tem
            cadastro ou não.
         </p>
      </div>
      <div v-else class="mb-16">
         <p class="font-weight-bold font-sora main-title">CPF encontrado!</p>
         <p class="text--secondary body-2 mt-1">
            Informe seu email e senha e clique em <strong>Entrar</strong> para
            acessar sua conta.
         </p>
      </div>

      <v-form ref="form">
         <v-expand-transition>
            <v-text-field
               v-if="!existingAccount"
               v-model="formData.cpf"
               v-maska="'###.###.###-##'"
               @keyup.enter="onClickSubmit"
               :rules="[
                  (value) => !!value || 'CPF obrigatório.',
                  (value) => cpfValidation(value) || 'CPF Inválido.',
               ]"
               type="tel"
               class="body-2 label-position"
               id="cpf-patient-form-input"
               label="CPF*"
               placeholder="000.000.000-00"
               height="40"
            >
               <template v-slot:prepend-inner>
                  <v-icon size="20" left>mdi-account-outline</v-icon>
               </template>
            </v-text-field>
         </v-expand-transition>

         <!-- Se não colocar esse text-field, buga ao apertar enter -->
         <v-text-field v-show="false"></v-text-field>

         <v-expand-transition>
            <div v-if="existingAccount">
               <v-text-field
                  v-model="formData.email"
                  :rules="[
                     (value) => !!value || 'Email obrigatório.',
                     (value) => emailRules(value) || 'Email inválido.',
                  ]"
                  type="email"
                  class="mt-10 body-2 label-position"
                  id="email-auth-input"
                  label="Email*"
                  placeholder="Digite seu email"
                  height="40"
                  autofocus
                  ><template v-slot:prepend-inner>
                     <v-icon size="18" left>mdi-email-outline</v-icon>
                  </template>
               </v-text-field>

               <v-text-field
                  v-model="formData.password"
                  @keyup.enter="onClickSubmit"
                  :type="showPassword ? 'text' : 'password'"
                  :rules="[(value) => !!value || 'Senha obrigatória.']"
                  class="mt-10 body-2 label-position"
                  id="password-auth-input"
                  label="Senha*"
                  placeholder="Digite sua senha"
                  height="40"
               >
                  <template v-slot:prepend-inner>
                     <v-icon size="18" left>mdi-lock-outline</v-icon>
                  </template>

                  <template v-slot:append>
                     <v-icon
                        @click="showPassword = !showPassword"
                        size="18"
                        id="visualization-password-auth-card-icon"
                        left
                        >{{
                           showPassword
                              ? "mdi-eye-outline"
                              : "mdi-eye-off-outline"
                        }}</v-icon
                     >
                  </template>
               </v-text-field>
            </div>
         </v-expand-transition>
      </v-form>

      <div v-if="nodeEnvComputed" class="mt-10 mb-n3">
         <vue-recaptcha
            ref="recaptcha"
            id="recaptcha"
            @verify="recaptchaVerify"
            @render="recaptchaRender"
            @expired="recaptchaExpired"
            :sitekey="siteKey"
         ></vue-recaptcha>

         <p v-if="recaptchaValidation" class="ma-0 caption error--text">
            Preencha a confirmação para continuar.
         </p>
      </div>

      <v-btn
         @click="onClickSubmit"
         :loading="loadingSubmit"
         :large="!isSmallerThan(959)"
         class="mt-11"
         color="primary"
         id="auth-button"
         block
         >{{ existingAccount ? "ENTRAR" : "CONSULTAR" }}
         <v-icon right>{{
            existingAccount ? "mdi-check" : "mdi-magnify"
         }}</v-icon>
      </v-btn>

      <v-expand-transition>
         <div
            v-if="existingAccount"
            class="d-flex justify-center align-center mt-5"
         >
            <router-link
               class="router-link-retrieve-account"
               to="/recuperar-conta"
               id="go-to-forgot-password-button"
            >
               <p
                  @click="onNavigateToRetrieveAccount"
                  class="caption primary--text label-forgot-password"
               >
                  Esqueceu o email ou senha?
               </p>
            </router-link>
         </div>
      </v-expand-transition>
   </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import { NODE_ENV } from "utils/defaultUtil";
import validationUtil from "utils/validationUtil";
import responsiveUtil from "utils/responsiveUtil";

export default {
   name: "AuthCardBase",

   components: { VueRecaptcha },

   props: {
      loadingSubmit: {
         type: Boolean,
         default: false,
      },

      existingAccount: {
         type: Boolean,
         default: false,
      },
   },

   data: function () {
      return {
         formData: {},

         showPassword: false,

         recaptchaToken: null,
         recaptchaValidation: false,
         recaptchaRendered: false,
      };
   },

   computed: {
      siteKey() {
         return (
            process.env.VUE_APP_RECAPTCHA_SITE_KEY ||
            "VUE_APP_RECAPTCHA_SITE_KEY"
         );
      },

      nodeEnvComputed() {
         return NODE_ENV === "production" ? true : false;
      },

      emailStore: {
         get() {
            return this.$store.getters["patient/getEmail"];
         },

         set(value) {
            this.$store.commit("patient/setEmail", value);
         },
      },

      cpfStore: {
         get() {
            return this.$store.getters["patient/getCpf"];
         },

         set(value) {
            this.$store.commit("patient/setCpf", value);
         },
      },
   },

   methods: {
      ...responsiveUtil,
      ...validationUtil,

      onClickSubmit() {
         /* Valida o formulário */
         const dataValidation = this.validateForm();
         /* Valida o recaptcha */
         let recaptchaValidation = true;
         if (this.nodeEnvComputed)
            recaptchaValidation = this.validateRecaptcha();

         if (!dataValidation || !recaptchaValidation) return;

         /* Cria um novo objeto formDataTemp para que quando a máscara do CPF seja removida, não altere na interface. */
         const formDataTemp = Object.assign({}, this.formData);
         if (formDataTemp.cpf)
            /* Remove a máscara do CPF. */
            formDataTemp.cpf = formDataTemp.cpf.replace(/[^0-9]/g, "");

         const emitData = {
            ...formDataTemp,
            token: this.recaptchaToken,
         };

         if (this.existingAccount) this.$emit("onAuth", emitData);
         else this.$emit("onCheckCpf", emitData);
      },

      clearPassword() {
         delete this.formData.password;
      },

      validateForm() {
         return this.$refs.form.validate();
      },

      emailRules(value) {
         if (value) return validationUtil.emailValidation(value);
      },

      recaptchaRender() {
         this.recaptchaRendered = true;
      },

      recaptchaVerify(value) {
         value ? (this.recaptchaValidation = false) : null;
         this.recaptchaToken = value;
      },

      recaptchaExpired() {
         this.recaptchaVerify(null);
      },

      validateRecaptcha() {
         this.recaptchaValidation = !this.recaptchaToken;
         return !this.recaptchaValidation;
      },

      resetRecaptcha() {
         if (this.$refs.recaptcha) {
            this.$refs.recaptcha.reset();
            this.recaptchaToken = null;
            this.recaptchaValidation = false;
         }
      },

      onNavigateToRetrieveAccount() {
         if (
            this.formData.email &&
            validationUtil.emailValidation(this.formData.email)
         ) {
            this.emailStore = this.formData.email;
            this.cpfStore = this.formData.cpf.replace(/[^0-9]/g, "");
         }
      },
   },

   created() {
      if (this.emailStore && validationUtil.emailValidation(this.emailStore)) {
         this.formData.email = this.emailStore;
         this.$emit("changeExistingEmail", true);
      }
   },
};
</script>

<style scoped>
.label-forgot-password {
   cursor: pointer !important;
}

.router-link-retrieve-account {
   text-decoration: none;
}

::v-deep .label-position > div > div > div > input {
   margin-bottom: 12px !important;
}

#recaptcha {
   max-width: 1px !important;
   transform: scale(1);
   -webkit-transform: scale(1);
   transform-origin: 0 0;
   -webkit-transform-origin: 0 0;
}

@media (max-width: 400px) {
   #recaptcha {
      transform: scale(0.75);
      -webkit-transform: scale(0.75);
      transform-origin: 100 5;
      -webkit-transform-origin: 100 5;
      height: 58px !important;
   }
}

.main-title {
   font-size: 1.15rem !important;
   letter-spacing: 0.0125em;
}
</style>