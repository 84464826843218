export default {
   namespaced: true,

   state: {
      visibility: null,
   },

   getters: {
      getVisibility(state) {
         return state.visibility;
      },
   },

   mutations: {
      setVisibility(state, visibility) {
         state.visibility = visibility;
      },
   },
};
