<template>
   <div>
      <v-card
         :disabled="disableAddButton"
         class="d-flex flex-column justify-center align-center card-config"
         height="150"
         width="140"
         flat
      >
         <div class="div-input">
            <input
               @change="handleAttachmentUpload"
               :accept="allowedTypes.join(',')"
               id="file"
               class="input"
               type="file"
               ref="file"
            />
         </div>

         <v-icon class="plus-icon" size="70">mdi-plus</v-icon>
         <p class="body-2 text--secondary text-center mb-2">
            Novo <br />
            documento
         </p>
      </v-card>
   </div>
</template>

<script>
import util from "utils/util";

export default {
   name: "AttachmentItemNew",

   props: {
      disableAddButton: {
         typeof: Boolean,
         default: false,
      },

      allowedTypes: {
         typeof: Array,
         default: () => [],
      },

      maximumSize: {
         typeof: Number,
         default: 1048576,
      },
   },

   methods: {
      async handleAttachmentUpload() {
         const attachmentTemp = this.$refs.file.files[0];

         if (attachmentTemp !== undefined) {
            if (!this.allowedTypes.includes(attachmentTemp.type))
               this.$emit("onSelectInvalidFormat");
            else if (parseInt(attachmentTemp.size) > this.maximumSize)
               this.$emit("onSelectInvalidSize");
            else
               this.$emit("onSelectAttachment", {
                  id: Math.random().toString(36).substr(2, 9),
                  raw: true,
                  content: {
                     name: attachmentTemp.name,
                     type: attachmentTemp.type,
                     data: await util.getFileContents(attachmentTemp),
                     originalData: attachmentTemp,
                     size: attachmentTemp.size,
                  },
               });

            this.$refs.file.value = "";
         }
      },
   },
};
</script>

<style scoped>
.card-config {
   border-radius: 7px !important;
   border: 2px solid #c4c4c4 !important;
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
   cursor: pointer;
}

.div-input {
   position: absolute;
   width: 140px;
   height: 150px;
   line-height: 150px;
   text-align: center;
}

.input {
   opacity: 0;
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   right: 0;
   width: 100%;
   height: 100%;
}

.plus-icon {
   pointer-events: none;
}
</style>