<template>
   <div v-if="desktop" class="position-sticky">
      <v-btn
         @click="$emit('close')"
         class="button-desktop"
         color="primary"
         fab
         text
         small
      >
         <v-icon>mdi-close</v-icon>
      </v-btn>
   </div>

   <v-btn
      v-else
      @click="$emit('close')"
      color="primary"
      fab
      text
      small
      fixed
      top
      right
   >
      <v-icon>mdi-close</v-icon>
   </v-btn>
</template>

<script>
export default {
   name: "Close",

   props: {
      desktop: {
         type: Boolean,
         default: false,
      },
   },
};
</script>

<style scoped>
.position-sticky {
   position: sticky;
   display: flex;
   justify-content: flex-end;
   align-items: center;
   pointer-events: none;
}

.button-desktop {
   margin-top: 10px;
   margin-right: 8px;
   margin-bottom: -50px;
   pointer-events: auto;
}

@media (max-width: 959px) {
   ::v-deep .v-btn--absolute.v-btn--top,
   .v-btn--fixed.v-btn--top {
      top: 10px;
   }

   ::v-deep .v-btn--absolute.v-btn--right,
   .v-btn--fixed.v-btn--right {
      right: 8px;
   }
}
</style>