import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Maska from "maska";
import "./registerServiceWorker";

Vue.config.productionTip = false;

Vue.use(Maska);

new Vue({
   router,
   store,
   vuetify,
   render: (h) => h(App),
}).$mount("#app");
